import React, {Component} from 'react';
import Dropdown from 'react-bootstrap/Dropdown'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import DropdownButton from "react-bootstrap/DropdownButton"
import {FormattedMessage} from "react-intl"
import LocalStorage from "../services/LocalStorage"
import UserService from "../services/UserService"
import PropTypes from 'prop-types'

class LanguageSelect extends Component {
    selectLanguage = (event, lang) => {
        if (lang.disabled) {
            return;
        }
        LocalStorage.set("language", lang.id);
        if (this.props.user) {
            UserService.updateLang(lang.id);
        }
        setTimeout(() => {
            window.location.reload();
        }, 300);
    };

    getButtonText = () => {
        if (this.props.small) {
            return (
                <React.Fragment>
                    <i className="fa fa-globe fa-fw"/>&nbsp;<FormattedMessage id="common.shortLang"/>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <i className="fa fa-globe fa-fw"/>&nbsp;<FormattedMessage id="common.lang"/>
                </React.Fragment>
            )
        }
    };

    render() {
        const activeLang = LocalStorage.getOrDefault("language", null);
        const langDefinitions = [{
            id: "cz",
            title: "Čeština",
            disabled: activeLang === "cz"
        }, {
            id: "en",
            title: "English",
            disabled: activeLang === "en"
        }, {
            id: "ru",
            title: "Русский",
            disabled: activeLang === "ru"
        }];

        let variant = this.props.small ? "link" : "outline-light";
        let className = this.props.small ? "nav-selector" : "";

        return (
            <DropdownButton as={ButtonGroup}
                            variant={variant}
                            size="sm"
                            className={className}
                            style={{marginLeft: 5, marginRight: 5}}
                            title={this.getButtonText()}>
                {
                    langDefinitions.map(lang => {
                        return (
                            <Dropdown.Item
                                onClick={e => this.selectLanguage(e, lang)}
                                disabled={lang.disabled}
                                eventKey={lang.id}
                                key={lang.id}>
                                {lang.title}
                            </Dropdown.Item>
                        )
                    })
                }
            </DropdownButton>
        )
    }
}

LanguageSelect.propTypes = {
    user: PropTypes.object,
    small: PropTypes.bool.isRequired,
};

export default LanguageSelect;
