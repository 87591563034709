import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ProductList from "../catalog/ProductList";
import ProfilePage from "./ProfilePage";
import WishlistService from "../services/WishlistService";
import {FormattedMessage} from "react-intl";

class Wishlist extends Component {
    loadProducts = () => {
        return WishlistService.list(0, 24);
    };

    render = () => {
        return (
            <ProfilePage user={this.props.user}>
                <h1><FormattedMessage id="topNavi.wishlist"/></h1>
                <ProductList location={window.location}
                             user={this.props.user}
                             onLogin={this.props.onLogin}
                             onAddedToWishlist={this.props.onAddedToWishlist}
                             onRemovedFromWishlist={this.props.onRemovedFromWishlist}
                             loadProducts={this.loadProducts}
                />
            </ProfilePage>
        )
    }
}

Wishlist.propTypes = {
    user: PropTypes.object,
    onLogin: PropTypes.func.isRequired,
    onAddedToWishlist: PropTypes.func.isRequired,
    onRemovedFromWishlist: PropTypes.func.isRequired,
};

export default Wishlist;
