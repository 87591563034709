import React, {Component} from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FormattedMessage} from "react-intl";

class Error406 extends Component {
    render = () => {
        return (
            <div className="jumbotron">
                <Row className="justify-content-md-center">
                    <Col md="auto">
                        <p style={{textAlign: "center"}}>
                            <i className="fas fa-5x fa-exclamation-triangle text-danger"/>
                            <h1 className="display-4">
                                <FormattedMessage id="common.errors.406"/>
                            </h1>
                            <p className="lead">
                                <FormattedMessage id="common.errors.406.description"/>
                            </p>
                        </p>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Error406;
