import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ProfileNavi from "./ProfileNavi";
import ProfileCard from "./ProfileCard";

class ProfileMenu extends Component {
    render = () => {
        return (
            <React.Fragment>
                <ProfileCard user={this.props.user}/>
                <ProfileNavi user={this.props.user}/>
            </React.Fragment>
        )
    }
}

ProfileMenu.propTypes = {
    user: PropTypes.object,
};

export default ProfileMenu;
