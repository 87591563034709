import LocalStorage from "./LocalStorage";

const LinkService = {
    getHome: function () {
        return this.getSmartCatalog();
    },

    getCatalog: function () {
        return "/catalog";
    },

    getShop: function (shop) {
        return this.getCatalog() + "?shop=" + shop;
    },

    getSmartCatalog: function () {
        let country = LocalStorage.get("country");
        if (country !== undefined && country !== null) {
            return this.getCatalog() + "?country=" + country;
        } else {
            return this.getCatalog();
        }
    },

    getMessages: function () {
        return "/messages";
    },

    getMessageThread: function (userId) {
        return "/messages/" + userId;
    },

    getWishlist: function () {
        return "/wishlist";
    },

    getMyStuff: function () {
        return "/mystuff";
    },

    getChangePassword: function () {
        return "/change-password";
    },

    getMyStuffExisting: function (productId) {
        return "/mystuff/" + productId;
    },

    getMyStuffNew: function () {
        return this.getMyStuffExisting("new");
    },

    getProfile: function () {
        return "/profile";
    },

    getHelpTerms: function () {
        return "/help/terms";
    },

    getHelpGDPR: function () {
        return "/help/gdpr";
    },

    getHelpCookies: function () {
        return "/help/cookies";
    },

    getProduct: function (productUrl) {
        return this.getProductPath() + "/" + productUrl;
    },

    getProductPath: function () {
        return "/product";
    },

    getErrors: function () {
        return "/errors";
    },

    getErrors406: function () {
        return this.getErrors() + "/406"
    },

    getActivate: function () {
        return "/activate"
    },

    getNoCatalogImage: function () {
        return "data:image/gif;base64,R0lGODlhQAFAAeMAAMzMzJaWlrGxscXFxaqqqr6+vqOjo5ycnLe3t8zMzMzMzMzMzMzMzMzMzMzMzMzMzCH5BAEKAA8ALAAAAABAAUABAAT+EMhJq7046827/2AojmRpnmiqrmzrvnAsz3Rt33iu73zv/8CgcEgsGo/IpHLJbDqf0Kh0Sq1ar9isdsvter/gsHhMLpvP6LR6zW673/C4fE6v2+/4vH7P7/v/gIGCg4SFhoeIiYqLjI2Oj5CRkpOUlZaXmJmam5ydnp+goaKjpKWmp6ipqqusra6vsLGys7S1tre4ubq7vL2+v8DBwsPExcbHyMnKy8zNzs/Q0dLT1NXW19jZ2tvc3d7f4OHi4+Tl5ufo6err7O3u7/Dx8vP09fb3+Pn6+/z9/v8AAwocSLCgwYMIEypcyLChw4cQI0qcSLGixYsYM2rcyLGjx4/+IEOKHEmypMmTKFOqXMmypcuXMGPKnEmzps2bOHPq3Mmzp8+fQIMKHUq0KEIEAhBIDMC0qQALTaM2nWGgqYGIUplCzTo1BgGpBCByDUuBa1cYXMWmLWs2gIy1aqOSrSCVxle5S7NeqEvjgNWJXK/SjWoDqVLAcCfw3Wn2wOCzOtselrD4AgG/AQ7M/YC5MoDOASYLOHBAMIWqByZvEFA182YNlzMbOEwYA2umpVXXautYcW0Ld7O+3mD2MdOrCECHliAALAflTJ9maC31atPeFZoHxiUVtHQAniVQ3+6hONuo46MiSM9Ut/Gs3yuwJ08hOFfstKQiiIo9/P627Xn+AJ1bFAwIYFb4vZfYBNoduBwF/wEYnyx1SSVdeKARMMAA9iXIQXjgZXVVg9cBEOGDW2U2QYYpMqXhAMlhKNeGJxKYH2H2ERgefwX+Vp6PvqkX5HFDDrdigjxCmKQEA+z4F4NC3tiVhSFCBoB98Z04IXFAUgbkklUGYFoHYF4Z1YSekahaAXhJqZWZhHk2XgEUsPnkj1Z6aSWYZXJp5ZzvyRfVAMaNGcti+nkGGqETNFniByB6xmeXGdRYwaKB9phnn68sRqJymZ73Jp6jimqjnioOeSpwBq7q5J4O5tmpj62GqioIkX7pI4gAzCdpl7/GuioslZEYbKmoDqtBrrD+QgYie5odO6y0B+4GpIG2JosrpdKaasGJGt7qbbbCpjqLZzVaiWmjnGbALLKVhTceo9pqyhS99a5IaS4y6nVaVHROYCeRkHKrq7PAHowsoONOMB4w/i2IJQUkbrmswc2W+iq8QFacLYkQU8peBSfiB1oI77q6a5egBZzvkAfQ6+imTb3WnMXE7uuvvQG82CHKGHOMcJ4d0llAehaAZkABMIJ66ZkO1+zmzuJFfKB7GLTaG3QKqhqg17wpGeu3Y5/bVovK5lhdwWeHmfHXXMVnLICCOniBr7K6QncF9mGgtovbtr1gVrTBlx1XMXbJHtKWNWbL3sbBhplmImgtAdfOptqIuGWYmdauAX4d8BSvEsQmG84ytTuU6kKhZxTFbb7+WZRBEbCZ00G1+jVQeAM+FN6o94SA6aUFL/vxyCev/PLMN+/889BHL/301Fdv/fXYZ6/99tx37/334Icv/vjkl2/++einr/767Lfv/vvwxy///PTXb//9+Oev//789+///wAMoAAHSMACGvCACEygAhfIwAY68IEQjKAEJ0jBClrwghjMoAY3yMEOevCDIAyhCEdIwhKa8IQoTKEKV8jCFrrwhTCMoQxnSEPmRQAAOw==";
    }

};

export default LinkService;
