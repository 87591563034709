import React, {Component} from 'react'
import AjaxLoader from "./ajax-loader.gif"
import Image from 'react-bootstrap/Image'

class LoadingIndicator extends Component {
    render = () => {
        return (
            <p className="text-center p-3 mb-2"><Image src={AjaxLoader}/></p>
        )
    }
}

export default LoadingIndicator;
