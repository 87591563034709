import React, {Component} from 'react';
import Panel from "../shell/Panel";
import PropTypes from 'prop-types';
import {injectIntl} from "react-intl";
import Form from 'react-bootstrap/Form'
import ContentLoader from "react-content-loader";

class FilterList extends Component {
    state = {
        changed: false
    };

    sortItems = (a, b) => {
        if (a.checked && !b.checked) {
            return -1;
        } else if (!a.checked && b.checked) {
            return 1;
        } else {
            return a.title.localeCompare(b.title);
        }
    };

    handleChange = (event, item) => {
        item.checked = event.target.checked;
        this.setState({changed: true}, this.applyFilter);
    };

    applyFilter = () => {
        const {items} = this.props;
        this.setState({changed: false});
        this.props.applyFilter(new Set(items.filter(item => item.checked).map(item => item.id)));
    };

    render = () => {
        const {items} = this.props;
        return (
            <React.Fragment>
                {items === null &&
                <ContentLoader preserveAspectRatio={"none"}
                               style={{width: '100%', marginBottom: 30}}/>
                }
                {items != null &&
                <Panel title={this.props.title}>
                    <Form className="filter-list-form" style={{height: this.props.height}}>
                        <Form.Group>
                            {items.sort(this.sortItems) && items.map(item => {
                                return (
                                    <Form.Check
                                        className="float-left filter-item"
                                        disabled={item.hasProducts === false}
                                        custom
                                        checked={item.checked}
                                        key={item.id}
                                        id={item.id}
                                        onChange={e => this.handleChange(e, item)}
                                        type="checkbox"
                                        label={item.title}/>
                                )
                            })}
                        </Form.Group>
                    </Form>
                    {/*<Button variant="outline-secondary" type="button" size="sm" block*/}
                    {/*onClick={this.applyFilter}*/}
                    {/*disabled={!this.state.changed}>*/}
                    {/*<FormattedMessage id="common.apply"/>*/}
                    {/*</Button>*/}
                </Panel>
                }
            </React.Fragment>
        )
    }
}

FilterList.propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.array,
    applyFilter: PropTypes.func.isRequired
};

export default injectIntl(FilterList);
