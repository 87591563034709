const i18n = {
    "en": {
        "common.lang": 'English',
        "common.shortLang": "EN",
        "common.waitPlease": "Wait please...",
        "common.econorCompany": "Econor, s.r.o.",
        "common.pragueCz": 'Prague, Czech Republic',
        "common.countryFilter": 'Countries',
        "common.shopFilter": 'Shops',
        "common.apply": 'Apply',
        "common.loading": 'Loading...',
        "common.sending": 'Sending...',
        "common.cancel": "Cancel",
        "common.pageNotFound": "404 - Page Not Found",
        "common.pageCannotBeFound": "Sorry, but the page you are looking for cannot be found.",
        "common.errors.406": "406 - Not Acceptable",
        "common.errors.406.description": "Sorry, but seems the request you made is not correct and therefore cannot be processed!",
        "common.importantInformation": "Important Information!",
        "common.cookiesInfo": "We use cookies to improve the service we provide to our customers. By using this website, you agree to our cookies policy.",
        "common.gdprInfo": "We have adapted our privacy policy to the new EU General Data Protection Regulation. Take a look at our changes.",
        "common.moreInfo": "More Information",
        "common.next": "Next",
        "common.errorMessage": "Sorry but there is an error! Please try your request again.",

        "currency.czk": 'CZK - Czech koruna',
        "currency.eur": 'EUR - Euro',
        "currency.usd": 'USD - US dollar',
        "currency.rub": 'RUB - Russian rouble',
        "currency.uah": 'UAH - Ukrainian hryvnia',

        "country.at": 'Austria',
        "country.be": 'Belgium',
        "country.de": 'Germany',
        "country.dk": 'Denmark',
        "country.fr": 'France',
        "country.gb": 'United Kingdom',
        "country.hr": 'Croatia',
        "country.hu": 'Hungary',
        "country.it": 'Italy',
        "country.lu": 'Luxembourg',
        "country.nl": 'Netherlands',
        "country.pl": 'Poland',
        "country.ro": 'Romania',
        "country.ru": 'Russia',
        "country.se": 'Sweden',
        "country.si": 'Slovenia',
        "country.bg": 'Bulgaria',
        "country.cz": 'Czech Republic',
        "country.es": 'Spain',
        "country.fi": 'Finland',
        "country.gr": 'Greece',
        "country.ie": 'Ireland',
        "country.lv": 'Latvia',
        "country.pt": 'Portugal',
        "country.sk": 'Slovak Republic',
        "country.ua": 'Ukraine',
        "country.by": 'Belarus',
        "country.ee": 'Estonia',
        "country.cl": 'Chile',
        "country.us": 'United States',
        "country.ot": 'Other',

        "topNavi.catalogue": 'Catalog',
        "topNavi.messages": 'Messages',
        "topNavi.wishlist": 'Wishlist',
        "topNavi.login": 'Sign in',
        "topNavi.register": 'Register',
        // "topNavi.account": 'My Account',
        "topNavi.profile": 'Profile',
        "topNavi.logout": 'Sign out',
        "topNavi.mystuff": "My stuff",
        "topNavi.changePassword": "Change Password",

        "footer.home": 'Home',
        "footer.terms": 'Terms',
        "footer.gdprTitle": "General Data Protection Regulation",
        "footer.cookies": "Cookies",

        "login.email": "Email",
        "login.password": "Password",
        "login.forgotPassword": 'Forgotten your password?',
        "login.forgotPassword.desc": "Please enter your email address below and we will help you",
        "login.clickHere": 'click here',
        "login.usernameOrPasswordIncorrect": "Email or password are not correct or don't exist! Please check the information you have entered.",
        "login.forgotPassword.error": "Sorry but there is an error during sending a message for recovery your password. Please try your request again.",

        "login.forgotPassword.sent": "The message for recovery your password was sent to email address you've just entered.",
        "login.createYourAccount": "Please fill in your registration information below.",
        "login.register": "Register",
        "login.nameRulesTips": "Name should be min 3 letters long (20 letters max) and may only contain alpha-numeric characters, underscores, and dashes.",
        "login.passwordRulesTips": "Password should be min 6 letters long (20 letters max) and may only contain alpha-numeric characters, underscores, and dashes.",
        "login.agreement": "I agree with the terms of join and I agree to the processing of personal data",
        "login.selectPlan": "Your plan (you can change it later)",
        "login.plan.visitor": "Visitor",
        "login.plan.visitor.desc": "You can only comment on existing stuff",
        "login.plan.member": "Author",
        "login.plan.member.desc": "You can put your own stuff for selling",
        "login.businessConditions": "Business conditions",
        "login.personalDataPolicy": "Personal data policy",
        "login.registrationFinished": "You've just successfully registered at Valika.org. For activation your account please follow by link, which has just been sent to your email.",
        "login.registrationFailed": "Problems have appeared at the server during the registration process. Please try to register once more.",
        "login.emailAlreadyDefined": "Email you defined is already used. Please check it. If you've registered already and don't remember your password, you can try to restore it.",
        "login.nameAlreadyDefined": "Name you defined is already used. Please select another one.",
        "login.activationFinished": "Your account has been successfully activated. From now you can use your email and password for singing in. You are able to control your profile, your stuff and other your things at site by using link-bar at the top of almost each page.",
        "login.accountActivation": "Account activation",
        "login.activationFailed": "A error has appeared during the activation process. Seems you have used wrong activation link. Please try to use activation link again. If you still have problems with the signing up, please feel free to contact us to solve the issue.",

        "product.reviews": 'Reviews',
        "product.resultsFound": 'Products found',
        "product.resultsNotFound": 'Unfortunately nothing has found. Please try to change your search criteria...',
        "product.approx": 'approximately',
        "product.addToWishlist": 'Add to Wishlist',
        "product.removeFromWishlist": 'Remove from Wishlist',
        "product.askQuestion": 'Ask question',
        "product.description": 'Description',
        "product.moreFrom": 'More from',
        "product.totalNum": 'total',
        "product.stars5": "5 stars",
        "product.stars4": "4 stars",
        "product.stars3": "3 stars",
        "product.stars2": "2 stars",
        "product.stars1": "1 star",
        "product.rateItem": 'Rate this item',
        "product.rating.1": "1 star out of 5",
        "product.rating.2": "2 stars out of 5",
        "product.rating.3": "3 stars out of 5",
        "product.rating.4": "4 stars out of 5",
        "product.rating.5": "5 stars out of 5",
        "product.leaveComment": "Leave a comment",
        "product.stayAnonymous": "Stay anonymous",
        "product.reviewWasNotSent": "There is a error during sending the review.",
        "product.anonymously": "Anonymously",
        "product.reply": "reply",
        "product.hidden": "Hidden",
        "product.remove": "Remove Product",
        "product.confirmRemove": "Do you really want to remove product ",

        "messages.notFound": "You have no messages",
        "messages.message": 'Message',
        "messages.send": "Send",
        "messages.messageWasSent": "Message was successfully sent.",
        "messages.messageWasNotSent": "There is a error during sending the message.",

        "profile.saveChanges": "Save changes",
        "profile.picture": "Profile picture",
        "profile.authorName": "Name",
        "profile.country": "Country",
        "profile.city": "City or town",
        "profile.aboutMe": "About me",
        "profile.email": "Email address",
        "profile.upload": "Upload image",
        "profile.remove": "Remove",
        "profile.changesNotSaved": "Error! Changes were not saved.",
        "profile.useFilesNoLargerThan": "Use '.jpg', '.gif' or '.png' files no larger than 5MB.",
        "profile.uploadImagesToSite": "Upload your images to the site.",
        "profile.selectImageToUpload": "Select an image to upload or just drop the file into area above",
        "profile.oldPassword": "Old password",
        "profile.repeatPassword": "Repeat password",
        "profile.passwordChangedSuccessfuly": "Password has been successfully changed!",
        "profile.passwordChangeUnsuccessful": "Unable to change password. Please try to send your request again.",

        "mystuff.editProduct": "Edit Product",
        "mystuff.newProduct": "New Product",
        "mystuff.edit.info": "Item info",
        "mystuff.edit.sort": "Sorting",
        "mystuff.edit.selling": "Selling",
        "mystuff.edit.images": "Images",
        "mystuff.edit.defineTitleAndDescription": "Define a title and a description for the product.",
        "mystuff.edit.title": "Title",
        "mystuff.edit.description": "Description",
        "mystuff.edit.fillProductProperties": "Please, fill in the properties of the product like materials, length, colours, etc. You can leave some of the property boxes empty if you haven't needed them.",
        "mystuff.edit.property": "Property",
        "mystuff.edit.upload": "Upload",
        "mystuff.edit.selectCategories": "Please select the categories for the product. You should select at least one.",
        "mystuff.edit.categories": "Categories",
        "mystuff.edit.categories.accessories_for_hair": "Accessories for hair",
        "mystuff.edit.categories.baby_clothing": "Baby clothing",
        "mystuff.edit.categories.bags": "Bags",
        "mystuff.edit.categories.boleros_and_cardigans": "Boleros and Cardigans",
        "mystuff.edit.categories.bracelets": "Bracelets",
        "mystuff.edit.categories.coverlets_and_capes": "Coverlets and capes",
        "mystuff.edit.categories.decorations": "Decorations",
        "mystuff.edit.categories.dog_clothes": "Dog's clothes",
        "mystuff.edit.categories.dresses_and_skirts": "Dresses and Skirts",
        "mystuff.edit.categories.earrings": "Earrings",
        "mystuff.edit.categories.flowers_and_brooches": "Flowers and Brooches",
        "mystuff.edit.categories.footwear": "Footwear",
        "mystuff.edit.categories.hats": "Hats",
        "mystuff.edit.categories.materials": "Materials",
        "mystuff.edit.categories.mittens": "Mittens",
        "mystuff.edit.categories.mobile_accessories": "Mobile's accessories",
        "mystuff.edit.categories.necklaces": "Necklaces",
        "mystuff.edit.categories.pictures": "Pictures",
        "mystuff.edit.categories.rings": "Rings",
        "mystuff.edit.categories.scarves_and_shawls": "Scarves and Shawls",
        "mystuff.edit.categories.socks": "Socks",
        "mystuff.edit.categories.sweaters_and_jakets": "Sweaters and Jackets",
        "mystuff.edit.categories.toys": "Toys",
        "mystuff.edit.categories.other": "Other",
        "mystuff.edit.sellingDescription": "Please select product's selling attributes.",
        "mystuff.edit.price": "Price",
        "mystuff.edit.currency": "Currency",
        "mystuff.edit.saveAndPublish": "Save and publish",
        "mystuff.edit.saveAsDraft": "Save as draft",
        "mystuff.edit.saveConditions": "In order to save the porduct you need to define a title, at least one product image, one category and price."
    },
    "cz": {
        "common.lang": 'Čeština',
        "common.shortLang": "CZ",
        "common.waitPlease": "Čekejte, prosím...",
        "common.econorCompany": "Econor, s.r.o.",
        "common.pragueCz": 'Praha, Česká republika',
        "common.countryFilter": 'Státy',
        "common.shopFilter": 'Obchody',
        "common.apply": 'Аplikovat',
        "common.loading": 'Načítá se...',
        "common.sending": 'Odesílá se...',
        "common.cancel": "Zrušit",
        "common.pageNotFound": "404 - Stránka nebyla nalezena",
        "common.pageCannotBeFound": "Litujeme, ale stránka, kterou vyhladáváte nebyla nalezena.",
        "common.errors.406": "406 - Nepřijatelné",
        "common.errors.406.description": "Omlouváme se, ale zdá se, že požadavek, který jste zadali, není správný, a proto jej nelze zpracovat!",
        "common.importantInformation": "Důležitá informace!",
        "common.cookiesInfo": "Provozovatel jako správce osobních údajů zpracovává na tomto webu cookies potřebné pro fungování webových stránek a pro analytické účely.",
        "common.gdprInfo": "Přizpůsobili jsme naši politiku ochrany osobních údajů novému obecnému nařízení o ochraně osobních údajů EU. Podívejte se na naše změny.",
        "common.moreInfo": "Více informací",
        "common.next": "Dále",
        "common.errorMessage": "Litujeme, ale nastala chyba! Prosím, zadejte žádost znovu!",

        "currency.czk": 'CZK - Česká koruna',
        "currency.eur": 'EUR - Euro',
        "currency.usd": 'USD - Americký dolar',
        "currency.rub": 'RUB - Ruský rubl',
        "currency.uah": 'UAH - Ukrajinská hřivna',

        "country.at": 'Austrálie',
        "country.be": 'Belgie',
        "country.de": 'Německo',
        "country.dk": 'Dánsko',
        "country.fr": 'Francie',
        "country.gb": 'Anglie',
        "country.hr": 'Chorvatsko',
        "country.hu": 'Maďarsko',
        "country.it": 'Itálie',
        "country.lu": 'Lucembursko',
        "country.nl": 'Nizozemí',
        "country.pl": 'Polsko',
        "country.ro": 'Rumunsko',
        "country.ru": 'Rusko',
        "country.se": 'Švédsko',
        "country.si": 'Slovinsko',
        "country.bg": 'Bulharsko',
        "country.cz": 'Česko',
        "country.es": 'Španělsko',
        "country.fi": 'Finsko',
        "country.gr": 'Řecko',
        "country.ie": 'Irsko',
        "country.lv": 'Lotyšsko',
        "country.pt": 'Portugalsko',
        "country.sk": 'Slovensko',
        "country.ua": 'Ukrajina',
        "country.by": 'Bělorusko',
        "country.ee": 'Estonsko',
        "country.cl": 'Chile',
        "country.us": 'Spojené státy',
        "country.ot": 'Jiný',

        "topNavi.catalogue": 'Katalog',
        "topNavi.messages": 'Zprávy',
        "topNavi.wishlist": 'Oblíbené zboží',
        "topNavi.login": 'Přihlášení',
        "topNavi.register": 'Registrace',
        // "topNavi.account": 'Moje Konto',
        "topNavi.profile": 'Profil',
        "topNavi.logout": 'Odhlásit se',
        "topNavi.mystuff": "Moje zboží",
        "topNavi.changePassword": "Změnit heslo",

        "footer.home": 'Úvodní strana',
        "footer.terms": 'Podmínky',
        "footer.gdprTitle": "Zásady práce s osobními údaji",
        "footer.cookies": "Cookies",

        "login.email": "Email",
        "login.password": "Heslo",
        "login.forgotPassword": 'Zapomněli jste na heslo?',
        "login.forgotPassword.desc": "Prosím, zadejte svou e-mailovou adresu níže a my Vám pomůžeme",
        "login.clickHere": 'klikněte zde',
        "login.usernameOrPasswordIncorrect": "Email nebo heslo nejsou platné nebo neexistují! Prosím, zkontrolujte data, které jste právě zadali.",
        "login.forgotPassword.error": "Litujeme, ale nastala chyba při odesílání zprávy na obnovení Vašeho hesla. Prosím, zadejte žádost znovu.",
        "login.forgotPassword.sent": "Zpráva pro obnovení Vašeho hesla byla odeslána na email, který jste právě zadali.",
        "login.createYourAccount": "Prosím, vyplňte registrační údaje níže.",
        "login.register": "Registrovat se",
        "login.nameRulesTips": "Jméno má obsahovat minimálně 3 znaky maximálně 20 znaků a smí obsahovat jen písmena, čísla, podtržítka a pomlčky.",
        "login.passwordRulesTips": "Heslo má obsahovat minimálně 6 znaků maximálně 20 znaků a smí obsahovat jen písmena, čísla, podtržítka a pomlčky.",
        "login.agreement": "Souhlasím s podmínkami připojení a právní doložkou a souhlasím se zpracováním osobních údajů",
        "login.selectPlan": "Váš plán (můžete jej později změnit)",
        "login.plan.visitor": "Návštěvník",
        "login.plan.visitor.desc": "Můžete pouze komentovat existující zboží",
        "login.plan.member": "Autor",
        "login.plan.member.desc": "Můžete přidat své zboží na prodej",
        "login.businessConditions": "Podmínky připojení",
        "login.personalDataPolicy": "Zásady práce s osobními údaji",
        "login.registrationFinished": "Jste úspěšně registrováni na Valika.org. Pro aktivaci Vašeho účtu, prosím, klikněte na odkaz, který se Vám zobrazil v emailu.",
        "login.registrationFailed": "Během registrace nastaly problémy se servrem. Prosim, zaregistrujte se znovu.",
        "login.emailAlreadyDefined": "Email, který jste zadali se již používá. Prosím, zkontrolujte ho. Jestliže jste již registrování a nepamatujete si své heslo, můžete ho obnovit.",
        "login.nameAlreadyDefined": "Jméno, které jste zadali se již používá. Prosím, vyberte jiné.",
        "login.activationFinished": "Váš účet byl úspěšně aktivován. Vítejte zde! Od teď se můžete začít přihlašovat Vaším e-mailem a heslem. Svůj profil, výrobky a jiné věci můžete ovládat pomocí odkazu nahoře, který je téměř na každé straně.",
        "login.accountActivation": "Aktivace účtu",
        "login.activationFailed": "Během aktivace se naskytla chyba. Zdá se, že jste použili špatný aktivační odkaz. Prosím, použijte aktivační odkaz znovu. Jestliže máte stále problémy s přihlášením, neváhejte a kontaktujte nás, abychom společně vyřešili problém.",

        "product.reviews": 'Hodnocení',
        "product.resultsFound": 'Nalezeno položek zboží',
        "product.resultsNotFound": 'Nic nebylo nalezeno. Zkuste změnit své vyhledávací požadavky...',
        "product.approx": 'přibližně',
        "product.addToWishlist": 'Přidat do oblíbených',
        "product.removeFromWishlist": 'Vyjmout z oblíbených',
        "product.askQuestion": 'Položit dotaz',
        "product.description": 'Popis',
        "product.moreFrom": 'Více od',
        "product.totalNum": 'celkem',
        "product.stars5": "5 hvězd",
        "product.stars4": "4 hvězdy",
        "product.stars3": "3 hvězdy",
        "product.stars2": "2 hvězdy",
        "product.stars1": "1 hvězda",
        "product.rateItem": 'Ohodnotit tuto položku',
        "product.rating.1": "1 hvězdička z 5",
        "product.rating.2": "2 hvězdičky z 5",
        "product.rating.3": "3 hvězdičky z 5",
        "product.rating.4": "4 hvězdičky z 5",
        "product.rating.5": "5 hvězdiček z 5",
        "product.leaveComment": "Okomentovat",
        "product.stayAnonymous": "Zůstat v anonymitě",
        "product.reviewWasNotSent": "Během zasílání hodnocení se objevila chyba.",
        "product.anonymously": "Anonymně",
        "product.reply": "odpovědět",
        "product.hidden": "Skryté",
        "product.remove": "Odstranit produkt",
        "product.confirmRemove": "Opravdu chcete odstranit produkt ",

        "messages.notFound": "Nemáte žádné zprávy",
        "messages.message": 'Zpráva',
        "messages.send": "Odeslat",
        "messages.messageWasSent": "Zpráva byla úspěšně zaslána.",
        "messages.messageWasNotSent": "Během zasílání zprávy se objevila chyba.",

        "profile.saveChanges": "Uložit změny",
        "profile.picture": "Profilový obrázek",
        "profile.authorName": "Jméno",
        "profile.country": "Stát",
        "profile.city": "Město",
        "profile.aboutMe": "O mě",
        "profile.email": "Emailová adresa",
        "profile.upload": "Načíst obrázek",
        "profile.remove": "Odebrat",
        "profile.changesNotSaved": "Chyba! Změny nebyly uloženy.",
        "profile.useFilesNoLargerThan": "Použijte obrázky '.jpg', '.gif' nebo '.png' ne větší než 5MB.",
        "profile.uploadImagesToSite": "Nahrajte vaše fotky na stránku.",
        "profile.selectImageToUpload": "Vyberte obrázek, který chcete nahrát, nebo prostě hoďte soubor do výše uvedené oblasti",
        "profile.oldPassword": "Staré heslo",
        "profile.repeatPassword": "Znovu zadejte heslo",
        "profile.passwordChangedSuccessfuly": "Heslo bylo úspěšně změněno!",
        "profile.passwordChangeUnsuccessful": "Heslo nelze změnit. Prosím, odešlete svou žádost znovu.",

        "mystuff.editProduct": "Oprava zboží",
        "mystuff.newProduct": "Nové zboží",
        "mystuff.edit.info": "Informace o položce",
        "mystuff.edit.sort": "Zatřízení",
        "mystuff.edit.selling": "Prodej",
        "mystuff.edit.images": "Fotky výrobků",
        "mystuff.edit.defineTitleAndDescription": "Napiš název a popisek zboží.",
        "mystuff.edit.title": "Název",
        "mystuff.edit.description": "Popisek",
        "mystuff.edit.fillProductProperties": "Prosím, vyplňte vlastnosti výrobku - materiál, velikost, barvu, apod. Kolonky Vlastnosti, které nepotřebujete, nemusíte vyplňovat.",
        "mystuff.edit.property": "Vlastnost",
        "mystuff.edit.upload": "Načíst",
        "mystuff.edit.selectCategories": "Prosím, vyberte kategorii pro příslušný výrobek. Měli byste vybrat alespoň jednu kategorii.",
        "mystuff.edit.categories": "Kategorii",
        "mystuff.edit.categories.accessories_for_hair": "Doplňky do vlasů",
        "mystuff.edit.categories.baby_clothing": "Dětské oblečení",
        "mystuff.edit.categories.bags": "Kabelky",
        "mystuff.edit.categories.boleros_and_cardigans": "Bolerka a Zapínací svetry",
        "mystuff.edit.categories.bracelets": "Náramky",
        "mystuff.edit.categories.coverlets_and_capes": "Deky a přehozy",
        "mystuff.edit.categories.decorations": "Dekorace",
        "mystuff.edit.categories.dog_clothes": "Oblečení pro psy",
        "mystuff.edit.categories.dresses_and_skirts": "Šaty a Sukně",
        "mystuff.edit.categories.earrings": "Náušnice",
        "mystuff.edit.categories.flowers_and_brooches": "Květiny a Brože",
        "mystuff.edit.categories.footwear": "Obuv",
        "mystuff.edit.categories.hats": "Čepice",
        "mystuff.edit.categories.materials": "Materiály",
        "mystuff.edit.categories.mittens": "Rukavice",
        "mystuff.edit.categories.mobile_accessories": "Příslušenství na mobily",
        "mystuff.edit.categories.necklaces": "Náhrdelníky",
        "mystuff.edit.categories.pictures": "Obrazy",
        "mystuff.edit.categories.rings": "Prsteny",
        "mystuff.edit.categories.scarves_and_shawls": "Šaly a Přehozy",
        "mystuff.edit.categories.socks": "Ponožky",
        "mystuff.edit.categories.sweaters_and_jakets": "Svetry a Saka",
        "mystuff.edit.categories.toys": "Hračky",
        "mystuff.edit.categories.other": "Jiné",
        "mystuff.edit.sellingDescription": "Prosím, vyberte vlastnosti výrobku k prodeji.",
        "mystuff.edit.price": "Cena",
        "mystuff.edit.currency": "Měna",
        "mystuff.edit.saveAndPublish": "Uložit a zveřejnit",
        "mystuff.edit.saveAsDraft": "Uložit jako rozepsaný",
        "mystuff.edit.saveConditions": "Abyste mohli vložit výrobek musíte ho pojmenovat, přiložit minimálně jednu fotku, přiřadit kategorii a uvést cenu."
    },
    "ru": {
        "common.lang": 'Русский',
        "common.shortLang": "RU",
        "common.waitPlease": "Подождите пожалуйста...",
        "common.econorCompany": "Econor, s.r.o.",
        "common.pragueCz": 'Прага, Чешская республика',
        "common.countryFilter": 'Страны',
        "common.shopFilter": 'Магазины',
        "common.apply": 'Применить',
        "common.loading": 'Загружается...',
        "common.sending": 'Отправляется...',
        "common.cancel": "Отмена",
        "common.pageNotFound": "404 - Не найдено",
        "common.pageCannotBeFound": "Приносим свои извинения, но страница которую вы искали не существует.",
        "common.errors.406": "406 - Неприемлемо",
        "common.errors.406.description": "Извините, но сделанный вами запрос неверен и поэтому не может быть обработан!",
        "common.importantInformation": "Важная информация!",
        "common.cookiesInfo": "Мы используем файлы cookie для улучшения обслуживания, которое мы предоставляем нашим клиентам. Используя этот сайт, вы соглашаетесь с нашей политикой по использованию cookies.",
        "common.gdprInfo": "Мы адаптировали нашу политику конфиденциальности в соответствии с новым Положением о защите данных общего пользования ЕС. Взгляните на наши изменения.",
        "common.moreInfo": "Подробней",
        "common.next": "Дальше",
        "common.errorMessage": "Произошла ошибка! Пожалуйста, попробуйте повторить ваш запрос снова!",

        "currency.czk": 'CZK - Чешская крона',
        "currency.eur": 'EUR - Евро',
        "currency.usd": 'USD - Доллар США',
        "currency.rub": 'RUB - Российский рубль',
        "currency.uah": 'UAH - Украинская гривна',

        "country.at": 'Австрия',
        "country.be": 'Бельгия',
        "country.de": 'Германия',
        "country.dk": 'Дания',
        "country.fr": 'Франция',
        "country.gb": 'Великобритания',
        "country.hr": 'Хорватия',
        "country.hu": 'Венгрия',
        "country.it": 'Италия',
        "country.lu": 'Люксембург',
        "country.nl": 'Нидерланды',
        "country.pl": 'Польша',
        "country.ro": 'Румыния',
        "country.ru": 'Россия',
        "country.se": 'Швеция',
        "country.si": 'Словения',
        "country.bg": 'Болгария',
        "country.cz": 'Чехия',
        "country.es": 'Испания',
        "country.fi": 'Финляндия',
        "country.gr": 'Греция',
        "country.ie": 'Ирландия',
        "country.lv": 'Латвия',
        "country.pt": 'Португалия',
        "country.sk": 'Словакия',
        "country.ua": 'Украина',
        "country.by": 'Беларусь',
        "country.ee": 'Эстония',
        "country.cl": 'Чили',
        "country.us": 'США',
        "country.ot": 'Другая',

        "topNavi.catalogue": 'Каталог',
        "topNavi.messages": 'Сообщения',
        "topNavi.wishlist": 'Отложенные',
        "topNavi.login": 'Войти',
        "topNavi.register": 'Регистрироваться',
        // "topNavi.account": 'Мой Аккаунт',
        "topNavi.profile": 'Профиль',
        "topNavi.logout": 'Выйти',
        "topNavi.mystuff": "Мои изделия",
        "topNavi.changePassword": "Изменить пароль",

        "footer.home": 'Домой',
        "footer.terms": 'Условия',
        "footer.gdprTitle": "Общий регламент по защите данных",
        "footer.cookies": "Cookies",

        "login.email": "Email",
        "login.password": "Пароль",
        "login.forgotPassword": 'Забыли пароль?',
        "login.forgotPassword.desc": "Пожалуйста, укажите свой email ниже и мы поможем вам",
        "login.clickHere": 'нажмите здесь',
        "login.usernameOrPasswordIncorrect": "Email или пароль не верны или не существуют! Пожалуйста проверьте введенную Вами информацию.",
        "login.forgotPassword.error": "Произошла ошибка при отправки сообщения о восстановлении пароля. Пожалуйста, попробуйте повторить ваш запрос снова.",
        "login.forgotPassword.sent": "Сообщение о востановление пароля было отправлено на указанный вами email.",
        "login.createYourAccount": "Пожалуйста, заполните регистрационную форму.",
        "login.register": "Зарегистрироваться",
        "login.nameRulesTips": "Имя должно состоять минимально из 3 символов (максимально 20) и может содержать толко латинские символы, цифры, символы подчеркивания и тире.",
        "login.passwordRulesTips": "Пароль должен состоять минимально из 6 символов (максимально 20) и может содержать толко латинские символы, цифры, символы подчеркивания и тире.",
        "login.agreement": "Я согласна с условиями присоединения и я согласна с обработкой персональных данных",
        "login.selectPlan": "Ваш план (вы сможете изменить его позже)",
        "login.plan.visitor": "Посетитель",
        "login.plan.visitor.desc": "Вы сможете только комментировать существующие изделия",
        "login.plan.member": "Автор",
        "login.plan.member.desc": "Вы сможете размещать свои изделия на продажу",
        "login.businessConditions": "Условия присоединения",
        "login.personalDataPolicy": "Политика персональных данных",
        "login.registrationFinished": "Вы успешно зарегистрированы на Valika.org. Для активации вашего аккаунта следуйте по ссылке, отправленной на ваш email.",
        "login.registrationFailed": "При регистрации произошли ошибки. Попробуйте зарегистрироваться еще раз.",
        "login.emailAlreadyDefined": "Email, который вы задали уже зарегистрирован. Пожалуйста проверьте введенную информацию. Если вы уже однажды зарегистрировались на сайте и не помните свой пароль – вы можете попробовать восстановить его.",
        "login.nameAlreadyDefined": "К сожалению, имя автора, которое вы ввели, уже используется . Попробуйте выбрать себе другое имя.",
        "login.activationFinished": "Ваш аккаунт успешно активирован. Теперь Вы можете авторизоваться на сайте используя Ваш е-mail и пароль. Вы можете контролировать информацию в Вашем профиле, Ваши изделия и другие вещи, используя панель ссылок вверху каждой страницы.",
        "login.accountActivation": "Активация аккаунта",
        "login.activationFailed": "В процессе активации произошла ошибка. Предоставленный Вами ключ неверен. Попробуйте провести активацию еще раз. Если повторная активация не помогла, пожалуйста свяжитесь с нами для решения данной проблемы.",

        "product.reviews": 'Отзывы',
        "product.resultsFound": 'Изделий найдено',
        "product.resultsNotFound": 'К сожалению ничего не найдено. Попробуйте изменить критерии поиска...',
        "product.approx": 'ориентировочно',
        "product.addToWishlist": 'Добавить в отложенные',
        "product.removeFromWishlist": 'Удалить из отложенных',
        "product.askQuestion": 'Задать вопрос',
        "product.description": 'Описание',
        "product.moreFrom": 'Еще от',
        "product.totalNum": 'всего',
        "product.stars5": "5 звезд",
        "product.stars4": "4 звезды",
        "product.stars3": "3 звезды",
        "product.stars2": "2 звезды",
        "product.stars1": "1 звезда",
        "product.rateItem": 'Оценить эту работу',
        "product.rating.1": "1 звезда из 5",
        "product.rating.2": "2 звезды из 5",
        "product.rating.3": "3 звезды из 5",
        "product.rating.4": "4 звезды из 5",
        "product.rating.5": "5 звезд из 5",
        "product.leaveComment": "Оставить комментарий",
        "product.stayAnonymous": "Остаться анонимным",
        "product.reviewWasNotSent": "При отправке оценки произошла ошибка.",
        "product.anonymously": "Анонимно",
        "product.reply": "ответить",
        "product.hidden": "Скрыто",
        "product.remove": "Удалить продукт",
        "product.confirmRemove": "Вы на самом деле хотите удалить продукт ",

        "messages.notFound": "У вас нет сообщений",
        "messages.message": 'Сообщение',
        "messages.send": "Отправить",
        "messages.messageWasSent": "Сообщение было успешно отправлено.",
        "messages.messageWasNotSent": "При отправке сообщения произошла ошибка.",

        "profile.saveChanges": "Сохранить изменения",
        "profile.picture": "Изображение",
        "profile.authorName": "Имя",
        "profile.country": "Страна",
        "profile.city": "Город",
        "profile.aboutMe": "Обо мне",
        "profile.email": "Адрес Email",
        "profile.upload": "Загрузить изображение",
        "profile.remove": "Удалить",
        "profile.changesNotSaved": "Ошибка! Изменения не сохранены.",
        "profile.useFilesNoLargerThan": "Используйте изображения '.jpg', '.gif' или '.png', размером не более 5МБ.",
        "profile.uploadImagesToSite": "Загрузите выбранные изображения на сайт.",
        "profile.selectImageToUpload": "Задайте изображение для загрузки или просто перетащите файл на область выше",
        "profile.oldPassword": "Старый пароль",
        "profile.repeatPassword": "Повтор пароля",
        "profile.passwordChangedSuccessfuly": "Пароль успешно изменен!",
        "profile.passwordChangeUnsuccessful": "Пароль невозможно изменить. Пожалуйста попробуйте повторить свой запрос.",

        "mystuff.editProduct": "Редактировать продукт",
        "mystuff.newProduct": "Новый продукт",
        "mystuff.edit.info": "Описание",
        "mystuff.edit.sort": "Классификация",
        "mystuff.edit.selling": "Продажа",
        "mystuff.edit.images": "Изображения",
        "mystuff.edit.defineTitleAndDescription": "Задайте название и описание продукта.",
        "mystuff.edit.title": "Название",
        "mystuff.edit.description": "Описание",
        "mystuff.edit.fillProductProperties": "Задайте ниже свойства продукта: материалы, размеры, цвета, техника исполнения и т.п. Можете оставить некоторые свойства незаполненными.",
        "mystuff.edit.property": "Свойство",
        "mystuff.edit.upload": "Загрузить",
        "mystuff.edit.selectCategories": "Выберете категории, к которым можно отнести ваш продукт. Вы должны выбрать хотя бы одну категорию.",
        "mystuff.edit.categories": "Категории",
        "mystuff.edit.categories.accessories_for_hair": "Принадлежности для волос",
        "mystuff.edit.categories.baby_clothing": "Одежда для детей",
        "mystuff.edit.categories.bags": "Сумки",
        "mystuff.edit.categories.boleros_and_cardigans": "Болеро и Кардиганы",
        "mystuff.edit.categories.bracelets": "Браслеты",
        "mystuff.edit.categories.coverlets_and_capes": "Покрывала и накидки",
        "mystuff.edit.categories.decorations": "Декорации",
        "mystuff.edit.categories.dog_clothes": "Одежда для собак",
        "mystuff.edit.categories.dresses_and_skirts": "Платья и Юбки",
        "mystuff.edit.categories.earrings": "Сережки",
        "mystuff.edit.categories.flowers_and_brooches": "Цветки и Брошки",
        "mystuff.edit.categories.footwear": "Обувь",
        "mystuff.edit.categories.hats": "Головные уборы",
        "mystuff.edit.categories.materials": "Материалы",
        "mystuff.edit.categories.mittens": "Варежки",
        "mystuff.edit.categories.mobile_accessories": "Аксессуары для телефонов",
        "mystuff.edit.categories.necklaces": "Ожерелья",
        "mystuff.edit.categories.pictures": "Картины",
        "mystuff.edit.categories.rings": "Кольца",
        "mystuff.edit.categories.scarves_and_shawls": "Шарфы и Шали",
        "mystuff.edit.categories.socks": "Носки",
        "mystuff.edit.categories.sweaters_and_jakets": "Свитера и Жакеты",
        "mystuff.edit.categories.toys": "Игрушки",
        "mystuff.edit.categories.other": "Другое",
        "mystuff.edit.sellingDescription": "Задайте информацию об условиях продажи продукта.",
        "mystuff.edit.price": "Цена",
        "mystuff.edit.currency": "Валюта",
        "mystuff.edit.saveAndPublish": "Сохранить и опубликовать",
        "mystuff.edit.saveAsDraft": "Сохранить как черновик",
        "mystuff.edit.saveConditions": "Для сохранения продукта вам необходимо задать заголовок, хотя бы одно изображение, категорию продукта и цену."
    }
};

export default i18n;
