import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from "react-intl";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import RouteParams from "../services/RouteParams";
import LinkService from "../services/LinkService";
import ProductService from "../services/ProductService";
import ProductDescription from "./ProductDescription";
import ImageThumbnails from "./ImageThumbnails";
import ProductDetails from "./ProductDetails";
import ProductReviews from "./ProductReviews";

class Product extends Component {
    state = {
        product: null,
        lastUrl: null,
        showReviews: null,
        show404: false
    };

    loadProduct = () => {
        const furl = RouteParams.productFurl();
        if (furl === undefined) {
            window.location = LinkService.getHome();
        }
        ProductService
            .productByFurl(furl)
            .then(result => {
                this.setState({product: this.precalculateProductDetails(result)});
                window.scroll(0, 0);
            })
            .catch(error => {
                this.setState({show404: true});
                window.scroll(0, 0);
            });
    };

    precalculateProductDetails = (product) => {
        const {formatMessage} = this.props.intl;

        let rating = {
            "5": {value: 0, percent: 0},
            "4": {value: 0, percent: 0},
            "3": {value: 0, percent: 0},
            "2": {value: 0, percent: 0},
            "1": {value: 0, percent: 0}
        };

        let count = 0;
        let sum = 0;
        product.reviews.forEach(review => {
            if (review.rating > 0) {
                count++;
                sum += review.rating;
                rating[review.rating].value = rating[review.rating].value + 1;
            }
        });
        if (count > 0) {
            product.rating = sum / count;
            product.reviewsCount = count;
        }

        let maxCount = 0;
        for (let key in rating) {
            maxCount = Math.max(maxCount, rating[key].value);
        }
        if (maxCount > 0) {
            for (let key in rating) {
                rating[key].percent = Math.round(rating[key].value * 100 / maxCount);
            }
        }

        product.starRating = [{
            title: formatMessage({id: "product.stars5"}),
            rating: rating["5"]
        }, {
            title: formatMessage({id: "product.stars4"}),
            rating: rating["4"]
        }, {
            title: formatMessage({id: "product.stars3"}),
            rating: rating["3"]
        }, {
            title: formatMessage({id: "product.stars2"}),
            rating: rating["2"]
        }, {
            title: formatMessage({id: "product.stars1"}),
            rating: rating["1"]
        }];

        return product;
    };

    showReviews = () => {
        this.setState({showReviews: Date.now()})
    };

    componentWillMount = () => {
        this.loadProduct();
        this.setState({lastUrl: window.location.pathname + window.location.search});
    };

    componentWillReceiveProps = (nextProps) => {
        const params = nextProps.location.pathname + nextProps.location.search;
        if (this.state.lastUrl !== params) {
            if (this.state.lastUrl !== null) {
                this.loadProduct();
            }
            this.setState({lastUrl: params});
        }

        if (this.props.user !== nextProps.user) {
            this.loadProduct();
        }
    };

    render = () => {
        const {product} = this.state;
        return (
            <React.Fragment>
                {product &&
                <Row>
                    <Col md={7}>
                        <ImageThumbnails product={product}/>
                        <Row style={{marginBottom: 20}}>
                            <Col md={{span: 10, offset: 2}}>
                                <ProductDetails product={product}
                                                user={this.props.user}
                                                showReviews={this.state.showReviews}
                                                onProductUpdated={this.loadProduct}
                                                onLogin={this.props.onLogin}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={5}>
                        <ProductDescription product={product}
                                            user={this.props.user}
                                            onAddedToWishlist={this.props.onAddedToWishlist}
                                            onRemovedFromWishlist={this.props.onRemovedFromWishlist}
                                            onLogin={this.props.onLogin}
                                            onShowReviews={this.showReviews}
                        />
                        <ProductReviews product={product} user={this.props.user}/>
                    </Col>
                </Row>
                }
                {this.state.show404 === true &&
                <div className="jumbotron">
                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <p style={{textAlign: "center"}}>
                                <i className="fas fa-5x fa-exclamation-triangle text-danger"/>
                                <h1 className="display-4">
                                    <FormattedMessage id="common.pageNotFound"/>
                                </h1>
                                <p className="lead">
                                    <FormattedMessage id="common.pageCannotBeFound"/>
                                </p>
                            </p>
                        </Col>
                    </Row>
                </div>
                }
            </React.Fragment>
        )
    }
}

Product.propTypes = {
    location: PropTypes.object.isRequired,
    user: PropTypes.object,
    onLogin: PropTypes.func.isRequired,
    onAddedToWishlist: PropTypes.func.isRequired,
    onRemovedFromWishlist: PropTypes.func.isRequired,
};

export default injectIntl(Product);
