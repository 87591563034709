import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Image from "react-bootstrap/Image";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from 'react-bootstrap/Dropdown'
import UserService from "../services/UserService";
import Alert from 'react-bootstrap/Alert'
import UploadImageDialog from "./UploadImageDialog";

const LANGS = ["GB", "AT", "BE", "BG", "BY", "CL", "HR", "DK", "EE", "FI", "FR", "IE", "IT", "LV", "LU", "HU", "NL",
    "DE", "PL", "PT", "RO", "RU", "SK", "SI", "US", "UA", "CZ", "GR", "ES", "SE", "OT"];


class ProfileDialog extends Component {
    state = {
        user: null,
        saving: false,
        showError: false,
        showUploadDialog: false
    };

    componentWillMount() {
        this.resetForm();
    }

    resetForm = () => {
        const {user} = this.props;
        this.setState({
            user: {
                avatar: user.avatar,
                email: user.email,
                name: user.name,
                country: user.country,
                city: user.city,
                about: user.about
            }
        });
    };

    updateUserField = (field, value) => {
        let user = this.state.user;
        user[field] = value;
        this.setState({user: user});
    };

    saveChanges = () => {
        this.setState({
            saving: true,
            showError: false
        });
        UserService
            .update(this.state.user)
            .then(() => {
                this.props.onUpdateUserDetails();
                this.setState({saving: false});
            })
            .catch(error => {
                this.setState({
                    saving: false,
                    showError: true
                });
            });
    };

    isFormUpdated = (avatar, email, name, country, city, about) => {
        let user = this.props.user;
        return avatar !== user.avatar || email !== user.email || name !== user.name || country !== user.country ||
            city !== user.city || about !== user.about;
    };

    render = () => {
        const {user} = this.state;
        const {formatMessage} = this.props.intl;
        return (
            <Form>
                <Alert dismissible variant="danger"
                       show={this.state.showError}
                       onClose={() => this.setState({showError: false})}
                >
                    <FormattedMessage id="profile.changesNotSaved"/>
                </Alert>
                <UploadImageDialog show={this.state.showUploadDialog}
                                   onImageUpload={(image) => this.updateUserField("avatar", image)}
                                   onClose={() => this.setState({showUploadDialog: false})}
                                   uploadFn={(file) => UserService.uploadAvatar(file)}
                />
                <Form.Group>
                    <Form.Label><FormattedMessage id="profile.picture"/></Form.Label>
                    <DropdownButton variant="outline-light"
                                    size="lg"
                                    drop="right"
                                    id="profilePicture"
                                    title={
                                        <Image src={user.avatar}
                                               rounded style={{width: 100, height: 100}}
                                        />
                                    }
                    >
                        <Dropdown.Item eventKey="1" as={Button} onClick={() => this.setState({showUploadDialog: true})}>
                            <i className="fas fa-file-upload"/>
                            &nbsp;&nbsp;
                            <FormattedMessage id="profile.upload"/>
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="2" as={Button}
                                       onClick={() => this.updateUserField("avatar", "http://valika.org/images/valika.jpg")}
                        >
                            <i className="fas fa-trash"/>
                            &nbsp;&nbsp;
                            <FormattedMessage id="profile.remove"/>
                        </Dropdown.Item>
                    </DropdownButton>
                </Form.Group>

                <Form.Group>
                    <Form.Label><FormattedMessage id="profile.email"/></Form.Label>
                    <Form.Control readOnly value={user.email}/>
                </Form.Group>

                <Form.Group>
                    <Form.Label><FormattedMessage id="profile.authorName"/></Form.Label>
                    <Form.Control readOnly value={user.name}/>
                </Form.Group>

                <Form.Group>
                    <Form.Label><FormattedMessage id="profile.country"/></Form.Label>
                    <Form.Control as="select" value={user.country}
                                  onChange={e => this.updateUserField("country", e.target.value)}
                    >
                        {
                            LANGS.map(lang => {
                                return (
                                    <option key={lang} value={lang}>
                                        {formatMessage({id: "country." + lang.toLowerCase()})}
                                    </option>
                                );
                            })
                        }
                    </Form.Control>
                </Form.Group>

                <Form.Group>
                    <Form.Label><FormattedMessage id="profile.city"/></Form.Label>
                    <Form.Control type="text" value={user.city}
                                  onChange={e => this.updateUserField("city", e.target.value)}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Label><FormattedMessage id="profile.aboutMe"/></Form.Label>
                    <Form.Control as="textarea" rows="3" value={user.about}
                                  onChange={e => this.updateUserField("about", e.target.value)}
                    />
                </Form.Group>


                <Button variant="primary" onClick={this.saveChanges}
                        disabled={!this.isFormUpdated(user.avatar, user.email, user.name, user.country, user.city, user.about) || this.state.saving}
                >
                    {!this.state.saving ? formatMessage({id: "profile.saveChanges"}) : formatMessage({id: "common.waitPlease"})}
                </Button>
                <span> </span>
                <Button variant="outline-secondary" onClick={this.resetForm}
                        disabled={!this.isFormUpdated(user.avatar, user.email, user.name, user.country, user.city, user.about) || this.state.saving}
                >
                    <FormattedMessage id="common.cancel"/>
                </Button>
            </Form>
        )
    }
}

ProfileDialog.propTypes = {
    user: PropTypes.object.isRequired,
    onUpdateUserDetails: PropTypes.func.isRequired
};

export default injectIntl(ProfileDialog);
