import React, {Component} from 'react';

class HelpTermsRu extends Component {
    render() {
        return (
            <React.Fragment>
                <h1>Условия присоединения</h1>
                <p>Регистрируясь на сервере Valika.org пользователь соглашается с данными условиями.</p>
                <h2>Правила использования</h2>
                <p>Сервер Valika.org является торговой площадкой, которая оказывает посреднические услуги по продаже и
                    покупке
                    изготовленных вручную изделий из шерсти. Под Продавцом понимается лицо осуществляющее продажу на
                    сервере Valika.org.
                    Под Покупателем понимается лицо выразившее согласие приобрести товар выставленный на Valika.org
                    Продавцом.</p>
                <ul>
                    <li>Регистрация на сервере Valika.org для Продавца является бесплатной.</li>
                    <li>При покупке Покупатель оплачивает изделие напрямую Продавцу на счет, либо наложенным платежом.
                        Сервер Valika.org
                        не берет никаких платежей от Покупателя.
                    </li>
                    <li>Продавец обязан заплатить серверу Valika.org комиссию за оказанные посреднические услуги по
                        поиску Покупателя и
                        организацию продажи ищделия Продавца, эта обязанность возникает в момент подтверждения покупки
                        Покупателем и
                        генерированием им счета на покупку для последующей отправки его (счета) Продавцу. В случае если
                        Покупатель после
                        отправки счету Продавцу отменит инициированную им сделку (не заплатит стоимость изделия,
                        откажется получать
                        изделие Продавца) Продавец может запросить сервер Valika.org о скидке с комиссии за последующие
                        продажи.
                    </li>
                    <li>Компания Econor s.r.o., владелец сервера Valika.org не является собственником выставленных на
                        сервере изделий и
                        не несет ответственность за товары, предлагаемые к продаже на сайте, а именно не может
                        гарантировать
                        соответствие товара на фотографии, доступность изделия, актуальную цену товара и т.д. Владелец
                        сервера не несет
                        ответственности за процессы покупку, продажи или возврата проданного товара. Также Владелец
                        сервера не несет
                        ответственности за возможные споры между Продавцом и Покупателем. Владелец сервера не несет
                        ответственности за
                        случаи указания неверной информации при регистрации на сервере. Владелец сервера не осуществляет
                        проверку данных
                        Продавца и Покупателя на идентичность.
                    </li>
                    <li>Владелец сервера не принимает участия в размещении товаров и регистрации Продавцов и поэтому не
                        гарантрует
                        корректность описания товаров, их изображений или информации о регистрации Продавцов.
                    </li>
                    <li>Пользователи сервера Valika.org обязаны придерживаться законов Чешской республики, а также
                        соблюдать нормы
                        этикета и порядочность.
                    </li>
                </ul>

                <h2>I. Условия использования</h2>
                <p>Деятельность по продаже изделий на сервере Valika.org является открытой и доступной для любых лиц,
                    достигших 18 лет а
                    также для зарегистрированных правовых субъектов. Владелец сервера оставляет за собой право
                    отказывать в своих
                    услугах пользователям, нарушающим данные правила.</p>
                <p>Сервер Valika.org принадлежит компании Econor s.r.o. Пользователи сервера а также лица, выражающие
                    желание
                    зарегистрироваться на сервере, не имеют никаких юридических прав на сервер Valika.org, также не
                    имеют права на
                    заключение договоров подразумевающих обладание подобными юридическими правами. Администрация сайта
                    наделена
                    исключительным правом отказать в регистрации соискателю, отступить от договора с существующим
                    пользователем или
                    ограничить доступ к ряду сервисов, предоставляемых сервером Valika.org, без объяснения причин.</p>
                <p>Администрация сайта не гарантирует поддержание, сохранение и непрерывность работы ресурса.</p>
                <p>При использовании сервисов сервера Valika.org действуют представленные правила.</p>

                <h2>II. Регистрация</h2>
                <p>Условием оказания посреднических услуг по продаже изделий посредством сервера Valika.org является
                    согласие с данными
                    условиями и регистрация пользователя на сервере.</p>
                <p>Пользователь, который является физической особой, обязан при регистрации задать имя, фамилию, полный
                    адрес
                    (постоянный либо адрес регистрации), email, пользовательское имя и пароль. Пользователь, который
                    является
                    юридическим лицом, обязан указать торговое наименование фирмы/компании, идентификационный номер
                    налогоплательщика
                    (если таковой имеется), юридический адрес фирмы, email, Имя и Фамилию особы, ответственной за
                    ведение переговоров от
                    лица фирмы. Пользователь обязан информировать админстрацию сервера Valika.org о любых изменениях
                    представленной для
                    регистрации информации а также своевременно актуализировать данную информацию.</p>
                <p>Администрация сервера в праве предоставить Продавцу или Покупателю необходимую информацию о другой
                    стороне (например
                    по причине не отправки товара после оплаты, либо по причине жалобы покупателя), включая имя и
                    фамилию, торговое
                    название фирмы, адреса или регистрации по месту пребывания или места юридического адреса фирмы. </p>
                <p>Пользователи имеют доступ к своим личным данным и могут их изменять. Пользователи также имеют право
                    запросить сервер
                    об удалении личных данных с базы сервера Валика после окончания деятельности на сайте.</p>
                <p>Пользователи имею право владеть больше чем одним пользовательским аккаунтом, однако владение
                    несколькими аккаунтами
                    не дает им право нарушать условия соглашения или извлекать двойную выгоду ограниченных служб на
                    сайте (например:
                    манипулирования с аккаунтами в целях сокрытия идентичности или в целях манипуляций с продажами). Все
                    пользователи
                    обязаны информировать о наличии других аккаунтов на сервере в своем профиле.</p>
                <p>Регистрация подверждается путем автоматической отправки письма на представленный email. Регистрации,
                    которые не
                    подтверждены письмом на email являются неактивными и недействительны. Подтверждением регистрации
                    является заключение
                    соглашения между владельцем сервера Валика и пользователем.</p>
                <p>Регистрация осуществляется на конкретного пользователя и перевод регистрации на другую особу
                    запрещен.</p>

                <h2>III. Запрещенные товары</h2>
                <p>На сервере Валика запрещаются к размещению следующие товары: Предложение товаров, размещение и
                    продажа которых ведет
                    к нарушению норм этичного поведения либо правовые предписания страны где находится пользователь.
                    Запрещено
                    предложение товаров, которые не отвечают специфике сервера, а также товары, на которые продавец не
                    имеет собственное
                    право или право продавать эти товары, либо распоряжение этими товарами ограничено - оружие,
                    алкоголь, психотропные
                    вещества, лекарства, медицинские принадлежности, порнография или иные порнографические или
                    эротические материалы,
                    товары, использование которых может привести к нарушению норм авторского и морального права
                    (неправомерное
                    использование марок, брендов, торговых знаков, находящихся по охраной авторских прав), товары, для
                    целей пропаганды
                    политических партий, товары нарушающие права и свободы человека или народа, имеющие признаки
                    национализма, неприятия
                    вероисповедания или иное.</p>
                <p>Администрация наделена исключительным правом удалять перечисленные виды товаров с сервера без
                    объяснения причин.</p>

                <h2>IV. Покупка товаров</h2>
                <p>Покупатель, выбирая товары выставленные Продавцом, соглашается с тем, что сервер Valika не является
                    владельцем
                    указанных изделий а только оказывает посреднические услуги по продаже выставленного Продавцом
                    товара. Администрация
                    сервера не несет ответственности за продаваемые товары.</p>
                <p>Покупатель принимает то, что товары, находящиеся в корзине могут принадлежать разным продавцам, и в
                    результате
                    покупке Покупателю будет выставлено несколько счетов от разных Продавцов. При этом условия доставки,
                    например такие
                    как, время доставки товара могут различаться между разными Продавцами.</p>
                <p>Сумма за доставку указывается в счете и прибавляется к стоимости изделия, в случае если продавец не
                    указал иное. К
                    оплате подлежит полная сумма счета.</p>
                <p>Покупатель принимает то, что отправкой счета на товар только информирует Продавца о желании купить
                    выбранный товар,
                    но непосредственная отправка товара будет осуществлена Продавцом только после подтверждения заказа
                    продавцом и
                    оплаты полной стоимости товара (включая доставку) покупателем.</p>
                <p>Покупатель принимает то, что Продавец имеет право отказать в принятии заказа или части заказа. В этом
                    случае
                    Покупатель оплачивает только подтвержденную часть заказа.</p>
                <p>Покупатель не оплачивает комиссию Серверу за посреднические услуги по осуществлению продажи. Оплата
                    товаров
                    производится напрямую на счет Продавца (либо иным способом, в соответствии с указанными платежными
                    условиями
                    Продавца).</p>

                <h2>V. Продажа товаров</h2>
                <h3>V. I. Определение товара</h3>
                <p>Сервер Valika.org предназначен для продажи новых креативных товаров изготовленных из шерсти лично
                    руками
                    Продавцов.</p>

                <h3>V. II. Продавцы</h3>
                <p>Продавцом является физическая или юридическая особа, за которую ведет переговоры физическое лицо.</p>

                <h3>V. III. Правила размещения изделий на сервере Valika.org</h3>
                <p>Каждая представленная позиция в части продаж сервера должна быть конкретным товаром, предназначенным
                    для продажи
                    (запрещено представлять товары только ради представления).</p>
                <p>Продавцы представляют товары с помощью/посредством электронного формуляра, в котором обязаны
                    заполнить все
                    обязательные позиции.</p>
                <p>Предлагаемые товары, их изображения и описание должны быть полными и наиболее подробно описывать
                    действительное
                    состояние изделия (рекомендуем использовать дополнительные фотографии). Изображение товара и его
                    описание должно в
                    полной мере информировать покупателей, чтобы минимизировать поиск замены или ошибочного восприятия
                    покупателем
                    изображенного изделия.</p>
                <p>Чтобы Вы могли начать продавать на Valika.org необходимо:</p>
                <ol>
                    <li>Зарегистрироваться</li>
                    <li>Заполнить информацию о себе.</li>
                    <li>Разместить фотографии изделий.</li>
                    <li>заполнить информацию о товаре: материал, размер, цена и описание.</li>
                </ol>
                <p><strong>Обращаем Ваше внимание!</strong></p>
                <li>Сервер оказывает посреднические услуги между покупающим и продающим. Оплата и платежные условия
                    обсуждаются
                    покупающим и продающим.
                </li>
                <p><strong>Размещение изделий на сервере Valika.org - бесплатно.</strong></p>
                <p>Зарегистрированные пользователи имеют следующие возможности:</p>
                <ul>
                    <li>персональный магазин - место где могут предлагать и продавать свои товары, коммуницировать/вести
                        переписку с
                        клиентами и заказчиками - каждый автор имеет свой собственный адрес магазина, который выглядит:
                        имя
                        магазина.valika.org.
                    </li>
                    <li>автор может разместить интернет-адрес своего магазина в своем блоге или в (функциональность
                        доступна в профиле
                        автора в закладке Торговые настройки).
                    </li>
                </ul>

                <h3>V. IV. Запрещенные товары / запрещенная информация</h3>
                <p>Запрещено продавать изделия, которые не выполнены руками Продавца (либо выполнены не на основании
                    собственного
                    графического / дизайнерского образца) или объединения продавцов (см. пункт V.II Продавец), или
                    перепродажу изделий
                    другого Продавца.</p>
                <p>Запрещено предагать на продажу услуги (кроме курсов или мастер-классов). Предметом продажи всегда
                    является товар, с
                    четко определенной ценой, содержанием и параметрами, так, чтобы Покупатель мог отправить/ вложить
                    изделия в корзину
                    и купить его.</p>
                <p>Запрещено предлагать на продажу изделия, которые нарушают авторское право (например копии изделий
                    других
                    авторов).</p>
                <p>Запрещено копировать изделия - предлагать одинаковые изделия под множеством позиций на сайте.</p>
                <p>Заголовок, описание, ключевые слова или фото изделия не может содержать контактную информацию
                    продавца, такую как
                    номер телефона, email, ссылку на интернет страницу а т.д. (за исключением имени и фамилии либо
                    логотипа автора),
                    другие указанные контактные данные (или побуждение к поиску этих данных в интернете), не может
                    содержать другие
                    элементы письменной, или графической продажи, как например комментарии, дискуссии, блоги или
                    внутреннюю почту и
                    т.д.</p>
                <p>Ни заголовок товара, ни его описание не могут содержать какие-либо ключевые слова, используемые с
                    целью
                    манипулирования результатами поиска. Под манипуляцией подразумевается использование слов не имеющих
                    ничего общего с
                    позицией или с описанием изделия и заданными исключительно с целью повышения результатов поиска
                    изделия.</p>
                <p>Ни заголовок изделия, ни его описание не может содержать элементы HTML, JavaScript, PHP и других
                    языков
                    программирования с целью улучшить представление или продаваемость изделия, или каким-либо ином
                    образом нарушить
                    деятельность сервера Valika.org.</p>
                <p>Запрещено использовать фотографии, которые могли бы нарушить авторские права (например фотографии из
                    журналов, к
                    которым продающий не имеет отношения и на которые не имеет авторских прав).</p>
                <p>В случае специальной подготовки изображений товара (например таких как правки в графических
                    редакторах, ретуширование
                    и т.п.), которая существенно видоизменяет представление товара, Продавец должен специально указывать
                    в описание
                    товара либо непосредственно в изображении товара о проведенных изменениях.</p>
                <p>Продавецу запрещено общаться с Покупателем о возможности продажи вне сервера (ссылки на собственный
                    интернет-магазин,
                    инструкция к поиску в интернете, предоставление контактных данных) или о возможности обмена
                    изделиями (такой как
                    встречная услуга или зачет требований) без использования процесса покупки-продажи сервера
                    Valika.org.</p>

                <h3>V. V. Платежные условия, условия доставки, цена изделия.</h3>
                <p><strong>Цена изделия</strong></p>
                <p>Все продавцы представляют цены включая НДС. Продающий обязан представить конечную цену изделия и
                    предлагать изделия
                    за указанную цену (запрещается продавать изделие по предоплате).</p>
                <p>Указанная цена изделия должна быть приближенной к цене использованого материала, технике, времени
                    изготовления и
                    авторского мастерства. Запрещено устанавливать завышенную либо заниженную цену, например с целью,
                    чтобы товар не был
                    продан или наоборот был продан за символическую цену с целью понижения комиссии.</p>
                <p><strong>Условия платежа</strong></p>
                <ul>
                    <li>100% предоплата - осуществляется банковским переводом. Номер банковского счета будет
                        представлено в email,
                        который будет отправлен после резервации изделия.
                    </li>
                    <li>Наложенный платеж - изделие оплачивается при получении на почте.</li>
                </ul>
                <p>В настоящий момент сервер Valika.org не поддерживает оплату через он-лайн. В будущем планируется
                    использование
                    системы оплаты Pay Pal и кредитные карты.</p>
                <p>Способ доставки и оплаты изделия обсуждается между Продавцом и Покупателем.</p>
                <p><strong>Условия доставки</strong></p>
                <p>Почтовые расходы или расходы доставки указывается Продавцом отдельно. Покупатель вправе выбрать и
                    указать наиболее
                    подходящее ему условия доставки. Продавец осуществляет упаковку и отправку изделия Покупателю. Срок
                    доставки
                    определяется на основании местонахождения Продавца и Покупателя.</p>

                <h3>V. VI. Изменение информации о товарах</h3>
                <p>Продавец имеет возможность в любой момент удалить или скрыть изделие на неограниченный срок. Продавец
                    также может
                    изменять и редактировать описание изделия.</p>

                <h4>V. VII. Торговое соглашение</h4>
                <p>Продавец информируется о намерении Покупателя произвести покупку при помощи системы сообщений сервера
                    Valika.org.
                    Данное намерение не является обязывающим Продавца произвести продажу и Продавец может отказать в
                    принятии
                    заказа.</p>
                <p>Продавец может, при получении заказа (фактуры), принять лишь часть заказа и указать только принятые
                    позиции.</p>
                <p>Принятием заказа (фактуры) обе стороны заключают торговые соглашение (продающий и покупающий)
                    Торговый договор
                    (покупка онлайн) на основании Законов Чешской республики.</p>
                <p>Продавец обязан отправить товары после принятия счета и подтверждения оплаты заказа без излишних
                    задержек.</p>

                <h2>VI. Комиссия за продажу изделий</h2>
                <p>Размещение изделий на сервере Valika.org для Продавца бесплатно. Однако Сервер Валиканачисляет
                    комиссию за
                    посреднические услуги за каждую покупку, совершенную на сервере. Продавец обязан заплатить комиссию
                    серверу после
                    подтверждения заказа. В случае если покупатель не произведет оплату изделия (а также не получит
                    изделие), продавец
                    обязан сообщить об этом в выписке к комиссии или если комиссия уже оплачена, то будет компенсирована
                    формой скидки в
                    следующий расчетный период.</p>
                <p>О итоговой сумме комиссии за период продавец информируется email. Администратор выставляет продавцу
                    фактуру на
                    оплату, которая может быть распечатана.</p>
                <p>Срок оплаты комиссии всегда указывается в фактуре (днем оплаты считается день поступления денежных
                    средств на счет
                    компании), в случае оплаты, произведенной после указанного срока оплаты, могут быть начислены пени.
                    Оплату владелец
                    сервера принимает только безналичным расчетом на счет компании.</p>
                <p>В случае неоплаты комиссии или несоблюдения срока оплаты, организатор имеет право заблокировать
                    аккаунт пользователя,
                    удалить его изделия из предложения на сайте, запретить повторную регистрацию на сервере Valika.org и
                    подать в суд на
                    возмещение задолженности.</p>
                <p>Сервер Valika.org необязан разрешать споры возникшие между продавцом и покупателем, а также
                    участвовать в процедуре
                    возврата товара, Продавец не имеет право требования на возврат комиссии в случае спора с
                    Покупателем.</p>
                <p>Актуальная сумма комиссии составляет 0% от цены изделия. Организатор сервера Valika.org имеет право
                    изменить величину
                    комиссии.</p>

                <h2>VII. Другие некоммерческие правила</h2>
                <p>Запрещено указывать прямые личные данных (адрес, номер телефона, емайл, сайт, ссылки на фейсбук,
                    ссылки на
                    одноклассники, ссылки на мой мир, ссылки на страницы вконтакте, ссылки на фотогалереи и др. соц
                    сети), в
                    комментариях, дискуссиях, клубах, внтренней почте и других элементах используемых для общения на
                    сервере Валика или
                    призыв к поиску указанных данных в интернете. Исключением является только Фамилия и Имя автора или
                    его авторский
                    псевдоним.</p>
                <p>Запрещено размещение любых рекламных объявлений или интернет ссылок с рекламным характером за
                    исключением поддержания
                    личного магазина на сервере Валика (только ссылка на сервер Валика).</p>
                <p>Запрещена рассылка сообщений, которые могут быть идентифицированы как спам. Например: повторяющаяся
                    отправка
                    рекламной информации или докучающей информации внутренней почтой, размещение указанной информации в
                    дискуссиях или
                    комментариях.</p>
                <p>Запрещено осуществлять деятельность, которая могла бы быть идентифицирована как недобросовествная
                    конкуренция
                    (например регистрация имени, которое могло бы помешать существующему продавцу, общаться или
                    предлагать товары с
                    целью заменить сущность изделия или продавца и т.д.)</p>
                <p>Запрещено с помощью коммуникационных элементов сервера (дискуссии, комментари, почта), общественно
                    подрывать
                    авторитет организатора или своим поведением демонстрировать неуважение и отсутствие лояльности к
                    компании -
                    организатору или сотрудниками компании.</p>
                <p>Запрещено публично вредить или атаковать продавца и покупателя. Негативная оценка (секция оценка
                    продавца)
                    неклассифицируется как вредительство, лишь как субъективная оценка. В случае спора рекомендуем Вам
                    разрешать вопросы
                    внутренней почтой или обращением к компании-организатору. Компания-организатор не несет
                    ответственности со случаи
                    возникновения споров.</p>

                <h2>VIII. Сохранение личных данных</h2>
                <p>Пользователь обязан указывать правдивую информацию и данные. Организатор осуществляет хранение
                    информации указанной
                    при регистрации пользователем в соответствии с законом 101/2000Sb, о охране личных данных, в
                    соответствии с
                    последними предписаниями и имеет право предоставить указанную информацию в соответствии с ниже
                    указанными условиями.
                    Пользователь соглашается с тем, чтобы организатор хранил информацию, которую пользователь
                    предоставил при
                    регистрации и использует только в соответствии с деятельностью сервера Valika.org. Пользователь
                    определенно
                    соглашается с тем, что его данные: имя и фамилия, торговая марка, название, номер телефона, место
                    жительства или
                    регистрации, юридический адрес -предоставляются организатором покупателям, которые проявили интерес
                    к покупке
                    изделия, а также продавцам, которые также проявили интерес к покупке предлагаемого изделия как
                    покупатели. Далее
                    пользователь соглашается с тем, что его данные могут быть предоставлены организатором в случае
                    жалобы на качество
                    изделия или недоставки изделия, когда организатор обязан предоставить личные данные на основании
                    закона.</p>
                <p>Предоставленные данные пользователь имеет право использовать только с целью однозначных случаев
                    использования на
                    сервере: либо к покупке или продаже изделия посредством сервера Valika.org, его обжалования или
                    жалобы на недоставку
                    изделия, в спорах по причине покупки или продажи. Организатор не имеет право не имеет право к
                    какому-либо
                    иному-другому использованию данных.</p>

                <h2>X. Условия использования фотографий</h2>
                <p>Размещением фотографий продавец дает компании - организатору право и согласие на использование
                    фотографий
                    размещенного изделия к целям пропагандирования/ рекламирования сервера Valika.org, а также с целью
                    повышения
                    популярности сервера Валика. А также соглашается с тем, что сервер Valika.org может предоставить
                    фотографии третьим
                    лицам (например газетам, журналам, интернет серверам и т.д., с целью повышения популярности сервера
                    Valika.org.</p>
                <p>Компания - организатор прилагает все усилия, чтобы каждая размещенная фотография или комплект
                    фотографий, был отмечен
                    логотипом Valika.orgили адресом сервера www.valika.org, в целях информирования о том, что изделие
                    продается на
                    сервере Valika.org.</p>
                <p>Компания-организатор обязуется не использовать фотографии пользователей ни для каких иных целей кроме
                    как повышения
                    популярности или продвижения сервера, и касательно указанных целей будет вести переговоры с третьей
                    стороной.</p>

                <h2>XI. Заключительные положения</h2>
                <p>Компания-организатор наделена правом незамедлительно разорвать данное соглашение с пользователями,
                    которые нарушают
                    правила использования сервера Valika.org, не имеют желание сотрудничать в разрешении стандартных и
                    исключительных
                    ситуаций, возникающих в процессе деятельности сервера Valika.org или в случае непогашения комиссии
                    за посреднические
                    услуги. Компания-организатор имеет право немедленно разорвать соглашение без разъяснений.</p>
                <p>Соглашение заключается в соответствии с указанными торговыми условиями и в соответствии с
                    законодательством Чешской
                    республики. Компания-организатор имеет право на внесение изменений в предоставляемые услуги и
                    условия торговли на
                    сервере без предварительного информирования.</p>
                <p>Любое копирование графического дизайна сервера Валика, а также имеющихся баз данных запрещено.</p>
                <p>Соглашение можно расторгнуть формой отмены/деактивации со стороны пользователя. Соглашение считается
                    расторженным с
                    момента решения пользователя и информирования компании-организатора.</p>
                <p>Все возникающие споры разрешаются в соответствии с законодательством Чешской республики по месту
                    нахождения юр.
                    адреса компании - организатора.</p>
                <p>В случае возникновения спора следует придерживаться условий на чешском языке. </p>
            </React.Fragment>
        )
    }
}

export default HelpTermsRu;
