import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";
import LinkService from "../services/LinkService";
import Card from "react-bootstrap/Card";
import ImageService from "../services/ImageService";

class SmallProductCard extends Component {
    state = {
        imageError: false,
    };

    handleImageLoad = (event) => {
        ImageService.calculateImageSize(event.target);
    };

    imageFallback = () => {
        this.setState({imageError: true});
    };

    render = () => {
        const {product} = this.props;
        return (
            <Card className="product" style={{marginBottom: 25}}>
                <LinkContainer to={LinkService.getProduct(product.url)}>
                    <a href={LinkService.getProduct(product.url)} className="product-card-card-image-frame">
                        <Card.Img variant="top"
                                  className="hvr-fade"
                                  onLoad={this.handleImageLoad}
                                  src={!this.state.imageError ? product.catalogImage : LinkService.getNoCatalogImage()}
                                  onError={this.imageFallback}/>
                    </a>
                </LinkContainer>
            </Card>
        )
    }
}

SmallProductCard.propTypes = {
    product: PropTypes.object.isRequired
};

export default SmallProductCard;
