import React, {Component} from 'react';

class HelpGDPRCz extends Component {
    render() {
        return (
            <React.Fragment>
                <h1>Zásady práce s osobními údaji pro uživatele internetového portálu Valika</h1>

                <h2>Obecná ustanovení</h2>
                <p>Valika klade na ochranu osobních údajů velký důraz. Již od počátku chráníme údaje našich uživatelů co
                    nejlépe
                    dovedeme. Citlivá data (jako například přihlašovací heslo) jsou šifrována, kontaktní údaje nikdy
                    nepředáváme do
                    doby, než je zprostředkována samotná objednávka či pokud nám to neukládá zákon.</p>
                <p>Účelem tohoto dokumentu je informovat vás o rozsahu a způsobu ukládání vašich osobních údajů, vašich
                    právech a o
                    formě jakou můžete svá práva uplatňovat.</p>

                <h2>Spravované osobní údaje</h2>
                <p>Na Valice ukládáme a spravujeme osobní údaje našich uživatelů. Rozsah údajů se liší dle role
                    uživatele. Záleží tedy
                    na tom, zdali jste nakupující či prodávající a také, zda jste registrováni či nikoliv.</p>

                <h3>Registrovaný uživatel na Valika.org</h3>
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th>údaj</th>
                        <th>účel/popis.</th>
                        <th>volitelné</th>
                        <th>veřejné</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>E-mailová adresa</td>
                        <td>Základní unikátní identifikátor účtu</td>
                        <td>NE</td>
                        <td>NE</td>
                    </tr>
                    <tr>
                        <td>Přihlašovací heslo</td>
                        <td>Uloženo v šifrované podobě</td>
                        <td>NE</td>
                        <td>NE</td>
                    </tr>
                    <tr>
                        <td>IP adresa registrace</td>
                        <td>Ukládá se adresa, ze které byl účet založen</td>
                        <td>NE</td>
                        <td>NE</td>
                    </tr>
                    <tr>
                        <td>Informace o bankovním účtu</td>
                        <td>Použije se při generování faktury zákazníkovi a bude zobrazena zákazníkovi pouze po jeho
                            žadosti o nákup.
                        </td>
                        <td>ANO</td>
                        <td>NE</td>
                    </tr>
                    <tr>
                        <td>Bydliště (Stát a Město)</td>
                        <td>Informace bude zobrazena na webových stránkách Valika.org</td>
                        <td>ANO</td>
                        <td>ANO</td>
                    </tr>
                    <tr>
                        <td>údaje o objednaném zboží, provedených objednávkách, zaplacených částkách, provedených
                            platbách apod.
                        </td>
                        <td></td>
                        <td>NE</td>
                        <td>NE</td>
                    </tr>
                    </tbody>
                </table>


                <h3>Nakupující</h3>
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th>údaj</th>
                        <th>volitelné</th>
                        <th>veřejné</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>E-mailová adresa</td>
                        <td>NE</td>
                        <td>NE</td>
                    </tr>
                    <tr>
                        <td>Jméno a příjmení</td>
                        <td>NE</td>
                        <td>NE</td>
                    </tr>
                    <tr>
                        <td>Adresa dodací</td>
                        <td>NE</td>
                        <td>NE</td>
                    </tr>
                    <tr>
                        <td>údaje o objednaném zboží, provedených objednávkách, zaplacených částkách, provedených
                            platbách apod.
                        </td>
                        <td>NE</td>
                        <td>NE</td>
                    </tr>
                    </tbody>
                </table>

                <h2>Účel a způsob zpracování osobních údajů</h2>
                <p>Vaše osobní údaje zpracováváme několika různými způsoby a pro odpovídající účely:</p>

                <h3>Nákup a prodej zboží a služeb na Valika.org</h3>
                <p>Abychom mohli zprostředkovat vaši objednávku potřebujeme znát a dále zpracovávat vaše osobní údaje.
                    Tyto údaje
                    zpracováváme výhradně za účelem zprostředkování uzavření kupní smlouvy a právním základem takového
                    zpracování je
                    tedy plnění kupní smlouvy.</p>
                <p>Tyto osobní údaje od vás získáváme výhradně na základě vaší registrace na Valika.org případně
                    vyplněním vašich údajů
                    na webové stránce s nákupním košíkem.</p>
                <p>Doba zpracování těchto údajů je po dobu vyřizování objednávky plus dobu minimálně 10 let, neboť tak
                    nám to přikazuje
                    daňový zákon.</p>

                <h3>Marketingová komunikace</h3>
                <p>V případě, že nám udělíte souhlas, budeme vaše osobní údaje (e-mailovou adresu) využívat pro zasílání
                    novinek o
                    výrobcích, zboží, akčních cenách a novinkách na Valice. V žádném případě nebude vaše e-mailová
                    adresa předána třetím
                    stranám a váš souhlas se zasíláním marketingových sdělení můžete kdykoliv a s okamžitou platností
                    odvolat kliknutím
                    na odkaz uvedený v každé emailové zprávě nebo ve svém účtu.</p>

                <h3>E-mailová podpora</h3>
                <p>V případě, že nás kontaktujete na některém z našich kontaktních emailů nebo pomocí kontaktního
                    formuláře, můžeme vás
                    požádat o poskytnutí jednoznačných identifikačních informací, které poslouží k ověření oprávněnosti
                    vašeho
                    požadavku. E-mailovou komunikaci s vámi archivujeme. Právním základem takového zpracování je náš
                    oprávněný
                    zájem.</p>

                <h3>Cookies</h3>
                <p>Při používání našich internetových stránek může docházet ke zpracování vašich osobních údajů
                    prostřednictvím cookies.
                    Více informací o tom, jak používáme cookies, naleznete <a href="/help/cookies">tady</a>.</p>

                <h2>Vaše práva jako subjektu údajů aneb co dělat, když chci své osobní údaje smazat nebo upravit</h2>
                <p>Pokud si přejete uplatnit níže uvedená práva a/nebo získat příslušné informace, můžete tak učinit
                    rovnou přihlášením
                    se do svého zákaznického účtu na stránkách Valika.org. Pokud u nás nemáte vytvořený účet a provedli
                    jste nákup bez
                    registrace, ale přesto byste rádi uplatnili některé z níže uvedených práv k ochraně svého soukromí,
                    můžete nás
                    kontaktovat prostřednictvím e-mailu info@valika.org.</p>
                <p>Pro přihlášení ke svému účtu potřebujete své přihlašovací údaje (uživatelské jméno/e-mail a heslo).
                    Pokud budete svá
                    práva uplatňovat jiným způsobem, můžeme vás požádat o poskytnutí jednoznačných identifikačních
                    informací, která
                    poslouží k ověření oprávněnosti vašeho požadavku. Odpovíme vám do 1 měsíce po obdržení vaší žádosti,
                    přičemž si však
                    vyhrazujeme právo tuto lhůtu prodloužit dle technické a časové náročnosti vašeho požadavku.</p>
                <p>V souladu s platnými právními předpisy máte právo požadovat přístup ke svým osobním údajům, které
                    jako správce
                    osobních údajů zpracováváme, právo na jejich opravu nebo vymazání. Můžete také kdykoliv vzít zpět
                    souhlas se
                    zpracováním některých osobních údajů (např. údajů k marketingovým účelům), který jste nám
                    poskytli.</p>
                <p>Jaká jsou tedy vaše práva?</p>

                <h3>Vymazání vašich osobních údajů</h3>
                <p>Kdykoliv nás můžete požádat o vymazání vašich osobních údajů. Pokud nás oslovíte s takovou žádostí,
                    smažeme bez
                    zbytečného odkladu všechny vaše osobní údaje, které máme, pokud však vaše osobní údaje nepotřebujeme
                    pro plnění
                    smluvních a zákonných povinností či ochranu našich oprávněných zájmů, jak jsou popsány výše.</p>

                <h4>Zrušení účtu nakupujícího</h4>
                <ol>
                    <li>Pokud nakupující provedl objednávku, jeho údaje týkající se objednávky jsme povinni uchovávat po
                        dobu minimálně
                        10 let od zrušení účtu.
                    </li>
                    <li>Pokud nakupující neprovedl žádnou objednávku jeho data mohou být na jeho žádost smazána s
                        výjimkou těch údajů,
                        která se týkají účelů zajištění bezpečnosti provozu systému a sítě (IP adresa).
                    </li>
                </ol>

                <h4>Zrušení účtu prodávajícího</h4>
                <ol>
                    <li>Pokud prodávající zrealizoval zprostředkovanou objednávku, jeho údaje týkající se objednávky
                        jsme povinni
                        uchovávat po dobu minimálně 10 let od zrušení účtu.
                    </li>
                    <li>Pokud prodávající nepřijal žádnou objednávku jeho data mohou být na jeho žádost smazána s
                        výjimkou těch údajů,
                        která se týkají účelů zajištění bezpečnosti provozu systému a sítě (IP adresa).
                    </li>
                </ol>

                <h3>Oprava vašich osobních údajů</h3>
                <p>Máte právo na opravu svých osobních údajů. Pokud máte účet na stránkách valika.org, můžete tyto údaje
                    upravit přímo
                    po přihlášení do svého účtu. Některé údaje není možné opravit přímo (například údaje uvedené na
                    faktuře), pak nás
                    prosím kontaktujte prostřednictvím e-mail nebo kontaktního formuláře.</p>

                <h3>Odvolání platnosti souhlasu</h3>
                <p>Udělený souhlas ke zpracování některých osobních údajů (např. údajů pro marketingové účely) můžete
                    kdykoliv odvolat
                    bez uvedení důvodů. Berte prosím na vědomí, že odvolání vašeho souhlasu nemá vliv na zákonnost
                    jakéhokoliv
                    zpracování prováděného na základě dříve uděleného souhlasu.</p>

                <h3>Vaše další práva</h3>
                <p>Vezměte prosím na vědomí, že podle zákona o ochraně osobních údajů máte, kromě výše jmenovaných, také
                    právo:</p>
                <ul>
                    <li>požadovat po nás informaci, jaké vaše osobní údaje zpracováváme</li>
                    <li>požadovat po nás vysvětlení ohledně zpracování osobních údajů</li>
                    <li>vyžádat si u nás přístup k těmto údajům</li>
                </ul>
            </React.Fragment>
        )
    }
}

export default HelpGDPRCz;
