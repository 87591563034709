import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button'
import WishlistService from "../services/WishlistService";
import ProductService from "../services/ProductService";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";
import LinkService from "../services/LinkService";

class QuickAccessPanel extends Component {
    addToWishlist = () => {
        const {product, user} = this.props;
        if (user === null || user === undefined) {
            this.props.onLogin();

        } else {
            const itself = this;
            WishlistService
                .addToWishlist(product.id)
                .then(function () {
                    product.inWishList = true;
                    itself.props.onAddedToWishlist(product);
                });
        }
    };

    removeFromWishlist = () => {
        const {product, user} = this.props;
        if (user === null || user === undefined) {
            this.props.onLogin();

        } else {
            const itself = this;
            WishlistService
                .deleteFromWishlist(product.id)
                .then(function () {
                    product.inWishList = false;
                    itself.props.onRemovedFromWishlist(product);
                });
        }
    };

    updateVisibility = (isVisible) => {
        const {product, user} = this.props;
        if (user === null || user === undefined) {
            this.props.onLogin();

        } else {
            const itself = this;
            ProductService
                .updateVisibility(product.id, isVisible)
                .then(function (updatedProduct) {
                    itself.props.onProductUpdated(updatedProduct);
                });
        }
    };

    render = () => {
        const {product, user} = this.props;
        return (
            <div className="quick-access-panel">
                {user && product.brand && product.brand.id === user.id &&
                <React.Fragment>
                    <Button variant="secondary" onClick={() => this.props.onRemoveProduct(product)} className="toolbar-button">
                        <i className="fas fa-2x fa-trash"/>
                    </Button>
                    <LinkContainer to={LinkService.getMyStuffExisting(product.id)}>
                        <Button variant="secondary" className="toolbar-button">
                            <i className="fas fa-2x fa-pen"/>
                        </Button>
                    </LinkContainer>
                    {!product.active &&
                    <Button variant="secondary" onClick={() => this.updateVisibility(true)} className="toolbar-button">
                        <i className="fas fa-2x fa-eye"/>
                    </Button>
                    }
                    {product.active &&
                    <Button variant="secondary" onClick={() => this.updateVisibility(false)} className="toolbar-button">
                        <i className="fas fa-2x fa-eye-slash"/>
                    </Button>
                    }
                </React.Fragment>
                }

                {(!product.inWishList || user === null) && product.active &&
                <Button variant="secondary" onClick={this.addToWishlist} className="add-to-wishlist toolbar-button">
                    <i className="fas fa-2x fa-heart text-danger"/>
                </Button>
                }
                {product.inWishList && user !== null &&
                <Button variant="secondary" onClick={this.removeFromWishlist} className="toolbar-button">
                    <i className="fas fa-2x fa-times"/>
                </Button>
                }
            </div>
        )
    }
}

QuickAccessPanel.propTypes = {
    product: PropTypes.object.isRequired,
    user: PropTypes.object,
    onLogin: PropTypes.func.isRequired,
    onAddedToWishlist: PropTypes.func.isRequired,
    onRemovedFromWishlist: PropTypes.func.isRequired,
    onProductUpdated: PropTypes.func.isRequired,
    onRemoveProduct: PropTypes.func.isRequired
};

export default QuickAccessPanel;
