import React, {Component} from 'react';
import ListGroup from 'react-bootstrap/ListGroup'
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import Badge from "react-bootstrap/Badge";
import LinkService from "../services/LinkService";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";
import ContentLoader from "react-content-loader";

class ProfileNavi extends Component {
    render = () => {
        const {user} = this.props;
        return (
            <React.Fragment>
                {user &&
                <ListGroup style={{marginBottom: 100}}>
                    <LinkContainer to={LinkService.getProfile()}>
                        <ListGroup.Item action active={window.location.pathname.startsWith(LinkService.getProfile())}>
                            <FormattedMessage id="topNavi.profile"/>
                        </ListGroup.Item>
                    </LinkContainer>
                    <LinkContainer to={LinkService.getChangePassword()}>
                        <ListGroup.Item action active={window.location.pathname.startsWith(LinkService.getChangePassword())}>
                            <FormattedMessage id="topNavi.changePassword"/>
                        </ListGroup.Item>
                    </LinkContainer>
                    {user.privileges.includes("SHOP") &&
                    <LinkContainer to={LinkService.getMyStuff()}>
                        <ListGroup.Item action active={window.location.pathname.startsWith(LinkService.getMyStuff())}>
                            <FormattedMessage id="topNavi.mystuff"/>
                        </ListGroup.Item>
                    </LinkContainer>
                    }
                    <LinkContainer to={LinkService.getMessages()}>
                        <ListGroup.Item action active={window.location.pathname.startsWith(LinkService.getMessages())}>
                            <FormattedMessage id="topNavi.messages"/>
                            {user.unreadMessagesCount > 0 &&
                            <Badge pill variant="danger" className="float-right">
                                {user.unreadMessagesCount}
                            </Badge>
                            }

                        </ListGroup.Item>
                    </LinkContainer>
                    <LinkContainer to={LinkService.getWishlist()}>
                        <ListGroup.Item action active={window.location.pathname.startsWith(LinkService.getWishlist())}>
                            <FormattedMessage id="topNavi.wishlist"/>
                            {user.wishlistCount > 0 &&
                            <Badge pill variant="secondary" className="float-right">
                                {user.wishlistCount}
                            </Badge>
                            }
                        </ListGroup.Item>
                    </LinkContainer>
                </ListGroup>
                }
                {!user && [1, 2, 3].map(index => {
                        return (
                            <ContentLoader preserveAspectRatio={"none"}
                                           key={index}
                                           style={{width: '100%', height: 50, marginBottom: 1}}/>
                        )
                    }
                )}
            </React.Fragment>
        )
    }
}

ProfileNavi.propTypes = {
    user: PropTypes.object,
};

export default ProfileNavi;
