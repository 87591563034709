import React, {Component} from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import './App.css';
import TopNavigation from "./shell/TopNavigation"
import Main from "./shell/Main"
import LoginDialog from "./shell/LoginDialog"
import UserService from "./services/UserService"
import Cookies from "./services/Cookies"
import LinkService from "./services/LinkService"
import Footer from "./shell/Footer";
import ForgotPasswordModal from "./shell/ForgotPasswordModal";
import LocalStorage from "./services/LocalStorage";
import GoTop from "./shell/GoTop";

class App extends Component {
    isCurrentRouteCorrect = (pathname, user) => {
        const authAllowed = [
            LinkService.getHome(),
            LinkService.getCatalog(),
            LinkService.getMessages(),
            LinkService.getWishlist(),
            LinkService.getProfile(),
            LinkService.getHelpTerms(),
            LinkService.getHelpGDPR(),
            LinkService.getHelpCookies(),
            LinkService.getProductPath(),
            LinkService.getMyStuff(),
            LinkService.getChangePassword(),
            LinkService.getErrors(),
            LinkService.getActivate()
        ];
        const anonAllowed = [
            LinkService.getHome(),
            LinkService.getCatalog(),
            LinkService.getHelpTerms(),
            LinkService.getHelpGDPR(),
            LinkService.getHelpCookies(),
            LinkService.getProductPath(),
            LinkService.getErrors(),
            LinkService.getActivate()
        ];
        const path = pathname.toLowerCase();

        if (pathname === "/") {
            return false;
        }

        return user
            ? this.isPathBelongToList(path, authAllowed)
            : this.isPathBelongToList(path, anonAllowed)
    };

    isPathBelongToList = (path, list) => {
        for (var i = 0; i < list.length; i++) {
            if (list[i].startsWith(path) || path.startsWith(list[i])) {
                return true;
            }
        }
        return false;
    };

    reRouteIfNeeded = (user) => {
        if (!this.isCurrentRouteCorrect(window.location.pathname, user)) {
            this.redirectToDefault();
        }
    };

    redirectToDefault = () => {
        this.props.history.push(LinkService.getHome());
    };

    handleLogout = () => {
        Cookies.remove('X-Auth-Token');
        this.setState({user: null});
        this.reRouteIfNeeded(null);
    };

    handleLogin = (authToken, expires) => {
        this.hideLogin();
        Cookies.put('X-Auth-Token', authToken, {expires: expires, path: "/"});
        this.loadCurrentUser();
    };

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            showLogin: false,
            showForgotPasswordModal: false,
            userUpdateIntervalId: null
        };
    }

    hideLogin = () => {
        this.setState({showLogin: false});
    };

    showLogin = () => {
        this.setState({showLogin: true});
    };

    componentWillMount = () => {
        this.loadCurrentUser();

        let self = this;
        let intervalId = setInterval(() => {
            self.loadCurrentUser()
        }, 600000);
        this.setState({userUpdateIntervalId: intervalId});
    };

    componentWillUnmount() {
        clearInterval(this.state.userUpdateIntervalId);
    }

    onAddedToWishlist = () => {
        const user = this.state.user;
        user.wishlistCount = user.wishlistCount + 1;
        this.setState({user: user});
    };

    onRemovedFromWishlist = () => {
        const user = this.state.user;
        user.wishlistCount = Math.max(user.wishlistCount - 1, 0);
        this.setState({user: user});
    };

    getMain = () => {
        return (
            <Main user={this.state.user}
                  onLogin={this.showLogin}
                  handleLogin={this.handleLogin}
                  onAddedToWishlist={this.onAddedToWishlist}
                  onRemovedFromWishlist={this.onRemovedFromWishlist}
                  onUpdateUserDetails={this.loadCurrentUser}
            />
        );
    };

    loadCurrentUser = () => {
        UserService
            .getCurrent()
            .then(user => {
                this.setState({user: user});
                const activeLang = LocalStorage.getOrDefault("language", "en");
                if (activeLang !== user.lang) {
                    LocalStorage.set("language", user.lang);
                    window.location.reload();
                }

                this.reRouteIfNeeded(user);
            })
            .catch(() => {
                this.handleLogout();
            });
    };

    render = () => {
        return (
            <React.Fragment>
                <TopNavigation user={this.state.user} onLogin={this.showLogin} onLogout={this.handleLogout}/>
                <div className="content">
                    <Switch>
                        <Route path='/' render={this.getMain}/>
                    </Switch>
                </div>
                <Footer user={this.state.user}/>
                <LoginDialog show={this.state.showLogin}
                             onLogin={this.handleLogin}
                             onHide={this.hideLogin}
                             onForgotPassword={() => this.setState({showForgotPasswordModal: true}, this.hideLogin)}
                />
                <ForgotPasswordModal show={this.state.showForgotPasswordModal} onHide={() => this.setState({showForgotPasswordModal: false})}/>
                <GoTop/>
            </React.Fragment>
        );
    };
}

export default withRouter(App);
