import React, {Component} from 'react';

class HelpGDPRRu extends Component {
    render() {
        return (
            <React.Fragment>
                <h1>Политика персональных данных для пользователей Valika</h1>

                <h2>Общие положения</h2>
                <p>Мы уделяем большое внимание защите персональных данных. С самого начала мы старались как можно лучше
                    защищать данные
                    наших пользователей. Чувствительные данные (такие как пароль) шифруются, мы никогда не отправляем
                    контактную
                    информацию до тех пор, пока не будет создан сам заказ, или этого не требует закон.</p>
                <p>Цель даного документа - сообщить вам о размерах и способе хранения ваших личных данных, ваших прав и
                    того, как вы
                    можете использовать свои права.</p>

                <h2>Обработка личной информации</h2>
                <p>На Valiks мы храним личную информацию наших пользователей. Объем данных различается в зависимости от
                    роли
                    пользователя. К примеру это зависит от того, являетесь ли вы покупателем или продавцом,
                    зарегистрированы ли вы на
                    сайте или нет.</p>

                <h3>Зарегистрированный пользователь Valika.org</h3>
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th>объект</th>
                        <th>цель/описание</th>
                        <th>необязательно</th>
                        <th>публичное</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Email адрес</td>
                        <td>Базовый уникальный идентификатор учетной записи</td>
                        <td>НЕТ</td>
                        <td>НЕТ</td>
                    </tr>
                    <tr>
                        <td>Пароль</td>
                        <td>Сохранено в зашифрованном виде</td>
                        <td>НЕТ</td>
                        <td>НЕТ</td>
                    </tr>
                    <tr>
                        <td>IP-адрес регистрации</td>
                        <td>Адрес, с которого была создана учетная запись</td>
                        <td>НЕТ</td>
                        <td>НЕТ</td>
                    </tr>
                    <tr>
                        <td>Информация о банковском счете</td>
                        <td>Используется для создания счета-фактуры и будет отображено клиенту только после его запроса
                            на покупку.
                        </td>
                        <td>ДА</td>
                        <td>НЕТ</td>
                    </tr>
                    <tr>
                        <td>Место проживания (страна и город)</td>
                        <td>Informace bude zobrazena na webových stránkách Valika.org</td>
                        <td>ДА</td>
                        <td>ДА</td>
                    </tr>
                    <tr>
                        <td>заказы, суммы, платежи и т.д.</td>
                        <td></td>
                        <td>НЕТ</td>
                        <td>НЕТ</td>
                    </tr>
                    </tbody>
                </table>


                <h3>Покупатель</h3>
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th>объект</th>
                        <th>необязательно</th>
                        <th>публичное</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Email адрес</td>
                        <td>НЕТ</td>
                        <td>НЕТ</td>
                    </tr>
                    <tr>
                        <td>Имя и фамилия</td>
                        <td>НЕТ</td>
                        <td>НЕТ</td>
                    </tr>
                    <tr>
                        <td>Адрес доставки</td>
                        <td>НЕТ</td>
                        <td>НЕТ</td>
                    </tr>
                    <tr>
                        <td>заказы, суммы, платежи и т.д.</td>
                        <td>НЕТ</td>
                        <td>НЕТ</td>
                    </tr>
                    </tbody>
                </table>

                <h2>Цель и способ обработки персональных данных</h2>
                <p>Мы обрабатываем ваши личные данные несколькими способами для достижения следующих целей:</p>

                <h3>Покупка и продажа товаров и услуг в Valika.org</h3>
                <p>Чтобы создать ваш заказ, нам необходимо знать и обрабатывать вашу личную информацию. Мы обрабатываем
                    эти данные
                    исключительно с целью оповестить вас о заключении договора купли-продажи и проведению вашего
                    заказа.</p>
                <p>Мы собираем ваши личные данные исключительно на основании вашей регистрации на Valika.org или путем
                    заполнения ваших
                    данных в корзине покупок.</p>
                <p>Мы обрабатываем эти данных на протяжении всего заказа, а также период не менее 10 лет, как того
                    требует налоговый
                    закон.</p>

                <h3>Маркетинговое общение</h3>
                <p>Если вы дадите нам свое согласие, мы будем использовать вашу личную информацию (адрес электронной
                    почты), чтобы
                    отправлять новости о продуктах, товарах, рекламных акциях и новостях на Valika. Ваш адрес
                    электронной почты ни в
                    коем случае не будет перенаправлен третьим лицам, и вы можете в любое время отозвать свое согласие
                    на маркетинговые
                    сообщения, щелкнув ссылку, указанную в каждом электронном письме или учетной записи.</p>

                <h3>Поддержка по электронной почте</h3>
                <p>В случае, если вы свяжетесь с нами через наш контактный email или контактную форму, мы можем
                    попросить вас
                    предоставить однозначную идентификационную информацию, чтобы подтвердить вашу правомочность вашего
                    запроса. Мы
                    архивируем электронную почту с вами, правовой основой такой обработки является наш законный
                    интерес.</p>

                <h3>Cookies</h3>
                <p>Когда вы используете наш сайт, ваши личные данные могут обрабатываться через файлы cookie. Узнать
                    больше о том, как
                    мы используем файлы cookie вы можете <a href="/help/cookies">здесь</a>.</p>

                <h2>Ваши права как субъекта данных или что делать, когда я хочу удалить или изменить свою личную
                    информацию</h2>
                <p>Если вы хотите использовать следующие права и / или получить соответствующую информацию, вы можете
                    сделать это, войдя
                    в свою учетную запись на Valika.org. Если у вас нет учетной записи и вы совершили покупку без
                    регистрации, но
                    по-прежнему хотели бы воспользоваться одним из следующих прав для защиты вашей конфиденциальности,
                    вы можете
                    связаться с нами по электронной почте info@valika.org.</p>
                <p>Чтобы войти в свою учетную запись, вам нужен ваш логин (имя пользователя / адрес электронной почты и
                    пароль). Если вы
                    используете свои права по-другому, мы можем попросить вас предоставить однозначную идентификационную
                    информацию,
                    которая будет служить для проверки законности вашего запроса. Мы ответим в течение 1 месяца после
                    получения вашего
                    запроса, но мы оставляем за собой право продлить этот период в соответствии с техническими и
                    временными требованиями
                    вашего запроса.</p>
                <p>В соответствии с действующим законодательством вы имеете право запрашивать доступ к нашим личным
                    данным, которые мы
                    обрабатываем как администратор персональных данных, право на его восстановление или удаление. Вы
                    также можете в
                    любое время отозвать согласие на обработку определенных персональных данных (таких как маркетинговые
                    данные),
                    которые вы нам предоставили.</p>
                <p>Итак, каковы ваши права?</p>

                <h3>Удаление вашей личной информации</h3>
                <p>Всякий раз, когда вы можете попросить нас удалить вашу личную информацию. Если вы обратитесь к нам с
                    таким запросом,
                    мы незамедлительно удалим все ваши личные данные, если только нам не нужны ваши личные данные для
                    выполнения
                    договорных и юридических обязательств или для защиты наших законных интересов, как описано выше.</p>

                <h4>Отмена учетной записи покупателя</h4>
                <ol>
                    <li>Если покупатель разместил заказ, мы должны хранить его данные заказа не менее 10 лет после
                        отмены учетной
                        записи.
                    </li>
                    <li>Если покупатель не сделал какого-либо заказа, его или ее данные могут быть удалены по его / ее
                        запросу, за
                        исключением данных, относящихся к целям обеспечения безопасности системы и сети (IP-адрес).
                    </li>
                </ol>

                <h4>Отмена счета продавца</h4>
                <ol>
                    <li>Если продавец выполнил опосредованный заказ, мы должны сохранить его данные заказа не менее 10
                        лет после отмены
                        учетной записи.
                    </li>
                    <li>Если продавец не принял заказ, его или ее данные могут быть удалены по его / ее запросу, за
                        исключением данных,
                        относящихся к целям обеспечения безопасности системы и сети (IP-адрес).
                    </li>
                </ol>

                <h3>Исправление вашей личной информации</h3>
                <p>Вы имеете право исправить вашу личную информацию. Если у вас есть учетная запись на valika.org, вы
                    можете
                    редактировать эту информацию непосредственно после входа в свою учетную запись. Некоторые данные не
                    могут быть
                    скорректированы напрямую (например, информация о счете), а затем, пожалуйста, свяжитесь с нами по
                    электронной почте
                    или в контактной форме.</p>

                <h3>Отзыв согласия</h3>
                <p>Вы можете в любое время отозвать свое согласие на обработку определенных персональных данных
                    (например, данных для
                    маркетинговых целей) без объяснения причин. Обратите внимание, что аннулирование вашего согласия не
                    влияет на
                    законность любой обработки, выполненной на основании ранее предоставленного согласия.</p>

                <h3>Ваши другие права</h3>
                <p>Обратите внимание, что в соответствии с Законом о защите личных данных у вас также есть право в
                    дополнение к
                    вышесказанному:</p>
                <ul>
                    <li>отправить нам запрос о том, как мы обрабатываем вашу личную информацию</li>
                    <li>запросить у нас разъяснения относительно обработки персональных данных</li>
                    <li>запросить доступ к этим данным</li>
                </ul>
            </React.Fragment>
        )
    }
}

export default HelpGDPRRu;
