import React, {Component} from 'react';
import Container from 'react-bootstrap/Container'
import {Route, Switch} from "react-router-dom";
import LinkService from "../services/LinkService"
import HelpCookies from "../help/HelpCookies";
import HelpGDPR from "../help/HelpGDPR";
import HelpTerms from "../help/HelpTerms";
import Catalog from "../catalog/Catalog";
import PropTypes from "prop-types";
import Wishlist from "../profile/Wishlist";
import Product from "../product/Product";
import MessageList from "../messages/MessageList";
import ProfilePage from "../profile/ProfilePage";
import MessageThread from "../messages/MessageThread";
import Mystuff from "../profile/Mystuff";
import Profile from "../profile/Profile";
import ChangePassword from "../profile/ChangePassword";
import EditProduct from "../profile/EditProduct";
import Error406 from "../errors/Error406";
import Home from "../home/Home";
import MessageAlert from "./MessageAlert";
import {injectIntl} from "react-intl";
import Activate from "./Activate";

class Main extends Component {
    getCatalog = () => {
        return (
            <Catalog user={this.props.user}
                     onLogin={this.props.onLogin}
                     onAddedToWishlist={this.props.onAddedToWishlist}
                     onRemovedFromWishlist={this.props.onRemovedFromWishlist}
            />
        );
    };

    getHome = () => {
        return (
            <Home user={this.props.user}
                  onLogin={this.props.onLogin}
                  onAddedToWishlist={this.props.onAddedToWishlist}
                  onRemovedFromWishlist={this.props.onRemovedFromWishlist}
            />
        );
    };

    getProfile = () => {
        return (<Profile user={this.props.user} onUpdateUserDetails={this.props.onUpdateUserDetails}/>)
    };

    getWishlist = () => {
        return (
            <Wishlist user={this.props.user}
                      onLogin={this.props.onLogin}
                      onAddedToWishlist={this.props.onAddedToWishlist}
                      onRemovedFromWishlist={this.props.onRemovedFromWishlist}
            />
        );
    };

    getMystuff = () => {
        return (
            <Mystuff user={this.props.user}
                     onLogin={this.props.onLogin}
                     onAddedToWishlist={this.props.onAddedToWishlist}
                     onRemovedFromWishlist={this.props.onRemovedFromWishlist}
            />
        );
    };

    getEditProduct = () => {
        return (
            <EditProduct user={this.props.user}
                         onLogin={this.props.onLogin}
            />
        );
    };

    getChangePassword = () => {
        return (
            <ChangePassword user={this.props.user}/>
        );
    };

    getMessages = () => {
        return (
            <ProfilePage user={this.props.user}>
                <MessageList/>
            </ProfilePage>

        );
    };

    getMessageThread = (props) => {
        return (
            <ProfilePage user={this.props.user}>
                <MessageThread userId={props.match.params.userId} onUpdateUserDetails={this.props.onUpdateUserDetails}/>
            </ProfilePage>

        );
    };

    getProduct = () => {
        return (
            <Product location={window.location}
                     user={this.props.user}
                     onLogin={this.props.onLogin}
                     onAddedToWishlist={this.props.onAddedToWishlist}
                     onRemovedFromWishlist={this.props.onRemovedFromWishlist}
            />
        );
    };

    getErrors406 = () => {
        return (<Error406/>);
    };

    getActivate = () => {
        return (<Activate handleLogin={this.props.handleLogin}/>);
    };

    render = () => {
        const {formatMessage} = this.props.intl;

        return (
            <Container style={{marginTop: 70, marginBottom: 15}} className="main">
                <MessageAlert message={formatMessage({id: "common.cookiesInfo"})}
                              link={LinkService.getHelpCookies()}
                              messageId="COOKIES_INFO"
                />
                <MessageAlert message={formatMessage({id: "common.gdprInfo"})}
                              link={LinkService.getHelpGDPR()}
                              messageId="GDPR_INFO"
                />
                <Switch>
                    {/* HOME */}
                    {/*<Route exact path={LinkService.getHome()} render={this.getHome}/>*/}

                    {/* HELP */}
                    <Route exact path={LinkService.getHelpCookies()} component={HelpCookies}/>
                    <Route exact path={LinkService.getHelpGDPR()} component={HelpGDPR}/>
                    <Route exact path={LinkService.getHelpTerms()} component={HelpTerms}/>

                    {/* CATALOG */}
                    <Route path={LinkService.getCatalog()} render={this.getCatalog}/>

                    {/* PRODUCT */}
                    <Route path={LinkService.getProductPath()} render={this.getProduct}/>

                    {/* PROFILE */}
                    <Route path={LinkService.getProfile()} render={this.getProfile}/>
                    <Route path={LinkService.getWishlist()} render={this.getWishlist}/>
                    <Route exact path={LinkService.getMyStuff()} render={this.getMystuff}/>
                    <Route path={LinkService.getMyStuff()} render={this.getEditProduct}/>
                    <Route path={LinkService.getChangePassword()} render={this.getChangePassword}/>
                    <Route exact path={LinkService.getMessages()} render={this.getMessages}/>
                    <Route exact path={LinkService.getMessageThread(":userId")} render={(props) => this.getMessageThread(props)}/>

                    {/* ACTIVATION */}
                    <Route exact path={LinkService.getActivate()} render={this.getActivate}/>

                    {/* ERRORS */}
                    <Route exact path={LinkService.getErrors406()} render={this.getErrors406}/>

                </Switch>
            </Container>
        )
    }
}

Main.propTypes = {
    user: PropTypes.object,
    onLogin: PropTypes.func.isRequired,
    handleLogin: PropTypes.func.isRequired,
    onAddedToWishlist: PropTypes.func.isRequired,
    onRemovedFromWishlist: PropTypes.func.isRequired,
    onUpdateUserDetails: PropTypes.func.isRequired
};

export default injectIntl(Main);
