import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import UserService from "../services/UserService"
import Alert from 'react-bootstrap/Alert'
import ValidationUtils from "../services/ValidationUtils";

class ForgotPasswordModal extends Component {
    state = {
        email: "",
        showProgress: false,
        alertType: null
    };

    isFormValid = (email) => {
        return email && email.trim().length > 0 && ValidationUtils.validateEmail(email);
    };

    handleResetPassword = () => {
        this.setState({
            showProgress: true,
            alertType: null
        });
        UserService
            .resetPassword(this.state.email)
            .then(() => {
                this.setState({
                    showProgress: false,
                    alertType: "success",
                    email: ""
                });
            })
            .catch(() => {
                this.setState({
                    showProgress: false,
                    alertType: "danger"
                });
            });
    };

    onHide = () => {
        this.setState({
            showProgress: false,
            alertType: null,
            email: ""
        }, this.props.onHide);
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        return (
            <Modal show={this.props.show} onHide={this.onHide} centered>
                <Modal.Header closeButton>
                    <h3><FormattedMessage id="login.forgotPassword"/></h3>
                </Modal.Header>
                <Modal.Body>
                    <Alert dismissible variant={this.state.alertType}
                           show={this.state.alertType !== null}
                           onClose={() => this.setState({alertType: null})}
                    >
                        {this.state.alertType === "success" ? formatMessage({id: "login.forgotPassword.sent"}) : formatMessage({id: "login.forgotPassword.error"})}
                    </Alert>
                    <p><FormattedMessage id="login.forgotPassword.desc"/></p>
                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label><FormattedMessage id="profile.email"/></Form.Label>
                            <Form.Control type="email"
                                          placeholder={formatMessage({id: "profile.email"})}
                                          value={this.state.email}
                                          onChange={(event) => this.setState({email: event.target.value})}
                            />
                        </Form.Group>
                        <Button variant="primary" type="button" size="lg" block
                                disabled={!this.isFormValid(this.state.email) || this.state.showProgress}
                                onClick={this.state.showProgress ? null : this.handleResetPassword}

                        >
                            {this.state.showProgress ? formatMessage({id: "common.waitPlease"}) : formatMessage({id: "messages.send"})}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        )
    }
}

ForgotPasswordModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired
};

export default injectIntl(ForgotPasswordModal);
