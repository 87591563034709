import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ValidationUtils from "../services/ValidationUtils";
import UserService from "../services/UserService";
import LocalStorage from "../services/LocalStorage";
import Alert from "react-bootstrap/Alert";

class FastRegistrationDialog extends Component {
    state = {
        email: "",
        username: "",
        showProgress: false,
        showError: false
    };

    handleTextFieldChange = (event, field) => {
        this.setState({[field]: event.target.value});
    };

    isEmailValid = (email) => {
        return email && email.trim().length > 0 && ValidationUtils.validateEmail(email)
    };

    onConfirm = () => {
        this.setState({showProgress: true, showError: false});
        UserService
            .fastRegister(this.state.email, this.state.username, LocalStorage.getOrDefault("language", "en"))
            .then(data => {
                this.setState({
                    showProgress: false
                });
                this.props.onRegister(data.id);
            })
            .catch((error) => {
                this.setState({
                    showProgress: false,
                    showError: true
                });
            });
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        return (
            <Modal show={this.props.show} onHide={this.props.onClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert dismissible variant="danger" show={this.state.showError} onClose={() => this.setState({showError: false})}>
                        <FormattedMessage id="common.errorMessage"/>
                    </Alert>

                    <Form.Group controlId="formEmail">
                        <Form.Label className={!this.isEmailValid(this.state.email) ? "text-danger" : ""}>
                            <FormattedMessage id="login.email"/>
                        </Form.Label>
                        <Form.Control type="email"
                                      autoFocus
                                      value={this.state.email}
                                      placeholder={formatMessage({id: "login.email"})}
                                      onChange={e => this.handleTextFieldChange(e, "email")}
                        />
                    </Form.Group>

                    <Form.Group controlId="formUsername">
                        <Form.Label><FormattedMessage id="profile.authorName"/></Form.Label>
                        <Form.Control type="text"
                                      value={this.state.username}
                                      placeholder={formatMessage({id: "profile.authorName"})}
                                      onChange={e => this.handleTextFieldChange(e, "username")}
                        />
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary"
                            onClick={this.onConfirm}
                            disabled={!this.isEmailValid(this.state.email) || this.state.showProgress}
                    >
                        <FormattedMessage id="common.next"/>
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

FastRegistrationDialog.propTypes = {
    title: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onRegister: PropTypes.func.isRequired
};

export default injectIntl(FastRegistrationDialog);
