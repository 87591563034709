import React, {Component} from 'react';
import Col from "react-bootstrap/Col";
import ContentLoader from "react-content-loader";

class ProductCardPlaceholder extends Component {
    render = () => {
        return (
            <Col md={4} style={{marginBottom: 15}}>
                <ContentLoader preserveAspectRatio={"none"}
                               style={{width: '100%', height: 344}}/>
            </Col>
        )
    }
}

export default ProductCardPlaceholder;
