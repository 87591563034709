import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import {FormattedMessage, injectIntl} from "react-intl";
import Jumbotron from 'react-bootstrap/Jumbotron'
import ProductsService from "../services/ProductService";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SmallProductCard from "./SmallProductCard";
import Button from "react-bootstrap/Button";
import LinkService from "../services/LinkService";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";
import ProductRating from "../catalog/ProductRating"
import ProgressBar from 'react-bootstrap/ProgressBar'
import RateItem from "./RateItem";

class ProductDetails extends Component {
    state = {
        otherProducts: null,
        showMoreButton: false,
        showReviews: null,

        key: "description",

        showRateItem: false,
        lastProductId: null
    };

    renderDescription = () => {
        const {product} = this.props;
        return (
            <Jumbotron className="bg-transparent" style={{borderRadius: 0}}>
                <p className="lead">{product.description}</p>
                <ul className="lead">
                    {
                        product.properties.map(property => {
                            return (
                                <li key={property}>{property}</li>
                            );
                        })
                    }
                </ul>
            </Jumbotron>
        )
    };

    renderMoreFrom = (otherProducts) => {
        return (
            <Jumbotron className="bg-transparent" style={{borderRadius: 0}}>
                <Row>
                    {otherProducts !== null && otherProducts.length === 0 &&
                    <Col md={12}>
                        <p className="lead text-center" ng-show="products.length == 0">
                            <FormattedMessage id="product.resultsNotFound"/>
                        </p>
                    </Col>
                    }
                    {otherProducts !== null && otherProducts.length > 0 &&
                    otherProducts.map(product => {
                        return (
                            <Col md={4} key={product.id}>
                                <SmallProductCard product={product}/>
                            </Col>
                        )
                    })
                    }
                    {this.state.showMoreButton &&
                    <Col md={12}>
                        <LinkContainer to={LinkService.getShop(this.props.product.brand.name.toLowerCase())}>
                            <Button variant="light" size="lg" block>
                                <FormattedMessage id="product.moreFrom"/> {this.props.product.brand.name}
                            </Button>
                        </LinkContainer>
                    </Col>
                    }
                </Row>
            </Jumbotron>
        )
    };

    renderReviews = () => {
        const {product, user} = this.props;

        let ratedAlready = (user && product && product.reviews.filter(review => review.authorId === user.id).length > 0) || false;
        return (
            <Jumbotron id="product-reviews" className="bg-transparent text-center" style={{borderRadius: 0}}>
                <h1 className="display-1">{product.rating.toFixed(2)}</h1>
                <h2><ProductRating value={product.rating}/></h2>
                <p><i className="fa fa-users"/> {product.reviewsCount} <FormattedMessage id="product.totalNum"/></p>

                <div style={{margin: "15px auto 45px auto", maxWidth: 350, textAlign: "left"}}>
                    {
                        product.starRating.map(progress => {
                            return (
                                <React.Fragment key={progress.title}>
                                    {progress.title}
                                    <ProgressBar now={progress.rating.percent}
                                                 variant="info"
                                                 label={progress.rating.value > 0 ? progress.rating.value : null}/>
                                </React.Fragment>
                            );
                        })
                    }
                    {!ratedAlready &&
                    <React.Fragment>
                        <Button variant="outline-secondary"
                                type="button" block
                                style={{marginTop: 45}}
                                onClick={this.rateItem}
                        >
                            <FormattedMessage id="product.rateItem"/>
                        </Button>
                        <RateItem show={this.state.showRateItem}
                                  onClose={() => this.setState({showRateItem: false})}
                                  onItemRated={this.props.onProductUpdated}
                                  productId={product.id}
                        />
                    </React.Fragment>
                    }

                </div>
            </Jumbotron>
        );
    };

    showReview = () => {
        const itself = this;
        this.setState({key: "reviews"}, () => {
            itself.scrollToElement("product-reviews");
        });
    };

    scrollToElement = (elementId) => {
        window.scroll(0, this.findPos(document.getElementById(elementId)));
    };

    findPos = (obj) => {
        var curtop = 0;
        if (obj.offsetParent) {
            do {
                curtop += obj.offsetTop;
                obj = obj.offsetParent
            } while (obj);
            return [curtop];
        }
    };

    rateItem = () => {
        const {user} = this.props;
        if (user === null || user === undefined) {
            this.props.onLogin();
        } else {
            this.setState({showRateItem: true});
        }
    };

    loadOtherProducts = (productId, brandId) => {
        ProductsService
            .list(0, 11, "default", brandId)
            .then(data => {
                let products = data.items.filter(p => p.id !== productId);
                this.setState({
                    showMoreButton: products.length > 9,
                    otherProducts: products.slice(0, 9),
                    lastProductId: productId
                });
            });
    };

    componentWillMount() {
        this.loadOtherProducts(this.props.product.id, this.props.product.brand.name.toLowerCase());
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.product !== null && nextProps.product !== undefined && nextProps.product.id !== this.state.lastProductId) {
            this.loadOtherProducts(nextProps.product.id, nextProps.product.brand.name.toLowerCase());
        }

        if (nextProps.showReviews !== null && nextProps.showReviews !== this.state.showReviews) {
            this.setState({showReviews: nextProps.showReviews}, this.showReview);
        }
    };

    resizeMoreFrom = (key) => {
        if (key === "moreFrom") {
            let products = this.state.otherProducts;
            this.setState({otherProducts: null}, () => {
                this.setState({otherProducts: products});
            });
        }
        this.setState({key: key});
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        const {product} = this.props;
        return (
            <React.Fragment>
                {product &&
                <Tabs onSelect={key => this.resizeMoreFrom(key)} activeKey={this.state.key}>
                    <Tab eventKey="description" className="bg-light" title={formatMessage({id: "product.description"})}>
                        {this.renderDescription()}
                    </Tab>
                    <Tab eventKey="moreFrom" className="bg-light" title={formatMessage({id: "product.moreFrom"}) + " " + product.brand.name}>
                        {this.renderMoreFrom(this.state.otherProducts)}
                    </Tab>
                    <Tab eventKey="reviews" className="bg-light" title={formatMessage({id: "product.reviews"})}>
                        {this.renderReviews()}
                    </Tab>
                </Tabs>
                }
            </React.Fragment>
        )
    }
}

ProductDetails.propTypes = {
    user: PropTypes.object,
    product: PropTypes.object.isRequired,
    showReviews: PropTypes.number,
    onProductUpdated: PropTypes.func,
    onLogin: PropTypes.func
};

export default injectIntl(ProductDetails);
