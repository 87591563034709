import React, {Component} from 'react';

class HelpCookiesEn extends Component {
    render() {
        return (
            <React.Fragment>
                <h1>Общая информация</h1>
                <p>Cookies - это небольшие текстовые файлы, которые веб-сайт хранит на жестком диске или мобильном
                    устройстве
                    пользователя. Часто это параметр, который облегчает пользователю использование сайта. Некоторые
                    функции недоступны
                    без файлов cookie.</p>
                <p><strong>Valika.org не использует файлы cookie для идентификации пользователей.</strong></p>
                <p>Мы сохраняем следующие типы файлов cookie:</p>
                <ul>
                    <li>Технические файлы cookie, необходимые для обеспечения базовой функциональности сайта
                        (идентификатор session).
                    </li>
                    <li>Сторонние файлы cookie, предназначенные для измерения поведения анонимного пользователя и
                        отслеживания трафика с
                        помощью Google Analytics для лучшей настройки страниц для пользователей.
                    </li>
                </ul>

                <h1>Настройки файлов cookie</h1>
                <ul>
                    <li>Правила использования или блокировки файлов cookie могут устанавливаться каждым пользователем в
                        их
                        веб-браузере.
                    </li>
                    <li>Пользователь может установить разрешение или отклонить все или только некоторые файлы cookie.
                        Блокировка файлов
                        cookie будет отрицательно влиять на удобство использования веб-сайта valika.org.
                    </li>
                    <li>Пользователь может в любое время изменить свой выбор файлов cookie. Пользователь может удалить
                        уже сохраненные
                        файлы cookie в своем интернет-браузере.
                    </li>
                    <li>Подробную информацию о том, как файлы cookie можно хранить в браузере, можно найти на веб-сайте
                        браузера
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}

export default HelpCookiesEn;
