import React, {Component} from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {FormattedDate, FormattedMessage} from "react-intl"
import Media from 'react-bootstrap/Media'
import ProductRating from "../catalog/ProductRating";
import ReviewReply from "./ReviewReply";
import LinkService from "../services/LinkService";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";

class ReviewItem extends Component {
    renderReviewAuthorName = (review) => {
        return (
            <React.Fragment>
                {review.shop &&
                <strong>&#32;
                    <LinkContainer to={LinkService.getShop(review.shop)}>
                        <a href>{review.authorName}</a>
                    </LinkContainer>
                </strong>
                }
                {!review.shop && <strong>&#32;{review.authorName}</strong>}
            </React.Fragment>
        );
    };

    render = () => {
        const {review, product, user} = this.props;
        return (
            <Media key={review.id}
                   className={classNames(
                       {"review-reply": review.replyTo},
                       "review",
                       {"bg-light": review.authorId === this.props.product.brand.id})}
            >
                <Media.Body>
                    <p className="media-title">
                        {review.rating > 0 && <ProductRating value={review.rating}/>}
                        {!review.anonymous && this.renderReviewAuthorName(review)}
                        {review.anonymous && <strong>&#32;<FormattedMessage id="product.anonymously"/></strong>}

                        {review.city && review.city.length > 0 && <small className="text-muted">&#32;{review.city}</small>}
                        {review.country !== 'OT' &&
                        <small className="text-muted">&#32;<FormattedMessage id={'country.' + review.country.toLowerCase()}/></small>}
                        <small className="float-right text-muted">
                            <FormattedDate value={review.created} year="numeric" month="numeric" day="numeric"/>
                        </small>
                    </p>
                    {review.body !== null && review.body.length > 0 && <p className="review-body">{review.body}</p>}
                    {!review.replyTo && user && product.brand.id === user.id &&
                    <ReviewReply product={product} reviewId={review.id}/>
                    }
                </Media.Body>
            </Media>
        )
    }
}

ReviewItem.propTypes = {
    review: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    user: PropTypes.object
};

export default ReviewItem;
