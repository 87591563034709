import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Image from 'react-bootstrap/Image'
import UserLogo from "./user.jpg"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {FormattedMessage, injectIntl} from "react-intl";
import UserService from "../services/UserService"
import Alert from 'react-bootstrap/Alert'
import ValidationUtils from "../services/ValidationUtils";

class Login extends Component {
    state = {
        email: "",
        password: "",

        showError: false,
        showProgress: false
    };

    isFormValid = (email, password) => {
        return email && email.trim().length > 0 && ValidationUtils.validateEmail(email) && password && password.trim().length > 0;
    };

    handleTextFieldChange = (event, field) => {
        this.setState({[field]: event.target.value});
    };

    handleLogin = () => {
        this.setState({
            showProgress: true,
            showError: false
        });
        UserService
            .login(this.state.email, this.state.password)
            .then(data => {
                this.setState({
                    showProgress: false,
                    showError: false
                });
                this.props.onLogin(data.accessToken, data.expires);
            })
            .catch(() => {
                this.setState({
                    showProgress: false,
                    showError: true
                });
            });
    };

    handleCloseAlert = () => {
        this.setState({showError: false});
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        return (
            <Form>
                <Alert dismissible variant="danger" show={this.state.showError} onClose={this.handleCloseAlert}>
                    <FormattedMessage id="login.usernameOrPasswordIncorrect"/>
                </Alert>
                <p style={{textAlign: "center"}}>
                    <Image src={UserLogo} thumbnail style={{margin: "0 auto 0"}}/>
                </p>
                <Form.Group controlId="formLoginEmail">
                    <Form.Label><FormattedMessage id="login.email"/></Form.Label>
                    <Form.Control type="email"
                                  placeholder={formatMessage({id: "login.email"})}
                                  onChange={e => this.handleTextFieldChange(e, "email")}
                    />
                </Form.Group>

                <Form.Group controlId="formLoginPassword">
                    <Form.Label><FormattedMessage id="login.password"/></Form.Label>
                    <Form.Control type="password"
                                  placeholder={formatMessage({id: "login.password"})}
                                  onChange={e => this.handleTextFieldChange(e, "password")}
                    />
                </Form.Group>
                <Form.Group>
                    <Button variant="primary" type="button" size="lg" block
                            disabled={!this.isFormValid(this.state.email, this.state.password) || this.state.showProgress}
                            onClick={this.state.showProgress ? null : this.handleLogin}
                    >
                        {this.state.showProgress ? formatMessage({id: "common.waitPlease"}) : formatMessage({id: "topNavi.login"})}
                    </Button>
                </Form.Group>
                <p>
                    <span><FormattedMessage id="login.forgotPassword"/></span>&nbsp;
                    <Button variant="link" onClick={this.props.onForgotPassword}>
                        <FormattedMessage id="login.clickHere"/>
                    </Button>
                </p>
            </Form>
        )
    }
}

Login.propTypes = {
    onLogin: PropTypes.func.isRequired,
    onForgotPassword: PropTypes.func.isRequired
};

export default injectIntl(Login);
