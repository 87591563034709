import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import ImageZoom from 'react-medium-image-zoom'

class ImageThumbnails extends Component {
    state = {
        selected: 0
    };

    selectThumbnail = (event, image) => {
        let imageIndex = 0;
        for (let i = 0; i < this.props.product.images.length; i++) {
            if (this.props.product.images[i].thumb === image.thumb) {
                imageIndex = i;
            }
        }
        this.setState({selected: imageIndex});
    };

    render = () => {
        const {product} = this.props;
        const {selected} = this.state;

        return (
            <React.Fragment>
                {product && product.images &&
                <Row style={{marginBottom: 20}}>
                    <Col md={2}>
                        <Row id="image-thumbnails">
                            {
                                product.images.map(image => {
                                    return (
                                        <Col md={12} style={{marginBottom: 5, textAlign: "right"}} key={image.thumb}>
                                            <Image src={image.thumb} rounded
                                                   style={{marginBottom: 0, cursor: "pointer"}}
                                                   onClick={e => this.selectThumbnail(e, image)}
                                                   err-src="{{::configService.noCatalogImage}}"
                                            />
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                    </Col>
                    <Col md={10}>
                        <p className="text-center">
                            <ImageZoom
                                image={{
                                    src: product.images[selected].middle,
                                    alt: product.title,
                                    className: 'img',
                                    style: {maxWidth: "100%"}
                                }}
                                zoomImage={{
                                    src: product.images[selected].large,
                                    alt: product.title,
                                }}
                            />
                        </p>
                    </Col>
                </Row>
                }
            </React.Fragment>
        )
    }
}

ImageThumbnails.propTypes = {
    product: PropTypes.object
};

export default ImageThumbnails;
