import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card'
import RouteParams from "../services/RouteParams";
import FiltersService from "../services/FiltersService";
import LocalStorage from "../services/LocalStorage";
import CatalogService from "../services/CatalogService";
import {withRouter} from "react-router-dom";
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";
import ContentLoader from "react-content-loader";

class ProductCategories extends Component {
    state = {
        activeCategory: null,
        categories: null,
        lastLocation: null
    };

    loadDescription = () => {
        let category = RouteParams.catalogCategory();
        let lang = LocalStorage.get("language");
        let itself = this;

        FiltersService
            .loadCategoryDescription(lang, category)
            .then(function (result) {
                itself.setState({activeCategory: result});
            });
    };

    loadCategories = () => {
        let params = RouteParams.search();
        let category = RouteParams.catalogCategory();
        let lang = LocalStorage.get("language");
        let itself = this;

        FiltersService
            .listCategories(lang, params.country, params.shop, category)
            .then(function (result) {
                itself.setState({categories: result.items});
            });
    };

    componentWillMount = () => {
        this.updateLastLocationAndLoadCategories(this.props);
        // this.loadDescription();
        // this.loadCategories();
    };

    componentWillReceiveProps = (nextProps) => {
        this.updateLastLocationAndLoadCategories(nextProps);

        // const location = nextProps.location.pathname + nextProps.location.search;
        // if (this.state.lastLocation !== location) {
        //     if (this.state.lastLocation !== null) {
        //         this.loadDescription();
        //         this.loadCategories();
        //     }
        //     this.setState({lastLocation: location});
        // }
    };

    updateLastLocationAndLoadCategories = (props) => {
        const location = props.location.pathname + props.location.search;
        if (this.state.lastLocation !== location) {
            this.setState({lastLocation: location}, () => {
                this.loadDescription();
                this.loadCategories();
            });
        }
    };

    render() {
        const {activeCategory, categories} = this.state;
        return (
            <React.Fragment>
                {(activeCategory === null || categories === null) &&
                <ContentLoader preserveAspectRatio={"none"}
                               style={{width: '100%', height: 380, marginBottom: 15}}/>
                }
                {activeCategory !== null && categories !== null &&
                <Card style={{marginBottom: 15}}>
                    <Card.Body>
                        <h3>{activeCategory.title}</h3>
                        {!activeCategory.root &&
                        <LinkContainer to={CatalogService.toRootCategory()}>
                            <Button variant="outline-secondary" size="sm"><i className="fas fa-arrow-left"/> {activeCategory.rootTitle}</Button>
                        </LinkContainer>
                        }
                        <Container fluid={true}>
                            <Row className="justify-content-center">
                                {
                                    categories.map(category => {
                                        return (
                                            <LinkContainer to={CatalogService.toCategory(category.furl)} key={category.id}>
                                                <Button variant="outline-dark"
                                                        className="product-categories-category float-left"
                                                        disabled={category.hasProducts === false}>
                                                    <span>{category.title}</span>&nbsp;
                                                    {/*<small className="text-muted">({category.amount})</small>*/}
                                                </Button>
                                            </LinkContainer>
                                        );
                                    })
                                }
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
                }
            </React.Fragment>
        )
    }
}

ProductCategories.propTypes = {
    location: PropTypes.object.isRequired
};

export default withRouter(ProductCategories);
