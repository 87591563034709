import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Col from "react-bootstrap/Col";
import Card from 'react-bootstrap/Card'
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";
import LinkService from "../services/LinkService";
import ImageService from "../services/ImageService";
import PriceService from "../services/PriceService";
import ProductRating from "./ProductRating";
import {FormattedMessage} from "react-intl";
import CardIndicators from "./CardIndicators";
import QuickAccessPanel from "./QuickAccessPanel";
import classNames from 'classnames'

class ProductCard extends Component {
    state = {
        imageError: false,
        imageLoaded: false
    };

    handleImageLoad = (event) => {
        ImageService.calculateImageSize(event.target);
        this.setState({imageLoaded: true});
    };

    imageFallback = () => {
        this.setState({imageError: true});
    };

    render = () => {
        const {product, user} = this.props;
        return (
            <Col md={3} style={{marginBottom: 15}}>
                {product.hidden === false &&
                <Card className={classNames("product", {"bg-danger": !product.active})}>
                    <LinkContainer to={LinkService.getProduct(product.url)}>
                        <a href={LinkService.getProduct(product.url)} className="product-card-card-image-frame">
                            <Card.Img variant="top"
                                      className={["hvr-fade", this.state.imageLoaded ? "d-block" : "d-none"].join(" ")}
                                      onLoad={this.handleImageLoad}
                                      src={!this.state.imageError ? product.catalogImage : LinkService.getNoCatalogImage()}
                                      onError={this.imageFallback}/>
                            <Card.Img variant="top"
                                      className={["hvr-fade", this.state.imageLoaded ? "d-none" : "d-block"].join(" ")}
                                      src={LinkService.getNoCatalogImage()}
                            />
                        </a>
                    </LinkContainer>
                    <Card.Body className="product-card-body">
                        <Card.Title className="product-card-title"><p>{product.title}</p></Card.Title>
                        <Card.Subtitle className="mb-2 text-muted product-card-title"><p>{product.brand.name}</p></Card.Subtitle>
                        {product.price !== undefined && product.price.amount > 0 &&
                        <span className="product-card-price text-light bg-dark">{PriceService.convertAndShow(product.price.code)}</span>
                        }
                        <div className="d-flex flex-row justify-content-between">
                            {product.reviewsCount > 0 &&
                            <ProductRating value={product.rating}/>
                            }
                            {product.reviewsCount === 0 && <span></span>}
                            <span className={classNames({"text-muted": (product.reviewsCount === 0)})}>
                                <FormattedMessage id="product.reviews"/>&nbsp;{product.reviewsCount}
                            </span>
                        </div>
                        {user !== null && <CardIndicators product={product} user={user}/>}
                        <QuickAccessPanel product={product}
                                          user={user}
                                          onLogin={this.props.onLogin}
                                          onAddedToWishlist={this.props.onAddedToWishlist}
                                          onRemovedFromWishlist={this.props.onRemovedFromWishlist}
                                          onProductUpdated={this.props.onProductUpdated}
                                          onRemoveProduct={this.props.onRemoveProduct}
                        />
                    </Card.Body>
                </Card>
                }
                {product.hidden === true &&
                <Card className="product">
                    <Card.Img variant="top"
                              className="hvr-fade"
                              onLoad={this.handleImageLoad}
                              src={LinkService.getNoCatalogImage()}
                              onError={this.imageFallback}/>
                    <Card.Body className="product-card-body">
                        <Card.Title className="product-card-title"><p><FormattedMessage id="product.hidden"/></p></Card.Title>
                        <Card.Subtitle className="mb-2 text-muted product-card-title"><p><FormattedMessage id="product.hidden"/></p></Card.Subtitle>
                        <div className="d-flex flex-row justify-content-between">
                            <span></span>
                            <span className="text-muted">
                                <FormattedMessage id="product.reviews"/>&nbsp;0
                            </span>
                        </div>
                        {user !== null && <CardIndicators product={product} user={user}/>}
                        <QuickAccessPanel product={product}
                                          user={user}
                                          onLogin={this.props.onLogin}
                                          onAddedToWishlist={this.props.onAddedToWishlist}
                                          onRemovedFromWishlist={this.props.onRemovedFromWishlist}
                                          onProductUpdated={this.props.onProductUpdated}
                                          onRemoveProduct={this.props.onRemoveProduct}
                        />
                    </Card.Body>
                </Card>
                }
            </Col>
        )
    }
}

ProductCard.propTypes = {
    product: PropTypes.object.isRequired,
    user: PropTypes.object,
    onLogin: PropTypes.func.isRequired,
    onAddedToWishlist: PropTypes.func.isRequired,
    onRemovedFromWishlist: PropTypes.func.isRequired,
    onProductUpdated: PropTypes.func.isRequired,
    onRemoveProduct: PropTypes.func.isRequired
};

export default ProductCard;
