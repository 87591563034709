import axios from "axios/index";
import ReturnPromise from "./ReturnPromise";

const USERS_API = "/api/v1/users";
const VISITORS_API = "/api/v1/visitors";
const USERS_ME_API = `${USERS_API}/me`;
const USERS_UNKNOWN_API = `${USERS_API}/unknown`;

const UserService = {
    getCurrent: function () {
        return ReturnPromise.get(axios.get(USERS_ME_API), true);
    },

    resetPassword: function (email) {
        return ReturnPromise.get(
            axios
                .post(USERS_UNKNOWN_API, {
                    email: email,

                }));
    },

    login: function (email, password) {
        return ReturnPromise.get(
            axios
                .post("/api/v1/login", {
                    email: email,
                    password: password

                }));
    },

    activate: function (user, code) {
        return ReturnPromise.get(
            axios
                .post("/api/v1/activate", {
                    user: user,
                    code: code
                }));
    },

    register: function (username, email, password, authority, lang) {
        return ReturnPromise.get(
            axios
                .post(USERS_API, {
                    lang: lang,
                    username: username,
                    email: email,
                    password: password,
                    authority: authority
                }));
    },

    fastRegister: function (email, username, lang) {
        return ReturnPromise.get(axios.post(VISITORS_API, {lang: lang, email: email, username: username}));
    },

    changePassword: function (oldPassword, newPassword) {
        return ReturnPromise.get(axios.put(`${USERS_ME_API}/password`, {oldPassword: oldPassword, newPassword: newPassword}));
    },

    updateLang: function (lang) {
        return ReturnPromise.get(axios.put(USERS_ME_API, {
            lang: lang
        }));
    },

    update: function (update) {
        return ReturnPromise.get(axios.put(USERS_ME_API, {
            country: update.country,
            city: update.city,
            about: update.about,
            avatar: update.avatar
        }));
    },

    uploadAvatar: function (file) {
        const data = new FormData();
        data.append('Files', file);
        return ReturnPromise.get(axios.post(`${USERS_ME_API}/avatar`, data));
    }
};

export default UserService;
