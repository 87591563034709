import React, {Component} from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CountriesFilter from "./CountriesFilter";
import ProductCategories from "./ProductCategories";
import ProductList from "./ProductList";
import PropTypes from "prop-types";
import RouteParams from "../services/RouteParams";
import ProductService from "../services/ProductService";

class Catalog extends Component {
    loadProducts = () => {
        let params = RouteParams.search();
        let category = RouteParams.catalogCategory();
        let sort = params.sort || 'rand';
        return ProductService.list(0, 24, sort, params.shop, params.country, category);
    };

    render = () => {
        return (
            <Row>
                <Col md={3}>
                    <ProductCategories location={window.location}/>
                    {/*<ShopsFilter location={window.location}/>*/}
                    <CountriesFilter location={window.location}/>
                </Col>
                <Col md={9}>
                    <ProductList location={window.location}
                                 user={this.props.user}
                                 onLogin={this.props.onLogin}
                                 onAddedToWishlist={this.props.onAddedToWishlist}
                                 onRemovedFromWishlist={this.props.onRemovedFromWishlist}
                                 loadProducts={this.loadProducts}
                    />
                </Col>
            </Row>
        )
    }
}

Catalog.propTypes = {
    user: PropTypes.object,
    onLogin: PropTypes.func.isRequired,
    onAddedToWishlist: PropTypes.func.isRequired,
    onRemovedFromWishlist: PropTypes.func.isRequired,
};

export default Catalog;
