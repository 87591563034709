import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import ProductCard from "./ProductCard";
import ProductService from "../services/ProductService";
import ProductCardPlaceholder from "./ProductCardPlaceholder";
import InfiniteScroll from "../shell/InfiniteScroll";
import LoadingIndicator from "./LoadingIndicator";
import {FormattedMessage, injectIntl} from "react-intl";
import Col from "react-bootstrap/Col";
import Popup from "../shell/Popup";

class ProductList extends Component {
    state = {
        products: null,
        lastUrl: null,
        // total: null,
        nextUrl: null,
        scrollEnable: false,
        loading: false,

        showPopup: false,
        popupTitle: "",
        popupMessage: "",
        onPopupConfirm: () => {
        }
    };

    doLoadProductsList = () => {
        if (this.state.nextUrl === null || this.state.products === null) {
            return this.props.loadProducts();
        } else {
            return ProductService.findByUrl(this.state.nextUrl);
        }
    };

    loadProductsList = () => {
        let itself = this;
        this.setState({loading: true});

        this
            .doLoadProductsList()
            .then(function (result) {
                let products = itself.state.products || [];
                result.items.forEach(product => {
                    products.push(product);
                });

                itself.setState({
                    products: products,
                    // total: result.total,
                    scrollEnable: true,
                    nextUrl: result.next || null,
                    loading: false
                });
            })
    };

    loadNext = () => {
        this.setState({scrollEnable: false});
        if (this.state.nextUrl !== null) {
            this.loadProductsList();
        }
    };

    componentWillMount = () => {
        this.updateLastUrlAndLoadProductList(this.props);

        // const params = this.props.location.pathname + this.props.location.search;
        //
        // this.setState({
        //     lastUrl: params,
        //     products: null,
        //     nextUrl: null
        // }, this.loadProductsList);
    };

    componentWillReceiveProps = (nextProps) => {
        this.updateLastUrlAndLoadProductList(nextProps);

        // const params = nextProps.location.pathname + nextProps.location.search;
        // if (this.state.lastUrl !== params) {
        //     if (this.state.lastUrl !== null) {
        //         this.setState({
        //             products: null,
        //             nextUrl: null
        //         }, this.loadProductsList);
        //     }
        //
        //     this.setState({lastUrl: params});
        // }
    };

    updateLastUrlAndLoadProductList = (prop) => {
        const params = prop.location.pathname + prop.location.search;
        if (this.state.lastUrl !== params) {
            this.setState({
                lastUrl: params,
                products: null,
                nextUrl: null
            }, this.loadProductsList);
        }
    };

    replaceProduct = (product) => {
        const products = this.state.products;
        const i = products.map(p => p.id).indexOf(product.id);
        if (i >= 0) {
            products[i] = product;
            this.setState({products: products});
        }
    };

    removeProduct = (product) => {
        const products = this.state.products;
        const i = products.map(p => p.id).indexOf(product.id);
        if (i >= 0) {
            delete products[i];
            this.setState({products: products});
        }
    };

    onAddedToWishlist = (product) => {
        this.replaceProduct(product);
        this.props.onAddedToWishlist(product);
    };

    onRemovedFromWishlist = (product) => {
        this.replaceProduct(product);
        this.props.onRemovedFromWishlist(product);
    };

    onRemoveProduct = (product) => {
        const {formatMessage} = this.props.intl;
        this.setState({
            showPopup: true,
            popupTitle: formatMessage({id: "product.remove"}),
            popupMessage: formatMessage({id: "product.confirmRemove"}) + "'" + product.title + "'?",
            onPopupConfirm: () => {
                ProductService
                    .deleteProduct(product.id)
                    .then(() => {
                        this.removeProduct(product)
                    });
            }
        });
    };

    render = () => {
        const {products} = this.state;
        return (
            <Container fluid={true} style={{padding: 0}}>
                {/*{total !== null && total > 0 &&*/}
                {/*<Row>*/}
                {/*    <SortingToolbar resultsFound={this.state.total}/>*/}
                {/*</Row>*/}
                {/*}*/}

                <Row style={{marginLeft: -15, marginRight: -15}}>
                    {products === null &&
                    [1, 2, 3, 4, 5, 6].map(index => {
                            return (<ProductCardPlaceholder key={index}/>)
                        }
                    )
                    }

                    {products !== null && products.length > 0 &&
                    products.map(product => {
                        return (
                            <ProductCard product={product}
                                         key={product.id}
                                         user={this.props.user}
                                         onLogin={this.props.onLogin}
                                         onAddedToWishlist={this.onAddedToWishlist}
                                         onRemovedFromWishlist={this.onRemovedFromWishlist}
                                         onProductUpdated={this.replaceProduct}
                                         onRemoveProduct={this.onRemoveProduct}
                            />
                        )
                    })
                    }
                    {products !== null && products.length === 0 &&
                    <Col md={12} style={{marginTop: 30}}>
                        <p className="lead text-center"><FormattedMessage id="product.resultsNotFound"/></p>
                    </Col>
                    }
                </Row>
                {this.state.loading && <LoadingIndicator/>}
                <InfiniteScroll distance={1} disabled={!this.state.scrollEnable} onBottom={this.loadNext}/>
                <Popup show={this.state.showPopup}
                       onClose={() => this.setState({showPopup: false})}
                       title={this.state.popupTitle}
                       message={this.state.popupMessage}
                       onConfirm={this.state.onPopupConfirm}
                       type="CONFIRM"
                />
            </Container>
        )
    }
}

ProductList.propTypes = {
    location: PropTypes.object.isRequired,
    user: PropTypes.object,
    onLogin: PropTypes.func.isRequired,
    onAddedToWishlist: PropTypes.func.isRequired,
    onRemovedFromWishlist: PropTypes.func.isRequired,
    loadProducts: PropTypes.func.isRequired
};

export default injectIntl(ProductList);
