import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import Modal from 'react-bootstrap/Modal'
import Rating from "./Rating"
import Form from 'react-bootstrap/Form'
import Button from "react-bootstrap/Button"
import Alert from 'react-bootstrap/Alert'
import ProductService from "../services/ProductService";

class RateItem extends Component {
    state = {
        rating: 0,
        anonymous: false,
        message: "",

        alertMessage: null,
        isSending: false
    };

    doRateItem = () => {
        const {rating, anonymous, message} = this.state;
        const itself = this;
        const {formatMessage} = this.props.intl;

        this.setState({isSending: true});
        ProductService
            .createReview(this.props.productId, rating, anonymous, message)
            .then(function () {
                itself.props.onItemRated();
                itself.props.onClose()

            }, function (error) {
                itself.setState({
                    isSending: false,
                    alertMessage: formatMessage({id: "product.reviewWasNotSent"}),
                });
            });
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        const {rating, anonymous, message, alertMessage, isSending} = this.state;

        return (
            <Modal show={this.props.show} onHide={this.props.onClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title><FormattedMessage id="product.rateItem"/></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert dismissible
                           variant="danger"
                           show={alertMessage !== null}
                           onClose={() => this.setState({alertMessage: null})}>
                        {alertMessage}
                    </Alert>
                    <Form>
                        <Rating onSelected={(i) => this.setState({rating: i})} disabled={isSending}/>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Control as="textarea" rows="3"
                                          placeholder={formatMessage({id: "product.leaveComment"})}
                                          value={message}
                                          disabled={isSending}
                                          onChange={event => this.setState({message: event.target.value})}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicChecbox">
                            <Form.Check type="checkbox"
                                        checked={anonymous}
                                        disabled={isSending}
                                        onChange={event => this.setState({anonymous: event.target.checked})}
                                        label={formatMessage({id: "product.stayAnonymous"})}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="button" block
                            disabled={rating === 0 || isSending}
                            onClick={this.doRateItem}
                    >
                        {isSending ? formatMessage({id: "common.sending"}) : formatMessage({id: "messages.send"})}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

RateItem.propTypes = {
    show: PropTypes.bool.isRequired,
    productId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onItemRated: PropTypes.func.isRequired
};

export default injectIntl(RateItem);
