import axios from "axios/index";
import LocalStorage from "./LocalStorage";

let sprintf = require('sprintf-js').sprintf

const formats = {
    RUB: '%.0f₽',
    USD: '$%01.2f',
    EUR: '%01.2f €',
    CZK: '%.0f Kč',
    UAH: '%.0f грн.',
    DEF: '%f'
};

const PriceService = {
    loadCurrencyRates: function () {
        return axios
            .get("/api/v1/currency-rates")
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    LocalStorage.set("currencyRates", response.data);
                }
                return Promise.reject(response);
            })
            .catch(error => {
            });
    },

    // {amount: 30000, currency: "CZK", code: "30000-CZK"}
    convertAndShow: function (money) {
        return this.formatPrice(this.convertMoney(money));
    },

    convertMoney: function (input) {
        let price = input.split('-');
        let amount = +price[0];
        if (amount > 0) {
            return this.convert(amount, price[1]);
        } else {
            return input;
        }
    },

    convert: function (fromAmmount, fromCurrency) {
        let currencyRates = LocalStorage.get("currencyRates");
        let currentCurrency = LocalStorage.get("currency").toUpperCase();

        if (currencyRates === undefined || currencyRates === null ||
            currencyRates[fromCurrency] === undefined || currencyRates[currentCurrency] === undefined) {
            return fromAmmount + '-' + fromCurrency;
        }

        let fromRate = currencyRates[fromCurrency];
        let toRate = currencyRates[currentCurrency];
        let conversationRate = toRate / fromRate;

        return fromAmmount * conversationRate + "-" + currentCurrency;
    },

    formatPrice: function (input) {
        let price = input.split('-');
        let amount = +price[0] / 100;
        let format = formats[price[1]] || formats.DEF;
        if (amount > 0) {
            return sprintf(format, amount);
        } else {
            return "";
        }
    }
};

export default PriceService;
