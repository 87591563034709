import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ProductRating extends Component {
    render = () => {
        const range = [1, 2, 3, 4, 5];
        const value = Math.round(this.props.value);
        return (
            <span>
                {
                    range.map(index => {
                        const starClass = index <= value ? 'fas' : 'far';
                        return (<i className={"text-warning fa-star " + starClass} key={index}/>);
                    })
                }
            </span>
        )
    }
}

ProductRating.propTypes = {
    value: PropTypes.number.isRequired
};

export default ProductRating;
