import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal'
import {injectIntl} from "react-intl";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Login from "./Login"
import Register from "./Register";

class LoginDialog extends Component {
    state = {
        key: "login"
    };

    render() {
        const {formatMessage} = this.props.intl;
        return (
            <React.Fragment>
                <Modal show={this.props.show} onHide={this.props.onHide} centered>
                    <Modal.Header closeButton>
                        <Tabs activeKey={this.state.key}
                              onSelect={key => this.setState({key})}
                              variant="pills">
                            <Tab eventKey="login" title={formatMessage({id: "topNavi.login"})}/>
                            <Tab eventKey="register" title={formatMessage({id: "topNavi.register"})}/>
                        </Tabs>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.key === "login" && <Login onLogin={this.props.onLogin} onForgotPassword={this.props.onForgotPassword}/>}
                        {this.state.key === "register" && <Register/>}
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

LoginDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    onLogin: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    onForgotPassword: PropTypes.func.isRequired
};

export default injectIntl(LoginDialog);
