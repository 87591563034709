import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ProfilePage from "./ProfilePage";
import {FormattedMessage} from "react-intl";

import Col from 'react-bootstrap/Col'
import ProfileDialog from "./ProfileDialog";

class Profile extends Component {
    render = () => {
        const {user} = this.props;
        return (
            <React.Fragment>
                {user &&
                <ProfilePage user={user}>
                    <h1><FormattedMessage id="topNavi.profile"/></h1>
                    <Col md={8} style={{padding: 0}}>
                        <ProfileDialog user={user} onUpdateUserDetails={this.props.onUpdateUserDetails}/>
                    </Col>
                </ProfilePage>
                }
            </React.Fragment>
        )
    }
}

Profile.propTypes = {
    user: PropTypes.object,
    onUpdateUserDetails: PropTypes.func.isRequired
};

export default Profile;
