import axios from "axios/index";
import ReturnPromise from "./ReturnPromise";

const MY_STUFF = "/api/v1/users/me/mystuff";
const PRODUCTS = "/api/v1/products";

const ProductService = {
    list: function (offset, limit, sort, shop, country, category) {
        return ReturnPromise.get(
            axios
                .get(PRODUCTS, {
                    params: {
                        offset: offset,
                        limit: limit,
                        sort: sort,
                        shop: shop,
                        country: country,
                        category: category
                    }
                }));
    },

    myStuff: function (offset, limit) {
        return ReturnPromise.get(axios.get(MY_STUFF, {params: {offset: offset, limit: limit}}));
    },

    findMyStuffById: function (productId) {
        return ReturnPromise.get(axios.get(`${MY_STUFF}/${productId}`));
    },

    findByUrl: function (url) {
        return ReturnPromise.get(axios.get(url));
    },

    productByFurl: function (furl) {
        return ReturnPromise.get(axios.get(`${PRODUCTS}/${furl}`));
    },


    createReview: function (productId, rate, anonymous, body) {
        return ReturnPromise.get(
            axios
                .post(`${PRODUCTS}/${productId}/comments`, {
                    anonymous: anonymous,
                    body: body,
                    rating: rate,
                }));
    },

    createReviewReply: function (productId, reviewId, message) {
        return ReturnPromise.get(
            axios
                .post(`${PRODUCTS}/${productId}/comments`, {
                    body: message,
                    replyTo: reviewId
                }));
    },

    updateVisibility: function (productId, isVisible) {
        return ReturnPromise.get(axios.put(`${MY_STUFF}/${productId}`, {active: isVisible}));
    },

    deleteProduct: function (productId) {
        return ReturnPromise.get(axios.delete(`${MY_STUFF}/${productId}`));
    },

    uploadImage: function (file) {
        const data = new FormData();
        data.append('Files', file);
        return ReturnPromise.get(axios.post(`${MY_STUFF}/any/images`, data));
    },

    updateProduct: function (product) {
        let url = product.id ? `${MY_STUFF}/${product.id}` : MY_STUFF;
        return ReturnPromise.get(axios.post(url, product));
    }
};

export default ProductService;
