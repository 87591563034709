import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown'
import Nav from 'react-bootstrap/Nav'
import LinkService from "../services/LinkService";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";
import ProfileCard from "../profile/ProfileCard";

class AccountLink extends Component {
    render = () => {
        const {user} = this.props;
        return (
            <React.Fragment>
                {!user &&
                <Form inline>
                    <Button
                        style={{marginLeft: 10}}
                        onClick={this.props.onLogin}
                        variant="outline-info">
                        <FormattedMessage id="topNavi.login"/> / <FormattedMessage id="topNavi.register"/>
                    </Button>
                </Form>
                }
                {user &&
                <Dropdown as={Nav.Item}>
                    <Dropdown.Toggle as={Nav.Link}>
                        <i className="fa fa-user"/>&nbsp;
                        {user.name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <ProfileCard user={user}/>
                        <Dropdown.Divider/>
                        <LinkContainer to={LinkService.getProfile()}>
                            <Dropdown.Item active={false}><FormattedMessage id="topNavi.profile"/></Dropdown.Item>
                        </LinkContainer>
                        {user.privileges.includes("SHOP") &&
                        <LinkContainer to={LinkService.getMyStuff()}>
                            <Dropdown.Item active={false}><FormattedMessage id="topNavi.mystuff"/></Dropdown.Item>
                        </LinkContainer>
                        }
                        <Dropdown.Divider/>
                        <Dropdown.Item onClick={this.props.onLogout}>
                            <FormattedMessage id="topNavi.logout"/>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                }
            </React.Fragment>
        )
    }
}

AccountLink.propTypes = {
    user: PropTypes.object,
    onLogin: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
};

export default AccountLink;
