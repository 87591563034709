import React, {Component} from 'react';

class HelpTermsCz extends Component {
    render() {
        return (
            <React.Fragment>
                <h1>Podmínky připojení</h1>
                <p>Registrací a užíváním internetového serveru Valika dává uživatel souhlas s těmito podmínkami.</p>
                <h2>Pravidla užívání</h2>
                <p>Valika je obchodním místem, které zprostředkovává prodej a nákup rukodělných výrobků z vlny mezi
                    prodávajícím a
                    kupujícím.</p>
                <ul>
                    <li>Registrace a propagace zboží prodávajícího na Valika je zcela zdarma.</li>
                    <li>Kupující platí zboží přímo prodávajícímu -převodem na účet nebo na dobírku, provozovateli Valika
                        už nic
                        neplatí.
                    </li>
                    <li>Prodávající je povinen uhradit (viz. níže) provozovateli serveru Valika provizi za
                        zprostředkování prodeje, tato
                        povinnost vzniká prodávajícímu ve chvíli potvrzením objednávky od kupujícího a zaplacením zboží.
                        Pokud kupující
                        neuhradí cenu za zboží (a zboží tedy neobdrží) může prodávající zažádat o slevu z provizí v
                        některém z dalších
                        měsíců. Provize z prodeje se platí jednou měsíčně na základě uskutečněných prodejů.
                    </li>
                    <li>Provozovatel serveru Valika neprodává vlastní zboží a není zodpovědný za prodávané zboží
                        (správnost vyobrazeného
                        zboží, jeho kvalitu, vlastnosti, dostupnost apod.) a není odpovědný za koupi, prodej, reklamaci
                        či za případné
                        spory mezi kupujícím a prodávajícím. Provozovatel serveru Valika rovněž neodpovídá za případy,
                        kdy se ukáže, že
                        identita registrovaného uživatele nebyla správná. Provozovatel serveru Valika identitu
                        prodávajících a
                        kupujících neprověřuje.
                    </li>
                    <li>Provozovatel serveru Valika se nepodílí na obsahu nabídek, popisu zboží, jeho vyobrazení a
                        informacích o
                        registrovaných uživatelích.
                    </li>
                    <li>Uživatelé serveru Valika jsou povinni dodržovat právní řád ČR a dodržovat dobré mravy.</li>
                </ul>

                <h2>I. PODMÍNKY UŽÍVÁNÍ</h2>
                <p>Obchodní aktivity na serveru Valika jsou veřejné a mohou se jich zúčastnit zaregistrované fyzické
                    osoby starší 18 let
                    a zaregistrované právnické osoby, které mají způsobilost k právním úkonům v plném rozsahu, s
                    výjimkou uživatelů,
                    kterým byla provozovatelem zrušena registrace.</p>
                <p>Registrovaní uživatelé ani zájemci o uzavření smlouvy nemají žádný právní nárok na užívání serveru
                    Valika nebo na
                    uzavření smlouvy. Provozovatel si vyhrazuje právo nepovolit registraci zájemci o ni, popř. odstoupit
                    od smlouvy se
                    stávajícím uživatelem nebo omezit jeho přístup k některým službám a to i bez udání důvodu.</p>
                <p>Provozovatel nijak nezaručuje udržování a uchovávání všech dat uživatelů, nepřetržitost a trvalou
                    kontinuitu
                    provozu.</p>
                <p>Při užívání serveru Valika platí pravidla uvedená výše.</p>

                <h2>II. REGISTRACE</h2>
                <p>Podmínkou zprostředkování prodeje zboží nebo jeho koupě prostřednictvím serveru Valika je souhlas s
                    touto smlouvou a
                    bezplatná registrace uživatele.</p>
                <p>Uživatel, který je fyzickou osobou, je povinen při registraci zadat jméno, příjmení, plnou adresu
                    (stálou či
                    přechodnou), e-mailovou adresu, telefonní číslo, uživatelské jméno a heslo. Uživatel, který je
                    právnickou osobou,
                    uvede své obchodní jméno (název), IČ, sídlo, e-mailovou adresu, telefonní číslo, jméno a příjmení
                    osoby, které za
                    něj jedná, uživatelské jméno a heslo. Uživatel je povinen provozovatele serveru Valika informovat o
                    změně
                    kteréhokoliv z těchto údajů (opravou těchto údajů ve svém účtu) a udržovat tyto údaje aktuální.</p>
                <p>Provozovatel je oprávněn poskytnout prodávajícímu či kupujícímu nezbytné údaje o protistraně (z
                    důvodu zaslání zboží,
                    jeho reklamace apod.), včetně jeho jména a příjmení, obchodního jména (názvu), telefonního čísla,
                    místa trvalého či
                    přechodného pobytu, sídla.</p>
                <p>Uživatelé mají přístup ke svým údajům a mohou je měnit. Uživatelé jsou také oprávněni zažádat o
                    jejich odstranění z
                    databáze po ukončení činnosti na serveru Valika.</p>
                <p>Uživatelé jsou oprávněni vlastnit více než jeden uživatelský účet, nejsou však oprávněni těchto účtů
                    používat k
                    porušování této smlouvy či k získání výhody vícenásobného používání omezených funkcí (např.
                    manipulace s účty za
                    účelem skrývání identity, manipulativní podpoře prodeje apod.). Všichni vlastníci vícenásobných účtů
                    jsou povinni o
                    svých dalších účtech informovat v profilu účtu.</p>
                <p>Registrace se ověřuje pomocí automaticky zasílaného e-mailového potvrzení. Registrace, které nejsou
                    takto potvrzeny,
                    jsou nefunkční a neplatné. Potvrzením registrace je uzavřena smlouva mezi provozovatelem serveru
                    Valika a
                    uživatelem.</p>
                <p>Registrace je vždy vázána na konkrétního uživatele a její převod na jinou osobu není bez souhlasu
                    provozovatele
                    serveru povolen.</p>

                <h2>III. ZAKÁZANÉ ZBOŽÍ</h2>
                <p>Je zakázáno nabízet zboží, jehož nabídka, prodej, koupě či užívání je v rozporu s dobrými mravy či
                    platnými právními
                    předpisy. Zakázáno je zejména nabízet zboží, které neodpovídá zaměření serveru, dále zboží, k němuž
                    nemá prodávající
                    vlastnické právo nebo právo je prodávat nebo zboží, které je zastaveno nebo jsou dispozice vlastníka
                    s ním jinak
                    omezeny, zbraně, alkohol, omamné či psychotropní látky, léky, zdravotnické potřeby, pornografii, či
                    jinak pobuřující
                    pornografické nebo erotické materiály, zboží, jehož prostřednictvím dochází k porušování duševního
                    vlastnictví
                    (porušování práv k ochranným známkám, označení původu, nelegální rozmnoženiny autorských děl apod.),
                    zboží určené k
                    propagaci a šíření názorů extrémní levice či extrémní pravice, směřující k potlačení práv a svobod
                    člověka nebo
                    hlásá národnostní, rasovou, náboženskou či třídní zášť nebo zášť vůči jiné skupině osob.</p>
                <p>Provozovatel si vyhrazuje právo odstranit zakázané zboží ze systému.</p>
                <p>Provozovatel si také vyhrazuje právo ve výjimečných případech odstranit zboží ze systému i bez udání
                    důvodu.</p>

                <h2>IV. NÁKUP ZBOŽÍ</h2>
                <p>Kupující vybírá zboží od prodejců s vědomím, že Valika není prodejcem zboží a že jednotlivé zboží
                    pochází od prodejců
                    - registrovaných uživatelů serveru Valika, od nichž si kupující zboží kupuje. Provozovatel za
                    prodávané zboží nijak
                    neodpovídá.</p>
                <p>Kupující bere na vědomí, že zboží, které vkládá do košíku, může pocházet od více prodávajících a
                    zboží, které
                    objednává v jedné objednávce, nemusí být akceptováno celé a také nemusí být dodáno současně.</p>
                <p>Poštovné je v ceně zboží.</p>
                <p>Kupující bere na vědomí, že odesláním objednávky pouze informuje prodávající o zájmu nakoupit toto
                    zboží, ale zboží
                    bude zasláno až ve chvíli potvrzení objednávky na straně prodávajícího (prodávajících) a uhrazení
                    částky za zboží
                    kupujícím.</p>
                <p>Kupující bere na vědomí, že prodávající má právo objednávku odmítnout, nebo její část. V tomto
                    případě hradí pouze
                    schválenou část objednávky.</p>
                <p>Kupující neplatí žádný poplatek nebo provizi za zprostředkování prodeje provozovateli. Zboží platí
                    přímo prodejci a
                    to předem na účet prodávajícího (či jinak, dle platebních podmínek prodejce).</p>

                <h2>V. PRODEJ ZBOŽÍ</h2>
                <h3>V. I. Definice zboží</h3>
                <p>Server Valika je primárně určen k prodeji nového kreativního rukodělného zboží z vlny tvořeného
                    prodávajícím.</p>

                <h3>V. II. Prodávající</h3>
                <p>Prodávajícím se běžně rozumí fyzická osoba nebo právnická osoba za kterou jedná fyzická osoba.</p>

                <h4>V. III. Pravidla při umisťování zboží</h4>
                <p>Každá uveřejněná položka v prodejní části serveru musí být konkrétním zbožím určeným k prodeji (je
                    zakázáno vkládat
                    zboží pouze na ukázku).</p>
                <p>Prodávající vystavuje zboží prostřednictvím elektronického formuláře, ve kterém je povinen uvést
                    všechny povinné
                    položky.</p>
                <p>Nabízené zboží, jeho zobrazení a popis musí plně a co nejvěrněji popisovat skutečnost (u fotografií
                    doporučujeme
                    používat také doplňkové fotografie). Zobrazení zboží a jeho popis musí dostatečně informovat
                    nakupující tak, aby
                    byla minimalizována možnost záměny nebo mylného pocitu návštěvníků o vyobrazeném zboží.</p>
                <p>Abyste mohli začít prodávat na Valice - je nutné:</p>
                <ol>
                    <li>Přihlásit se</li>
                    <li>Vyplnit informace o sobě.</li>
                    <li>Umístit fotografie zboží.</li>
                    <li>Vyplnit informace o zboží: materiál, rozměr, cenu a popis.</li>
                </ol>
                <p><strong>Věnujte pozornost, prosim!</strong></p>
                <p>
                    Webová stránka zprostředkovává kontakt mezi prodávajícím a kupujicím. Placení a platební podmínky si
                    projednává
                    prodávající s kupujícím.
                </p>
                <p><strong>Umístění na Valice je zdarma.</strong></p>
                <p>Registrovaní uživatele mohou využít následující možnosti:</p>
                <ul>
                    <li>Vlastní obchod - místo, kde budou nabízet a prodávat své výrobky, komunikovat se zákazníky -
                        každý autor má svou
                        vlastní adresu obchodu, která vypadá - jméno-autora.valika.org.
                    </li>
                    <li>Autor může umístit adresu svého obchodu ve svém blogu nebo na livejournal.com (funkčnost je k
                        dispozici v
                        autorově profilu na záložce Obchodní nastavení).
                    </li>
                </ul>

                <h3>V. IV. Nepovolené zboží / nepovolené údaje</h3>
                <p>Je zakázáno prodávat zboží, které není vlastnoručním výrobkem prodejce (nebo dle vlastního
                    grafického/designového
                    návrhu) či prodejního týmu (viz V. II. Prodejce), nebo zboží od jiného prodejce dále prodávat.</p>
                <p>Je zakázáno nabízet služby (mimo kurzů). Předmětem obchodu musí být vždy zboží (fyzický předmět) s
                    přesně definovanou
                    cenou, obsahem a parametry tak, aby mohl zákazník zboží vložit do košíku a nakoupit je.</p>
                <p>Je zakázáno nabízet k prodeji zboží, která porušují autorská práva (jako např. kopie děl jiných
                    tvůrců apod.)</p>
                <p>Je zakázáno duplikovat zboží - tedy nabízet stejné zboží pod více katalogovými čísly (pro nastavení
                    počtu dostupného
                    zboží použijte políčko "množství").</p>
                <p>Titulek, popis, klíčová slova ani obrázek zboží nesmí obsahovat kontaktní údaje na prodávajícího,
                    jako např.
                    telefonní číslo, e-mail, odkaz na webové stránky apod. (výjimku tvoří jméno a příjmení nebo umělecké
                    jméno autora).
                    Tyto kontaktní údaje (nebo nabádání k jejich vyhledání jinde na internetu) nesmí obsahovat ani další
                    prvky
                    písemného, či obrazového prodeje, jako jsou např. komentáře, diskuse, blogy, vnitřní pošta apod.</p>
                <p>Titul zboží ani popis předmětu nesmí obsahovat žádná klíčová slova použitá za účelem manipulace
                    výsledků vyhledávání.
                    Jedná se především o násilně volená klíčová slova, která nemají s položkou nic společného.</p>
                <p>Titul zboží ani popis předmětu nesmí obsahovat elementy HTML, JavaScriptu, PHP a dalších
                    programovacích jazyků se
                    záměrem ovlivnit obsah nebo prodejnost zboží, či jinak poškodit či ohrozit funkčnost serveru
                    Valika.</p>
                <p>Je zakázáno používat fotografie, které by mohly porušovat autorská práva (např. fotografie z
                    časopisů, fotografie, ke
                    kterým které nemá prodávající autorské právo apod.).</p>
                <p>V případě nutnosti použití počítačové simulace nebo počítačových retuší, které zásadně mění charakter
                    a podstatu nebo
                    užití zboží nebo z některých důvodů nemohou informovat nakupující plně o podstatě zboží (např. 2D
                    nebo 3D
                    vizualizace, nabídka zboží z dřívějšího prodeje ale se záměnou některých prvků, grafické návrhy pro
                    potisky apod.)
                    musí prodejce na tuto skutečnost upozornit v popisu zboží nebo přímo v obrázku (např.: obrázek je
                    ilustrační,
                    obrázek je 3D vizualizací apod.).</p>
                <p>Prodejce nesmí jakkoliv (vnitřní poštou, e-mailem) komunikovat se zákazníky Valika o možnosti prodeje
                    mimo Valika
                    (odkaz na vlastní e-shop, návod jak vyhledat vlastní e-shop, případně výměna kontaktních údajů) ani
                    o možnosti
                    výměny zboží bez řádného zakoupení přes systém Valika (protislužba kompenzací či vzájemným
                    zápočtem).</p>

                <h3>V. V. Platební a dodací podmínky, cena zboží</h3>
                <p><strong>Cena zboží</strong></p>
                <p>Veškeré prodávajícím uváděné ceny jsou cenami včetně DPH. Prodejce je povinen uvést vždy plnou cenu
                    zboží a za tuto
                    cenu zboží nabízet (není povoleno prodávat zboží pouze za zálohu).</p>
                <p>Uvedená cena zboží musí být cenou obvyklou nebo přiměřenou vzhledem k použitému materiálu, technice,
                    době zpracování
                    a umělecké invenci. Není povoleno stanovovat účelově nepřiměřenou cenu zboží, např. z důvodů, aby
                    zboží zůstalo
                    neprodáno nebo bylo naopak prodáno za zanedbatelnou cenu kvůli minimální provizi.</p>
                <p><strong>Platební podmínky</strong></p>
                <ul>
                    <li>Platba předem - platba bude provedena bankovním převodem. Číslo bankovního spojení bude uvedeno
                        v e-mailu, který
                        bude zaslán po potvrzení rezervace.
                    </li>
                    <li>Dobírka - zboží zaplatíte při doručení Českou poštou nebo přímo dopravci.</li>
                </ul>
                <p>V současnosti nepřijímáme online platby. Do budoucna bychom chtěli používat PayPal a kreditní
                    karty.</p>
                <p>Způsob dodání a platby výrobku je na domluvě mezi prodávajícím a kupujícím.</p>
                <p><strong>Dodací podmínky</strong></p>
                <p>Poštovné nebo dopravné je obsaženo v ceně výrobku. Autor zabalí a nechá doručit výrobek zákazníkovi.
                    Doba dodání
                    záleží na destinaci autora a zákazníka.</p>

                <h4>V. VI. Úprava položek</h4>
                <p>Prodávající má možnost kdykoliv smazat nabízené zboží, nebo je na neurčitou dobu skrýt. Prodávající
                    může také měnit a
                    upravovat popis zboží.</p>

                <h3>V. VII. Kupní smlouva</h3>
                <p>Prodávající je informován o zájmu kupujícího pomocí elektronické pošty Valika. Tato informace není
                    závazná a
                    prodávající může objednávku odmítnout.</p>
                <p>Prodávající může, po přijetí oznámení o objednávce, přijmout jen část objednávky a to pomocí označení
                    pouze
                    vyhovujících položek.</p>
                <p>Přijetím objednávky uzavírají obě smluvní strany (kupující a prodávající) Kupní smlouvu (nákup na
                    dálku) dle platných
                    zákonů České republiky.</p>
                <p>Prodávající se zavazuje vyexpedovat zboží po přijetí, potvrzení a zaplacení objednávky a to bez
                    zbytečného
                    odkladu.</p>

                <h2>VI. PROVIZE ZA PRODEJ ZBOŽÍ</h2>
                <p>Vystavení zboží na serveru Valika je pro prodávajícího zcela zdarma. Server Valika však účtuje
                    provizi za
                    zprostředkování z uskutečněného prodeje. Provizi za zprostředkování je povinen prodávající zaplatit
                    provozovateli po
                    potvrzení objednávky na základě měsíčního vyúčtování. Pokud kupující zboží nezaplatí (a tedy
                    nepřevezme), je
                    prodávající povinen tuto skutečnost označit ve výpisu provizí a již uhrazená provize bude
                    komenzována formou slevy v
                    dalším měsíci.</p>
                <p>O souhrnné provizi z prodeje za kalendářní měsíc je informován e-mailem . Provozovatel vystavuje
                    prodávajícímu daňový
                    doklad automaticky formou elektronické faktury k vytištění.</p>
                <p>Splatnost provizí je vždy uvedena na vydané faktuře (za den splatnosti se považuje den připsání
                    částky na účet
                    provozovatele), platby po splatnosti mohou být penalizovány. Platby přijímá provozovatel pouze
                    bezhotovostně
                    převodem na účet.</p>
                <p>V případě neuhrazení provize nebo nedodržení termínu platby je provozovatel oprávněn zrušit účet
                    uživatele, odstranit
                    jeho zboží z nabídky, nepovolit opětovnou registraci na serveru Valika a vymáhat dlužnou částku
                    soudní cestou.</p>
                <p>Server Valika není povinen řešit případné spory mezi prodávajícím a kupujícím a reklamaci zboží,
                    prodávající nemá
                    nárok na navrácení provize v případě sporu s kupujícím.</p>
                <p>Aktuální výše provize činí 0% z prodejní ceny zboží. Provozovatel serveru Valika si vyhrazuje právo
                    výši provize
                    změnit.</p>

                <h2>VII. DALŠÍ PRAVIDLA PRO NEPRODEJNÍ SEKCE</h2>
                <p>Je zakázáno uveřejňování přímých osobních údajů (adresa, telefonní číslo, e-mail, web, odkazy na
                    facebook nebo
                    fotogalerie apod.) v komentářích, diskusích, klubech, vnitřní poštou a v dalších komunitních prvcích
                    serveru nebo
                    vybízet či záměrně umožňovat jejich dohledání. Výjimkou z tohoto pravidla je uveřejnění jména a
                    příjmení autora
                    případně jeho umělecké jméno.</p>
                <p>Je zakázáno vkládat jakékoliv reklamní upozornění či webové odkazy s reklamním charakterem s výjimkou
                    podpory
                    vlastního obchodu na Valika (ne však s odkazem mimo server Valika).</p>
                <p>Je zakázáno vyvíjet aktivitu, která by mohla být klasifikována jako nevyžádaná pošta (spam). Tedy
                    například zasílat
                    opakované či hromadné reklamní nebo obtěžující informace vnitřní poštou, umisťovat opakované či
                    hromadné reklamní
                    nebo obtěžující informace v diskusích nebo komentářích.</p>
                <p>Je zakázáno vytvářet aktivity, které by mohly být označeny za nekalou soutěž (např. registrace už.
                    jména, které by
                    mohlo případnou záměnou poškodit původního prodejce, komunikovat či nabízet zboží s cílem zaměnit
                    identitu prodejce
                    či zboží apod.)</p>
                <p>Je zakázáno, pomocí komunitních prvků serveru (diskuse, komentáře, pošta), veřejně podrývat autoritu
                    provozovatele či
                    svým chováním jinak vyjadřovat svoji neloajalitu vůči provozovateli a zaměstnancům
                    provozovatele.</p>
                <p>Je zakázáno veřejně poškozovat či napadat prodejce a nakupující. Negativní hodnocení (sekce Hodnocení
                    uživatele) není
                    klasifikováno jako poškozování. V případě sporů doporučujeme řešení vnitřní poštou nebo
                    kontaktováním provozovatele.
                    Provozovatel nijak neodpovídá za případné spory.</p>

                <h2>VIII. OCHRANA OSOBNÍCH ÚDAJŮ</h2>
                <p>Uživatel je povinen uvádět pravdivé údaje. Provozovatel chrání veškeré informace zaslané při
                    registraci uživatelem ve
                    smyslu zákona č. 101/2000 Sb., o ochraně osobních údajů, ve znění pozdějších předpisů a je oprávněn
                    je poskytnout
                    pouze za níže uvedených podmínek. Uživatel souhlasí s tím, aby provozovatel vedl údaje, které mu při
                    registraci
                    poskytne a to výlučně v souvislosti s provozem serveru Valika . Uživatel výslovně souhlasí s tím,
                    aby jeho údaje a
                    to jméno a příjmení, obchodní jméno, název, telefonní číslo, místo trvalého či přechodného pobytu,
                    sídlo, byly
                    provozovatelem poskytnuty uživatelům - kupujícím, kteří o koupi zboží uživatele jako prodávajícího
                    projeví zájem, a
                    uživatelům - prodávajícím, o prodej jejichž nabízeného zboží projeví uživatel jako kupující zájem.
                    Dále uživatel
                    výslovně souhlasí s tím, aby tyto údaje byly poskytnuty provozovatelem v případě reklamace a
                    stížnosti na nedodané
                    zboží a v případech, kdy je provozovatel povinen tyto údaje poskytnout na základě zákona.</p>
                <p>Poskytnuté údaje je oprávněn uživatel užít pouze za účelem, pro který byly v jednotlivých případech
                    poskytnuty, tedy
                    k nákupu či prodeji zboží prostřednictvím serveru Valika, jeho reklamaci či stížnosti na nedodání
                    zboží, sporech
                    vyvolaných v souvislosti s nákupem a prodejem zboží a zavazuje se tyto údaje dále žádným způsobem
                    nevyužívat či
                    jinak zpracovávat.</p>

                <h2>X. SVOLENÍ K UŽITÍ FOTOGRAFIÍ</h2>
                <p>Vložením fotografií dává prodávající provozovateli práva a souhlas k užití fotografií vloženého zboží
                    k propagačním
                    účelům serveru Valika a to za účelem popularizace serveru Valika. Souhlasí také s tím, aby server
                    Valika postoupil
                    fotografie třetím osobám (například novinám, časopisům, internetovým serverům apod.) za účelem
                    popularizace serveru
                    Valika.</p>
                <p>Provozovatel vyvine úsilí aby každá, takto použitá fotografie nebo soubor fotografií, byl označen
                    logem Valika nebo
                    označením www.valika.org a to tak, aby bylo zřejmé, že se jedná o výrobek prodávaný na Valika.</p>
                <p>Provozovatel se zavazuje, že fotografie uživatelů nepoužije k jiným účelům, než je popularizace či
                    propagace serveru
                    a že ve stejném duchu bude jednat i s třetími stranami.</p>

                <h2>XI. ZÁVĚREČNÁ USTANOVENÍ</h2>
                <p>Provozovatel si vyhrazuje právo ukončit okamžitě tuto smlouvu s uživateli, kteří nedodržují pravidla
                    serveru Valika,
                    nejsou ochotni spolupracovat při řešení standardních či výjimečných situací s vedením serveru Valika
                    nebo nezaplatí
                    provizi za zprostředkování. Provozovatel si vyhrazuje právo ukončit okamžitě smlouvu s uživateli i
                    bez udání
                    důvodu.</p>
                <p>Smlouva uzavřená podle těchto obchodních podmínek se řídí právním řádem České republiky. Provozovatel
                    si vyhrazuje
                    právo na změnu poskytování kterékoliv ze služeb, jakož i těchto obchodních podmínek, a to bez
                    předchozího
                    upozornění.</p>
                <p>Jakékoliv napodobování grafického ztvárnění serveru Valika a vytvořených databází je zakázáno.</p>
                <p>Smlouvu je možno ukončit výpovědí, učiněnou formou odhlášení ze strany uživatele. Ta je ukončena
                    okamžikem projevení
                    vůle a doručením provozovateli.</p>
            </React.Fragment>
        )
    }
}

export default HelpTermsCz;
