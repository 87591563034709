import React, {Component} from 'react';
import LocalStorage from "../services/LocalStorage";
import HelpCookiesEn from "./HelpCookiesEn";
import HelpCookiesCz from "./HelpCookiesCz";
import HelpCookiesRu from "./HelpCookiesRu";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class HelpCookies extends Component {
    render = () => {
        const lang = LocalStorage.getOrDefault("language", null);
        return (
            <Row>
                <Col md={3}></Col>
                <Col md={9}>
                    {lang === "en" && <HelpCookiesEn/>}
                    {lang === "cz" && <HelpCookiesCz/>}
                    {lang === "ru" && <HelpCookiesRu/>}
                </Col>
            </Row>
        )
    }
}

export default HelpCookies;
