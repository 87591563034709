import RouteParams from "./RouteParams";
import LocalStorage from "./LocalStorage";
import UrlBuilder from "./UrlBuilder";
import LinkService from "./LinkService";

function getCatalogPath() {
    let category = RouteParams.catalogCategory();
    if (category === undefined) {
        return LinkService.getCatalog();
    } else {
        return LinkService.getCatalog() + "/" + category;
    }
}

const CatalogService = {
    toRootCategory: function () {
        let params = RouteParams.search();
        return UrlBuilder.build(LinkService.getCatalog(), params);
    },

    setRootCountry: function (root, country) {
        var params = RouteParams.search();
        if (country === undefined) {
            LocalStorage.delete("country");
            delete params.country;
        } else {
            params.country = country;
            LocalStorage.set("country", country);
        }

        return UrlBuilder.build(root, params);
    },

    setCountry: function (country) {
        return this.setRootCountry(getCatalogPath(), country);
    },

    setShop: function (shop) {
        let params = RouteParams.search();
        if (shop === undefined) {
            delete params.shop;
        } else {
            params.shop = shop;
        }
        return UrlBuilder.build(getCatalogPath(), params);
    },

    toCategory: function (categoryId) {
        let params = RouteParams.search();
        return UrlBuilder.build(LinkService.getCatalog() + "/" + categoryId, params);
    }
};

export default CatalogService;
