import React, {Component} from 'react'
import {FormattedMessage, injectIntl} from "react-intl"
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ValidationUtils from "../services/ValidationUtils";
import BusinessConditionsPopup from "./BusinessConditionsPopup";
import PersonalDataPolicyPopup from "./PersonalDataPolicyPopup";
import Alert from "react-bootstrap/Alert";
import UserService from "../services/UserService";
import LocalStorage from "../services/LocalStorage";

class Register extends Component {
    state = {
        email: "",
        username: "",
        password: "",
        agreement: false,
        plan: "",

        showProgress: false,
        alertType: null,
        alertMessage: null,
        showBusinessConditions: false,
        showPersonalDataPolicy: false
    };

    handleTextFieldChange = (event, field) => {
        this.setState({[field]: event.target.value});
    };

    isEmailValid = (email) => {
        return email && email.trim().length > 0 && ValidationUtils.validateEmail(email)
    };

    isUsernameValid = (username) => {
        return username && username.trim().length >= 3 && username.trim().length <= 20 && /^[0-9a-zA-Z_-]+$/.test(username);
    };

    isPasswordValid = (password) => {
        return password && password.trim().length >= 6 && password.trim().length <= 20 && /^[0-9a-zA-Z_-]+$/.test(password);
    };

    isPlanValid = (plan) => {
        return plan === "MEMBER" || plan === "VISITOR";
    };

    isAgreementValid = (agreement) => {
        return agreement === true;
    };

    isFormValid = (email, username, password, plan, agreement) => {
        return this.isEmailValid(email)
            && this.isPasswordValid(password)
            && this.isUsernameValid(username)
            && this.isPlanValid(plan)
            && this.isAgreementValid(agreement);
    };

    handleChangeAgreement = (event) => {
        this.setState({agreement: event.target.checked});
    };

    handlePlanChange = (plan) => {
        this.setState({plan: plan});
    };

    handleCloseAlert = () => {
        this.setState({alertMessage: null, alertType: null});
    };

    handleRegister = () => {
        const {formatMessage} = this.props.intl;
        this.setState({
            showProgress: true,
            alertType: null,
            alertMessage: null
        });

        UserService
            .register(this.state.username, this.state.email, this.state.password, this.state.plan, LocalStorage.getOrDefault("language", "en"))
            .then(data => {
                this.setState({
                    showProgress: false,
                    alertType: "success",
                    alertMessage: formatMessage({id: "login.registrationFinished"}),

                    email: "",
                    username: "",
                    password: "",
                    agreement: false,
                    plan: "",
                });
            })
            .catch((error) => {
                let message = formatMessage({id: "login.registrationFailed"});
                if (error.response) {
                    if (error.response.status === 401) {
                        message = formatMessage({id: "login.emailAlreadyDefined"})
                    } else if (error.response.status === 409) {
                        message = formatMessage({id: "login.nameAlreadyDefined"})
                    }

                }

                this.setState({
                    showProgress: false,
                    alertType: "danger",
                    alertMessage: message
                });
            });
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        const {email, username, password, plan, agreement} = this.state;
        return (
            <Form>
                <p className="lead"><FormattedMessage id="login.createYourAccount"/></p>

                <Form.Group controlId="formRegisterEmail">
                    <Form.Label className={!this.isEmailValid(email) ? "text-danger" : ""}><FormattedMessage id="login.email"/></Form.Label>
                    <Form.Control type="email"
                                  value={email}
                                  placeholder={formatMessage({id: "login.email"})}
                                  onChange={e => this.handleTextFieldChange(e, "email")}
                    />
                </Form.Group>

                <Form.Group controlId="formRegisterUsername">
                    <Form.Label className={!this.isUsernameValid(username) ? "text-danger" : ""}><FormattedMessage id="profile.authorName"/></Form.Label>
                    <Form.Control type="text"
                                  value={username}
                                  placeholder={formatMessage({id: "profile.authorName"})}
                                  onChange={e => this.handleTextFieldChange(e, "username")}
                    />
                    <Form.Text className="text-muted">
                        <FormattedMessage id="login.nameRulesTips"/>
                    </Form.Text>
                </Form.Group>

                <Form.Group controlId="formRegisterPassword">
                    <Form.Label className={!this.isPasswordValid(password) ? "text-danger" : ""}><FormattedMessage id="login.password"/></Form.Label>
                    <Form.Control type="password"
                                  value={password}
                                  placeholder={formatMessage({id: "login.password"})}
                                  onChange={e => this.handleTextFieldChange(e, "password")}
                    />
                    <Form.Text className="text-muted">
                        <FormattedMessage id="login.passwordRulesTips"/>
                    </Form.Text>
                </Form.Group>

                <Form.Group controlId="formRegisterType">
                    <Form.Label className={!this.isPlanValid(plan) ? "text-danger" : ""}><FormattedMessage id="login.selectPlan"/></Form.Label>
                    <Form.Check label={formatMessage({id: "login.plan.visitor"})}
                                type="radio"
                                id="plan-visitor"
                                name="plan"
                                onChange={() => this.handlePlanChange("VISITOR")}
                                checked={plan === "VISITOR"}
                    />
                    <Form.Text className="text-muted">
                        <FormattedMessage id="login.plan.visitor.desc"/>
                    </Form.Text>
                    <Form.Check label={formatMessage({id: "login.plan.member"})}
                                type="radio"
                                id="plan-member"
                                name="plan"
                                onChange={() => this.handlePlanChange("MEMBER")}
                                checked={plan === "MEMBER"}
                    />
                    <Form.Text className="text-muted">
                        <FormattedMessage id="login.plan.member.desc"/>
                    </Form.Text>
                </Form.Group>


                <Form.Group controlId="formRegisterAgreement">
                    <Form.Check type="checkbox" label={formatMessage({id: "login.agreement"})}
                                className={!this.isAgreementValid(agreement) ? "text-danger" : ""}
                                onChange={this.handleChangeAgreement}
                                checked={agreement}
                    />
                    <Form.Text>
                        <Button variant="link" onClick={() => this.setState({showBusinessConditions: true})}>
                            <FormattedMessage id="login.businessConditions"/>
                        </Button>
                        <Button variant="link" onClick={() => this.setState({showPersonalDataPolicy: true})}>
                            <FormattedMessage id="login.personalDataPolicy"/>
                        </Button>
                    </Form.Text>
                </Form.Group>


                <Alert dismissible variant={this.state.alertType} show={this.state.alertMessage !== null} onClose={this.handleCloseAlert}>
                    {this.state.alertMessage}
                </Alert>

                <Form.Group>
                    <Button variant="primary" type="button" size="lg" block
                            disabled={!this.isFormValid(email, username, password, plan, agreement) || this.state.showProgress}
                            onClick={this.state.showProgress ? null : this.handleRegister}
                    >
                        {this.state.showProgress ? formatMessage({id: "common.waitPlease"}) : formatMessage({id: "login.register"})}
                    </Button>
                </Form.Group>

                <BusinessConditionsPopup
                    show={this.state.showBusinessConditions}
                    onClose={() => this.setState({showBusinessConditions: false})}
                />
                <PersonalDataPolicyPopup
                    show={this.state.showPersonalDataPolicy}
                    onClose={() => this.setState({showPersonalDataPolicy: false})}
                />
            </Form>
        )
    }
}

export default injectIntl(Register);
