import React, {Component} from 'react';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {FormattedMessage} from "react-intl";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import LocalStorage from "../services/LocalStorage";
import PropTypes from 'prop-types'

class CurrencySelect extends Component {
    selectCurrency = (event, currency) => {
        if (currency.disabled) {
            return;
        }
        LocalStorage.set("currency", currency.id);
        setTimeout(() => {
            window.location.reload();
        }, 300);
    };

    renderButtonText = (activeCurrency) => {
        if (this.props.small) {
            return (
                <React.Fragment>
                    <i className="far fa-money-bill-alt fa-fw"/>&nbsp;
                    {activeCurrency.toUpperCase()}
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <i className="far fa-money-bill-alt fa-fw"/>&nbsp;<FormattedMessage
                    id={"currency." + activeCurrency}/>
                </React.Fragment>
            );
        }
    };

    render() {
        const activeCurrency = LocalStorage.getOrDefault("currency", "\"eur\"");
        const currencies = [{
            id: 'czk',
            disabled: activeCurrency === 'czk'
        }, {
            id: 'eur',
            disabled: activeCurrency === 'eur'
        }, {
            id: 'usd',
            disabled: activeCurrency === 'usd'
        }, {
            id: 'rub',
            disabled: activeCurrency === 'rub'
        }, {
            id: 'uah',
            disabled: activeCurrency === 'uah'
        }];

        let variant = this.props.small ? "link" : "outline-light";
        let className = this.props.small ? "nav-selector" : "";

        return (
            <DropdownButton as={ButtonGroup}
                            className={className}
                            variant={variant}
                            size="sm"
                            style={{marginLeft: 5, marginRight: 5}}
                            title={this.renderButtonText(activeCurrency)}>
                {
                    currencies.map(currency => {
                        return (
                            <Dropdown.Item
                                onClick={e => this.selectCurrency(e, currency)}
                                disabled={currency.disabled}
                                eventKey={currency.id}
                                key={currency.id}>
                                <FormattedMessage id={"currency." + currency.id}/>
                            </Dropdown.Item>
                        )
                    })
                }
            </DropdownButton>
        )
    }
}

CurrencySelect.propTypes = {
    small: PropTypes.bool.isRequired
};

export default CurrencySelect;
