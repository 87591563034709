import React, {Component} from 'react'
import PropTypes from 'prop-types'
import LinkService from "../services/LinkService";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";
import ImageService from "../services/ImageService";

class SinglePhotoTemplate extends Component {
    state = {
        imageError: false,
    };

    handleImageLoad = (event) => {
        ImageService.calculateTileSize(event.target);
    };

    imageFallback = () => {
        this.setState({imageError: true});
    };

    render = () => {
        return (
            <LinkContainer to={LinkService.getProduct(this.props.product.url)}>
                <a href={LinkService.getProduct(this.props.product.url)}
                   className={"float-left card template-image-wrapper tile-card-image-frame " + this.props.classes}>
                    <img onLoad={this.handleImageLoad}
                         src={!this.state.imageError ? this.props.product.catalogImage : LinkService.getNoCatalogImage()}
                         className="hvr-fade card-img-top"
                         alt={this.props.product.title}
                         onError={this.imageFallback}
                    />
                </a>
            </LinkContainer>
        )
    }
}

SinglePhotoTemplate.propTypes = {
    product: PropTypes.object.isRequired,
    classes: PropTypes.string.isRequired
};

export default SinglePhotoTemplate;
