import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ProductService from "../services/ProductService";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import GridProductList from "./GridProductList";
import LocalStorage from "../services/LocalStorage";

class Home extends Component {
    loadProducts = () => {
        let country = LocalStorage.get("country");
        if (country === null) {
            country = undefined;
        }

        return ProductService.list(0, 24, "rand", undefined, country, undefined);
    };

    render = () => {
        return (
            <Row>
                <Col md={12}>
                    <GridProductList loadProducts={this.loadProducts}/>
                </Col>
            </Row>
        )
    }
}

Home.propTypes = {
    user: PropTypes.object,
    onLogin: PropTypes.func.isRequired,
    onAddedToWishlist: PropTypes.func.isRequired,
    onRemovedFromWishlist: PropTypes.func.isRequired,
};

export default Home;
