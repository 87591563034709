import axios from "axios";
import ReturnPromise from "./ReturnPromise";

const FiltersService = {
    // listShops: function (country, category) {
    //     return ReturnPromise.get(
    //         axios
    //             .get("/api/v1/filters/shops", {
    //                 params: {
    //                     country: country,
    //                     category: category
    //                 }
    //             }));
    // },

    listCountries: function (shop, category) {
        return ReturnPromise.get(
            axios
                .get("/api/v1/filters/countries", {
                    params: {
                        shop: shop,
                        category: category
                    }
                }));
    },
    listCategories: function (lang, country, shop, category) {
        return ReturnPromise.get(
            axios
                .get("/api/v1/filters/categories", {
                    params: {
                        lang: lang,
                        shop: shop,
                        country: country,
                        category: category
                    }
                }));
    },
    loadCategoryDescription: function (lang, category) {
        return ReturnPromise.get(
            axios
                .get("/api/v1/filters/active-category", {
                    params: {
                        lang: lang,
                        category: category
                    }
                }));
    }
};

export default FiltersService;
