import React, {Component} from 'react';
import PropTypes from 'prop-types';

class InfiniteScroll extends Component {
    onBottom = () => {
        const distance = (this.props.distance || 0) + 1;
        if (((window.innerHeight * distance + window.pageYOffset) >= document.body.offsetHeight - 2) && !this.props.disabled) {
            this.props.onBottom();
        }
    };

    componentDidMount = () => {
        window.addEventListener("scroll", this.onBottom);
    };

    componentWillUnmount = () => {
        window.removeEventListener("scroll", this.onBottom);
    };

    render = () => {
        return <React.Fragment></React.Fragment>;
    }
}

InfiniteScroll.propTypes = {
    onBottom: PropTypes.func.isRequired,
    distance: PropTypes.number,
    disabled: PropTypes.bool
};

export default InfiniteScroll;
