import React, {Component} from 'react';

class HelpCookiesEn extends Component {
    render() {
        return (
            <React.Fragment>
                <h1>Obecné informace</h1>
                <p>Cookies jsou krátké textové soubory, které si internetové stránky ukládají na pevný disk počítače
                    nebo mobilního
                    zařízení uživatele. Často jde o nastavení, které uživateli usnadní používání stránek. Některé funkce
                    není možné bez
                    cookies využívat.</p>
                <p><strong>Na valika.org nepoužíváme cookies za účelem osobní identifikace uživatelů.</strong></p>
                <p>Ukládáme následující druhy cookies:</p>
                <ul>
                    <li>Technické cookies, které jsou nezbytné pro zajištění základní funkčnosti stránek (identifikátor
                        session).
                    </li>
                    <li>Cookies třetích stran určené pro měření anonymních dat o chování uživatelů a sledování
                        návštěvnosti pomocí
                        Google Analytics za účelem lepšího přizpůsobení stránek pro uživatele.
                    </li>
                </ul>

                <h1>Nastavení cookies</h1>
                <ul>
                    <li>Pravidla pro používání nebo zablokování cookies si může každý uživatel nastavit ve svém
                        internetovém
                        prohlížeči.
                    </li>
                    <li>Uživatel si může nastavit povolení nebo odmítnutí veškerých nebo pouze některých souborů
                        cookies. Zablokování
                        souborů cookies bude mít negativní vliv na použitelnost webové stránky a služby valika.org.
                    </li>
                    <li>Uživatel může svou volbu ohledně nastavení cookies kdykoliv změnit. Uživatel může již uložené
                        soubory cookies
                        vymazat ve svém internetovém prohlížeči.
                    </li>
                    <li>Detailní informace o nastavení ukládání souborů cookies v prohlížeči lze nalézt na stránkách
                        poskytovatele
                        konkrétního internetového prohlížeče
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}

export default HelpCookiesEn;
