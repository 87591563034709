import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {injectIntl} from "react-intl"

class Rating extends Component {
    state = {
        selected: 0,
        hovered: 0
    };

    select = (i) => {
        this.setState({selected: i}, () => {
            this.props.onSelected(i);
        });
    };

    renderStar = (i) => {
        const {disabled} = this.props;
        const {hovered, selected} = this.state;
        const {formatMessage} = this.props.intl;
        const position = (hovered > 0 && !disabled) ? hovered : selected;
        const color = disabled ? "text-muted" : "text-warning clickable"
        const starClass = (i <= position) ? 'fas' : 'far';
        return (
            <i className={"fa-2x fa-star " + starClass + " " + color}
               title={formatMessage({id: "product.rating." + i})}
               style={{paddingLeft: 5, paddingRight: 5}}
               key={i}
               onMouseLeave={() => this.setState({hovered: 0})}
               onMouseEnter={() => this.setState({hovered: i})}
               onClick={() => disabled ? null : this.select(i)}
            />
        )
    };

    render = () => {
        return (
            <React.Fragment>
                <p className="lead text-center">
                    {
                        [1, 2, 3, 4, 5].map((i) => {
                            return this.renderStar(i);
                        })
                    }
                </p>
            </React.Fragment>
        )
    }
}

Rating.propTypes = {
    onSelected: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
};

export default injectIntl(Rating);
