import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Modal from "react-bootstrap/Modal";
import {FormattedMessage} from "react-intl";
import LocalStorage from "../services/LocalStorage";
import HelpGDPREn from "../help/HelpGDPREn";
import HelpGDPRCz from "../help/HelpGDPRCz";
import HelpGDPRRu from "../help/HelpGDPRRu";

class PersonalDataPolicyPopup extends Component {
    render = () => {
        const lang = LocalStorage.getOrDefault("language", null);
        return (
            <Modal show={this.props.show} onHide={this.props.onClose} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><FormattedMessage id="login.personalDataPolicy"/></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {lang === "en" && <HelpGDPREn/>}
                    {lang === "cz" && <HelpGDPRCz/>}
                    {lang === "ru" && <HelpGDPRRu/>}
                </Modal.Body>
            </Modal>
        )
    }
}

PersonalDataPolicyPopup.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default PersonalDataPolicyPopup;
