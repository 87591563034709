import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import LocalStorage from "../services/LocalStorage";
import Modal from "react-bootstrap/Modal";
import HelpTermsEn from "../help/HelpTermsEn";
import HelpTermsCz from "../help/HelpTermsCz";
import HelpTermsRu from "../help/HelpTermsRu";

class BusinessConditionsPopup extends Component {
    render = () => {
        const lang = LocalStorage.getOrDefault("language", null);
        return (
            <Modal show={this.props.show} onHide={this.props.onClose} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><FormattedMessage id="login.businessConditions"/></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {lang === "en" && <HelpTermsEn/>}
                    {lang === "cz" && <HelpTermsCz/>}
                    {lang === "ru" && <HelpTermsRu/>}
                </Modal.Body>
            </Modal>
        )
    }
}

BusinessConditionsPopup.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default BusinessConditionsPopup;
