import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from "react-intl";
import FilterList from "./FilterList";
import RouteParams from "../services/RouteParams";
import FiltersService from "../services/FiltersService";
import CatalogService from "../services/CatalogService";
import {withRouter} from "react-router-dom";

class CountriesFilter extends Component {
    state = {
        countries: null,
        lastUrl: null
    };

    loadFilters = () => {
        this.setState({shops: null});
        const {formatMessage} = this.props.intl;
        const params = RouteParams.search();
        let category = RouteParams.catalogCategory();
        const itself = this;
        return FiltersService
            .listCountries(params.shop, category)
            .then(function (result) {
                const activeCountries = (params.country || "").toLowerCase().split(',');

                result.items.forEach(function (item) {
                    item.checked = activeCountries.indexOf(item.id) >= 0;
                    item.title = formatMessage({id: 'country.' + item.id});
                });

                itself.setState({countries: result.items});
            });
    };

    componentWillMount = () => {
        this.updateUrlAndLoadFilters(this.props);

        // this.setState({
        //     lastUrl: this.props.location.pathname + this.props.location.search
        // }, this.loadFilters);
    };

    componentWillReceiveProps = (nextProps) => {
        this.updateUrlAndLoadFilters(nextProps);
        // const params = nextProps.location.pathname + nextProps.location.search;
        // if (this.state.lastUrl !== params) {
        //     // if (this.state.lastUrl !== null) {
        //         this.loadFilters();
        //     // }
        //     this.setState({lastUrl: params});
        // }
    };

    updateUrlAndLoadFilters = (props) => {
        const params = props.location.pathname + props.location.search;
        if (this.state.lastUrl !== params) {
            this.setState({lastUrl: params}, this.loadFilters);
        }
    };

    applyFilter = (countries) => {
        let selectedCountries = Array.from(countries);
        var country = selectedCountries.join(",");
        if (country.length === 0) {
            country = undefined;
        }

        let url = CatalogService.setCountry(country);
        this.props.history.push(url);
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        return (
            <FilterList title={formatMessage({id: "common.countryFilter"})}
                        applyFilter={this.applyFilter}
                        items={this.state.countries}
            />
        )
    }
}

CountriesFilter.propTypes = {
    location: PropTypes.object.isRequired
};

export default withRouter(injectIntl(CountriesFilter));
