import React, {Component} from 'react'
import FooterLinks from "./FooterLinks"
import {FormattedMessage} from "react-intl"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import CurrencySelect from "./CurrencySelect"
import LanguageSelect from "./LanguageSelect"
import PropTypes from 'prop-types'

class Footer extends Component {
    render = () => {
        const shopEmail = "valika.shop(at)gmail.com";
        const currentYear = new Date().getFullYear();
        return (
            <footer className="bg-secondary footer s">
                <Container>
                    <Row noGutters={true} className="justify-content-between">
                        <FooterLinks/>
                        <div>
                            <CurrencySelect small={false}/>
                            <LanguageSelect user={this.props.user} small={false}/>
                        </div>
                    </Row>
                    <Row noGutters={true} className="justify-content-center copyright text-light">
                        &copy;
                        <span>2011-{currentYear}</span>,&nbsp;
                        <strong><FormattedMessage id="common.econorCompany"/></strong>,&nbsp;
                        <span><FormattedMessage id="common.pragueCz"/></span>&nbsp;|&nbsp;
                        <span>
                            <FormattedMessage id="login.email"/>:&nbsp;
                            <a href="mailto:{shopEmail}" className="text-light">{shopEmail}</a>
                        </span>
                    </Row>
                </Container>
            </footer>
        )
    }
}

Footer.propTypes = {
    user: PropTypes.object,
};

export default Footer;
