import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Card from "react-bootstrap/Card";

class Panel extends Component {
    render = () => {
        return (
            <Card style={{marginBottom: 30}} className="panel">
                <Card.Header>{this.props.title}</Card.Header>
                <Card.Body>
                    {this.props.children}
                </Card.Body>
            </Card>
        )
    }
}

Panel.propTypes = {
    title: PropTypes.string.isRequired
};

export default Panel;
