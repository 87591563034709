import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import Modal from 'react-bootstrap/Modal'
import NewMessage from "../messages/NewMessage";

class SendMessage extends Component {
    handleMessageSent = () => {
        setTimeout(() => {
            this.props.onClose();
        }, 3000);
    };

    render = () => {
        return (
            <Modal show={this.props.show} onHide={this.props.onClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title><FormattedMessage id="product.askQuestion"/></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NewMessage to={this.props.userId}
                                body={this.props.body}
                                onMessageSent={this.handleMessageSent}
                                from={this.props.from}
                    />
                </Modal.Body>
            </Modal>
        )
    }
}

SendMessage.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    body: PropTypes.string,
    from: PropTypes.string
};

export default SendMessage;
