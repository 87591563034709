import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Nav from "react-bootstrap/Nav";
import LinkService from "../services/LinkService";
import {FormattedMessage} from "react-intl";
import Badge from "react-bootstrap/Badge";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";

class WishlistNavLink extends Component {
    title = () => {
        return (
            <React.Fragment>
                <i className="fa fa-heart"></i>&nbsp;
                <FormattedMessage id="topNavi.wishlist"/>
            </React.Fragment>
        );
    };

    render = () => {
        const {user} = this.props;
        return (
            <React.Fragment>
                {user &&
                <LinkContainer to={LinkService.getWishlist()}>
                    <Nav.Link active={window.location.pathname.startsWith(LinkService.getWishlist())}>
                        {this.title()}
                        {user.wishlistCount > 0 &&
                        <React.Fragment>
                            <span>&nbsp;</span>
                            <Badge pill variant="secondary">
                                {user.wishlistCount}
                            </Badge>
                        </React.Fragment>
                        }
                    </Nav.Link>
                </LinkContainer>
                }
                {!user &&
                <Nav.Link active={window.location.pathname.startsWith(LinkService.getWishlist())} onClick={this.props.onLogin}>
                    {this.title()}
                </Nav.Link>
                }

            </React.Fragment>
        )
    }
}

WishlistNavLink.propTypes = {
    user: PropTypes.object,
    onLogin: PropTypes.func.isRequired
};

export default WishlistNavLink;
