import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import ContentLoader from "react-content-loader";
import RouteParams from "../services/RouteParams";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";
import LinkService from "../services/LinkService";
import UserService from "../services/UserService";

class Activate extends Component {
    state = {
        loading: true,
        activated: false
    };

    componentWillMount = () => {
        let itself = this;
        let search = RouteParams.search();
        console.log(this.props)
        UserService
            .activate(search.u, search.c)
            .then(data => {
                itself.setState({
                    loading: false,
                    activated: true
                });
                itself.props.handleLogin(data.accessToken, data.expires);
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    activated: false
                });
            });
    };

    renderActivated = () => {
        return (
            <div className="jumbotron bg-transparent">
                <Row className="justify-content-md-center">
                    <Col md="auto">
                        <p style={{textAlign: "center"}}>
                            <i className="fas fa-5x fa-thumbs-up text-success"/>
                            <h1 className="display-4">
                                <FormattedMessage id="login.accountActivation"/>
                            </h1>
                            <p className="lead">
                                <FormattedMessage id="login.activationFinished"/>
                            </p>
                            <ButtonToolbar className="justify-content-center">
                                <LinkContainer to={LinkService.getHome()}>
                                    <Button variant="link" size="lg">
                                        <FormattedMessage id="footer.home"/>
                                    </Button>
                                </LinkContainer>
                                <LinkContainer to={LinkService.getProfile()}>
                                    <Button variant="link" size="lg">
                                        <FormattedMessage id="topNavi.profile"/>
                                    </Button>
                                </LinkContainer>
                            </ButtonToolbar>
                        </p>
                    </Col>
                </Row>
            </div>
        )
    };

    renderFailed = () => {
        return (
            <div className="jumbotron bg-transparent">
                <Row className="justify-content-md-center">
                    <Col md="auto">
                        <p style={{textAlign: "center"}}>
                            <i className="fas fa-5x fa-exclamation-triangle text-danger"/>
                            <h1 className="display-4">
                                <FormattedMessage id="login.accountActivation"/>
                            </h1>
                            <p className="lead">
                                <FormattedMessage id="login.activationFailed"/>
                            </p>
                        </p>
                    </Col>
                </Row>
            </div>
        )
    };

    render = () => {
        return (
            <React.Fragment>
                {this.state.loading && <ContentLoader/>}
                {!this.state.loading && this.state.activated && this.renderActivated()}
                {!this.state.loading && !this.state.activated && this.renderFailed()}
            </React.Fragment>
        )
    }
}

Activate.propTypes = {
    handleLogin: PropTypes.func.isRequired
};

export default Activate;
