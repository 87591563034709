import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedDate, FormattedMessage} from "react-intl"
import LinkService from "../services/LinkService";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";
import MessageService from "../services/MessageService";
import Table from 'react-bootstrap/Table'
import ContentLoader from "react-content-loader";
import classNames from 'classnames'
import Card from 'react-bootstrap/Card'
import NewMessage from "./NewMessage";

class MessageThread extends Component {
    state = {
        senderName: null,
        messages: null
    };

    handleMessageSent = (message) => {
        let messages = this.state.messages || [];
        messages.push(message);
        this.setState({
            messages: messages
        }, () => {
            this.scrollToBottom();
        });

    };

    containsUnreadMessages = (messages, senderId) => {
        let result = false;
        messages.forEach(function (message) {
            if (message.userId === senderId && message.unread) {
                result = true;
            }
        });
        return result;
    };

    renderLoader = (i) => {
        const height = 120;
        return (
            <ContentLoader width={825}
                           height={height} key={i}
                           preserveAspectRatio={"none"}
                           style={{width: '100%', height: height, marginBottom: 5}}>
            </ContentLoader>
        )
    };

    renderMessage = (message) => {
        const {userId} = this.props;
        return (
            <tr key={message.id} className={classNames({"bg-light": (message.userId === userId && message.unread)})}>
                <td>
                    <img className="rounded messages-avatar" alt={message.name} src={message.avatar}/>
                </td>
                <td>
                    <strong>{message.name}</strong><br/>
                    <small className="text-muted">
                        <FormattedDate value={message.created} year="numeric" month="numeric" day="numeric"/>
                    </small>
                    {message.userId !== userId &&
                    <p>
                        {message.unread && <i className="fa fa-paper-plane text-primary fa-lg" aria-hidden="true"></i>}
                        {!message.unread && <i className="fa fa-check-circle text-success fa-lg" aria-hidden="true"></i>}
                    </p>
                    }

                </td>
                <td>
                    {message.body.split("\n").map((value, index) => {
                        return <React.Fragment key={index}>{value}<br/></React.Fragment>
                    })}
                </td>
                <td className="messages-unread">
                    {message.unreadCount > 0 &&
                    <span ng-show="dialog.unreadCount>0" className="badge badge-danger badge-pill float-right">{message.unreadCount}</span>
                    }
                </td>
            </tr>
        )
    };

    scrollToBottom = () => {
        let body = document.body, html = document.documentElement;
        let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        window.scroll(0, height);
    };

    componentWillMount = () => {
        MessageService
            .loadMessageThread(this.props.userId)
            .then(response => {
                this.setState({
                    senderName: response.senderName,
                    messages: response.items
                }, () => {
                    this.scrollToBottom();

                    if (this.containsUnreadMessages(this.state.messages, this.props.userId)) {
                        MessageService
                            .markMessageThreadAsRead(this.props.userId)
                            .then(() => {
                                this.props.onUpdateUserDetails()
                            });
                    }
                });
            })
    };

    render = () => {
        const {senderName, messages} = this.state;
        return (
            <React.Fragment>
                <h1>
                    <small>
                        <small>
                            <LinkContainer to={LinkService.getMessages()}>
                                <a href><FormattedMessage id="topNavi.messages"/></a>
                            </LinkContainer>
                            <span> / </span>
                        </small>
                    </small>
                    {senderName === null && <span>...</span>}
                    {senderName !== null && senderName}
                </h1>
                {messages === null && [1, 2, 3, 4, 5].map(i => {
                    return this.renderLoader(i)
                })}
                {messages !== null && messages.length > 0 &&
                <Table>
                    <tbody>
                    {messages.map(message => {
                        return (this.renderMessage(message))
                    })}
                    </tbody>
                </Table>
                }
                <Card className="message-sticky">
                    <Card.Body>
                        <NewMessage to={this.props.userId} onMessageSent={this.handleMessageSent}/>
                    </Card.Body>
                </Card>
            </React.Fragment>
        )
    }
}

MessageThread.propTypes = {
    userId: PropTypes.string.isRequired,
    onUpdateUserDetails: PropTypes.func.isRequired
};

export default MessageThread;
