import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Form from 'react-bootstrap/Form'

class UploadImageDialog extends Component {
    state = {
        imageFile: null,

        showError: false,
        inProgress: false
    };

    isValid = (imageFile) => {
        return imageFile !== null;
    };

    handleUploadFile = (event) => {
        this.setState({imageFile: event.target.files[0]});
    };

    doUpload = () => {
        this.setState({
            inProgress: true,
            showError: false
        });
        this.props
            .uploadFn(this.state.imageFile)
            .then(data => {
                this.props.onImageUpload(data);
                this.onClose();
            })
            .catch(error => {
                this.setState({
                    inProgress: false,
                    showError: true
                });
            });
    };

    clearUploader = () => {
        this.setState({
            inProgress: false,
            showError: false,
            imageFile: null
        });
        document.getElementById('uploadFile').value = "";
    };

    onClose = () => {
        this.clearUploader();
        this.props.onClose();
    };

    render = () => {
        const {inProgress} = this.state;
        const {formatMessage} = this.props.intl;

        return (
            <Modal show={this.props.show} onHide={this.onClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title><FormattedMessage id="profile.upload"/></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert dismissible variant="danger" show={this.state.showError} onClose={() => this.setState({showError: false})}>
                        <FormattedMessage id="profile.changesNotSaved"/>
                    </Alert>
                    <Form>
                        <Form.Group controlId="uploadFile">
                            <Form.Label>
                                <FormattedMessage id="profile.uploadImagesToSite"/>&nbsp;
                                <FormattedMessage id="profile.useFilesNoLargerThan"/>
                            </Form.Label>
                            <Form.Control type="file"
                                          className="image-uploader-area bg-secondary"
                                          onChange={e => this.handleUploadFile(e)}
                                          disabled={inProgress}
                            />
                            <Form.Text className="text-muted">
                                <FormattedMessage id="profile.selectImageToUpload"/>
                            </Form.Text>
                        </Form.Group>
                    </Form>


                    {inProgress && <ProgressBar animated now={100}/>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary"
                            disabled={!this.isValid(this.state.imageFile) || inProgress}
                            onClick={this.doUpload}>
                        {!inProgress ? formatMessage({id: "profile.upload"}) : formatMessage({id: "common.waitPlease"})}
                    </Button>
                    <Button variant="secondary" onClick={this.onClose}>
                        <FormattedMessage id="common.cancel"/>
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

UploadImageDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onImageUpload: PropTypes.func.isRequired,
    uploadFn: PropTypes.func.isRequired
};

export default injectIntl(UploadImageDialog);
