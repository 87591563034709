import React, {Component} from 'react';

class HelpGDPREn extends Component {
    render() {
        return (
            <React.Fragment>
                <h1>Personal data policy for Valika users</h1>

                <h2>General conditions</h2>
                <p>Valika places great emphasis on personal data protection. Right from the beginning, we protect the
                    data of our users
                    as best we can. Sensitive data (such as the login password) is encrypted, we never send the contact
                    information
                    until the order has being created, or the law does not impose it.</p>
                <p>The purpose of this document is to inform you of the scope and manner of storing your personal data,
                    your rights and
                    the form in which you can exercise your rights.</p>

                <h2>Managed personal information</h2>
                <p>On Valika we store and manage the personal information of our users. The scope of the data differs
                    based on the role
                    of the user. It depends on whether you are a buyer or a seller, and whether you are registered or
                    not.</p>

                <h3>Registered user at Valika.org</h3>
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th>Entry</th>
                        <th>Purpose / Description</th>
                        <th>Optional</th>
                        <th>Public</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>E-mail address</td>
                        <td>Basic unique account identifier</td>
                        <td>NO</td>
                        <td>NO</td>
                    </tr>
                    <tr>
                        <td>Login password</td>
                        <td>Saved in encrypted form</td>
                        <td>NO</td>
                        <td>NO</td>
                    </tr>
                    <tr>
                        <td>IP address of registration</td>
                        <td>The address from which the account was created</td>
                        <td>NO</td>
                        <td>NO</td>
                    </tr>
                    <tr>
                        <td>Information about bank account</td>
                        <td>It will be used to generate the invoice to the customer and will be displayed to the
                            customer only after his
                            purchase request
                        </td>
                        <td>YES</td>
                        <td>NO</td>
                    </tr>
                    <tr>
                        <td>Residence (State and City)</td>
                        <td>The information will be displayed on the Valika.org</td>
                        <td>YES</td>
                        <td>YES</td>
                    </tr>
                    <tr>
                        <td>ordering details, orders, amounts paid, payments made, etc.</td>
                        <td></td>
                        <td>NO</td>
                        <td>NO</td>
                    </tr>
                    </tbody>
                </table>


                <h3>Buyer</h3>
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th>Entry</th>
                        <th>Optional</th>
                        <th>Public</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Email address</td>
                        <td>NO</td>
                        <td>NO</td>
                    </tr>
                    <tr>
                        <td>Name and surname</td>
                        <td>NO</td>
                        <td>NO</td>
                    </tr>
                    <tr>
                        <td>Delivery address</td>
                        <td>NO</td>
                        <td>NO</td>
                    </tr>
                    <tr>
                        <td>ordering details, orders, amounts paid, payments made, etc.</td>
                        <td>NO</td>
                        <td>NO</td>
                    </tr>
                    </tbody>
                </table>

                <h2>Purpose and method of processing personal data</h2>
                <p>We process your personal data in several different ways for next purposes:</p>

                <h3>Purchase and sale of goods and services at Valika.org</h3>
                <p>In order to mediate your order, we need to know and further process your personal information. We
                    process these data
                    solely for the purpose of mediating the conclusion of the purchase contract and the legal basis for
                    such processing
                    is the fulfillment of the purchase contract.</p>
                <p>We collect your personal data from you solely on the basis of your registration at Valika.org or by
                    filling in your
                    details on a shopping cart website.</p>
                <p>The processing time of these data is for the duration of the order, plus a period of at least 10
                    years, as the tax
                    law so requires.</p>

                <h3>Marketing comunication</h3>
                <p>If you give us your consent, we will use your personal information (e-mail address) to send news
                    about products,
                    merchandise, promotions and news at Valice. Your email address will be never forwarded to third
                    parties and you may
                    revoke your consent to the marketing communications at any time by clicking the link provided in
                    each email or in
                    your account.</p>

                <h3>Email support</h3>
                <p>In case you contact us through one of our contact emails or a contact form, we may ask you to provide
                    unambiguous
                    identification information to help verify your eligibility. We have to archive our email
                    communication. The legal
                    basis for such processing is our legitimate interest.</p>

                <h3>Cookies</h3>
                <p>When you use our website, your personal data can be processed through cookies. Learn more about how
                    we use cookies <a
                        href="/help/cookies">here</a>.</p>

                <h2>Your rights as a data subject or what to do when you want to delete or edit your personal
                    information</h2>
                <p>If you wish to exercise the following rights and / or obtain the relevant information, you can do so
                    by logging in to
                    your customer account on Valika.org. If you do not have an account with us and have made a purchase
                    without
                    registration, but would still like to exercise one of the following rights to protect your privacy,
                    you can contact
                    us via e-mail info@valika.org.</p>
                <p>To sign in to your account, you need your login (username / email and password). If you exercise your
                    rights in a
                    different way, we may ask you to provide unambiguous identification information that will serve to
                    verify the
                    legitimacy of your request. We will respond within 1 month after receiving your request, but we
                    reserve the right to
                    extend this period according to the technical and time requirements of your request.</p>
                <p>In accordance with applicable law, you have the right to request access to our personal data that we
                    process as a
                    personal data administrator, the right to repair or delete it. You may also, at any time, withdraw
                    consent to the
                    processing of certain personal data (such as marketing data) that you have provided us with.</p>
                <p>So what are your rights?</p>

                <h3>Deleting your personal information</h3>
                <p>Whenever you can ask us to delete your personal information. If you contact us with such a request,
                    we will delete
                    all your personal data without delay, unless we, however, need your personal data to fulfill
                    contractual and legal
                    obligations or to protect our legitimate interests as described above.</p>

                <h4>Canceling a buyer's account</h4>
                <ol>
                    <li>If the buyer placed an order, we are required to store his order data for at least 10 years
                        after the account
                        was canceled.
                    </li>
                    <li>If the purchaser has not made any order, his or her data may be deleted at his / her request,
                        except for data
                        relating to the purposes of ensuring system and network security (IP address).
                    </li>
                </ol>

                <h4>Canceling the Seller's Account</h4>
                <ol>
                    <li>If the seller has executed a mediated order, we have to store his order data for at least 10
                        years after the
                        account is canceled.
                    </li>
                    <li>If the seller has not accepted an order, his or her data may be deleted at his / her request,
                        except for data
                        relating to the purposes of ensuring system and network security (IP address).
                    </li>
                </ol>

                <h3>Fix your personal information</h3>
                <p>You have the right to correct your personal information. If you have an account on valika.org, you
                    can edit this
                    information directly after logging in to your account. Some data can not be corrected directly (for
                    example, invoice
                    information), then please contact us via email or contact form.</p>

                <h3>Revocation of consent</h3>
                <p>You may at any time revoke your consent to process certain personal data (e.g., data for marketing
                    purposes) without
                    giving any reason. Please note that the revocation of your consent does not affect the legality of
                    any processing
                    performed on the basis of previously granted consent.</p>

                <h3>Your other rights</h3>
                <p>Please note that under the General Data Protection Regulation, you have the right, in addition to the
                    next:</p>
                <ul>
                    <li>ask us for information about how we process your personal information</li>
                    <li>request an explanation for us regarding the processing of personal data</li>
                    <li>request us access to these data</li>
                </ul>
            </React.Fragment>
        )
    }
}

export default HelpGDPREn;
