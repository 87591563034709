import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import i18n from './i18n';
import {addLocaleData, IntlProvider} from 'react-intl';
import en from 'react-intl/locale-data/en';
import ru from 'react-intl/locale-data/ru';
import cz from 'react-intl/locale-data/cs';
import BrowserRouter from "react-router-dom/es/BrowserRouter";
import LocalStorage from "./services/LocalStorage"
import PriceService from "./services/PriceService";
import axios from "axios";
import Cookies from "./services/Cookies";
import ScrollToTop from "./shell/ScrollToTop";
import LinkService from "./services/LinkService";

function getBrowserLang() {
    const defaultLang = 'en';
    const langs = ['en', 'ru', 'cz'];
    var language = window.navigator.userLanguage || window.navigator.language;
    if (typeof (language) === 'string' && language != null) {
        language = language.substring(0, 2).toLowerCase();
        if (langs.indexOf(language) <= -1) {
            language = defaultLang;
        }

    } else language = defaultLang;

    return language;
}

function getLang() {
    if (LocalStorage.getOrDefault("language", 1) === 1) {
        LocalStorage.set("language", getBrowserLang());
    }
    return LocalStorage.getOrDefault("language", "en");
}

function checkCurrency() {
    if (LocalStorage.getOrDefault("currency", 1) === 1) {
        LocalStorage.set("currency", "eur");
    }
}

function configAxios() {
    axios.defaults.baseURL = process.env.NODE_ENV === "development" ? "http://localhost:8085" : "";
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.withCredentials = true;
    axios.interceptors.request.use(request => {

        let xAuthToken = Cookies.get("X-Auth-Token");
        if (xAuthToken) {
            request.headers["X-Auth-Token"] = xAuthToken;
        }

        return request;
    });
}

function prefetchProgressImage() {
    let noCatalogImage = new Image();
    noCatalogImage.src = LinkService.getNoCatalogImage();
}

addLocaleData([...en, ...cz, ...ru]);

const lang = getLang();
const localeLang = lang === "cz" ? "cs" : lang;

configAxios();
checkCurrency();
prefetchProgressImage();
PriceService.loadCurrencyRates();

ReactDOM.render(
    <IntlProvider locale={localeLang} messages={i18n[lang]}>
        <BrowserRouter>
            <ScrollToTop>
                <App/>
            </ScrollToTop>
        </BrowserRouter>
    </IntlProvider>

    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
