import axios from "axios";
import ReturnPromise from "./ReturnPromise";

const WISHLIST_API = "/api/v1/wishlists/mine";

const WishlistService = {
    check: function (productId) {
        return ReturnPromise.get(axios.head(`${WISHLIST_API}/${productId}`));
    },

    addToWishlist: function (productId) {
        return ReturnPromise.get(axios.post(`${WISHLIST_API}/${productId}`, {}));
    },

    deleteFromWishlist: function (productId) {
        return ReturnPromise.get(axios.delete(`${WISHLIST_API}/${productId}`));
    },

    list: function (offset, limit) {
        return ReturnPromise.get(axios.get(WISHLIST_API, {params: {offset: offset, limit: limit}}));
    },

};

export default WishlistService;
