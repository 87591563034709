import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ProductService from "../services/ProductService";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ProductCardPlaceholder from "../catalog/ProductCardPlaceholder";
import LoadingIndicator from "../catalog/LoadingIndicator";
import InfiniteScroll from "../shell/InfiniteScroll";
import Template1 from "./Template1";
import Template2 from "./Template2";
import Template3 from "./Template3";
import Template4 from "./Template4";
import Template5 from "./Template5";
import Template6 from "./Template6";
import Template7 from "./Template7";
import Template8 from "./Template8";
import Template9 from "./Template9";

class GridProductList extends Component {
    TEMPLATES = {
        1: 4,
        2: 2,
        3: 5,
        4: 5,
        5: 4,
        6: 3,
        7: 5,
        8: 4,
        9: 5
    };

    TEMPLATES_COUNT = Object.keys(this.TEMPLATES).length;
    TEMPLATES_MIN = Math.min(...Object.values(this.TEMPLATES));

    state = {
        unprocessed: [],
        products: null,
        lastUrl: null,
        // total: null,
        nextUrl: null,
        scrollEnable: false,
        loading: false,

        showPopup: false,
        popupTitle: "",
        popupMessage: "",
        onPopupConfirm: () => {
        }
    };

    getTemplateNum = (unprocessed) => {
        if (unprocessed.length < this.TEMPLATES_MIN) {
            return undefined;
        }

        let templateNum = 0;
        while (templateNum === 0 || templateNum === undefined) {
            let rand = Math.floor(Math.random() * this.TEMPLATES_COUNT) + 1
            if (unprocessed.length >= this.TEMPLATES[rand]) {
                templateNum = rand;
            }
        }
        return templateNum;
    };

    doLoadProductsList = () => {
        if (this.state.nextUrl === null || this.state.products === null) {
            return this.props.loadProducts();
        } else {
            return ProductService.findByUrl(this.state.nextUrl);
        }
    };

    loadProductsList = () => {
        let itself = this;
        this.setState({loading: true});

        this
            .doLoadProductsList()
            .then(function (result) {
                let unprocessed = itself.state.unprocessed;
                result.items.forEach(product => {
                    unprocessed.push(product);
                });

                let products = itself.state.products || [];
                let templateNum = 1;
                while (templateNum !== undefined) {
                    templateNum = itself.getTemplateNum(unprocessed);
                    if (templateNum !== undefined) {
                        products.push({
                            template: templateNum,
                            products: unprocessed.splice(0, itself.TEMPLATES[templateNum])
                        })
                    }
                }

                itself.setState({
                    unprocessed: unprocessed,
                    products: products,
                    // total: result.total,
                    scrollEnable: true,
                    nextUrl: result.next || null,
                    loading: false
                });
            })
    };

    loadNext = () => {
        this.setState({scrollEnable: false});
        if (this.state.nextUrl !== null) {
            this.loadProductsList();
        }
    };

    renderTemplate = (template, products) => {
        switch (template) {
            case 1:
                return (<Template1 products={products}/>);
            case 2:
                return (<Template2 products={products}/>);
            case 3:
                return (<Template3 products={products}/>);
            case 4:
                return (<Template4 products={products}/>);
            case 5:
                return (<Template5 products={products}/>);
            case 6:
                return (<Template6 products={products}/>);
            case 7:
                return (<Template7 products={products}/>);
            case 8:
                return (<Template8 products={products}/>);
            case 9:
                return (<Template9 products={products}/>);
            default:
                return (<React.Fragment/>);
        }
    };

    componentWillMount = () => {
        this.loadProductsList();
    };

    render = () => {
        const {products} = this.state;
        return (
            <Container fluid={true} style={{padding: 0}}>
                <Row style={{marginLeft: -15, marginRight: -15}}>
                    {products === null &&
                    [1, 2, 3, 4, 5, 6].map(index => {
                            return (<ProductCardPlaceholder key={index}/>)
                        }
                    )}

                    {products !== null && products.length > 0 &&
                    products.map((product, index) => {
                        return (
                            <React.Fragment key={index}>
                                {this.renderTemplate(product.template, product.products)}
                            </React.Fragment>
                        )
                    })}
                </Row>
                {this.state.loading && <LoadingIndicator/>}
                <InfiniteScroll distance={1} disabled={!this.state.scrollEnable} onBottom={this.loadNext}/>
            </Container>
        )
    }
}

GridProductList.propTypes = {
    loadProducts: PropTypes.func.isRequired
};

export default GridProductList;
