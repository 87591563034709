import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import Button from "react-bootstrap/Button"
import {injectIntl} from "react-intl";
import MessageService from "../services/MessageService";
import Alert from 'react-bootstrap/Alert'

class NewMessage extends Component {
    formControlRef = React.createRef();

    state = {
        message: "",
        isSending: false,
        alertMessage: null,
        alertType: null
    };

    sendingNotAvailable = (message, isSending) => {
        return message === null || message.length === 0 || isSending;
    };

    handleCloseAlert = () => {
        this.setState({
            alertMessage: null,
            alertType: null
        });
    };

    handleSend = () => {
        const {formatMessage} = this.props.intl;
        let itself = this;
        this.setState({isSending: true});

        MessageService
            .create(this.props.to, this.state.message, this.props.from)
            .then(function (createdMessage) {
                itself.setState({
                    isSending: false,
                    alertMessage: formatMessage({id: "messages.messageWasSent"}),
                    alertType: "success",
                    message: ""
                }, () => {
                    itself.props.onMessageSent(createdMessage)
                });


            }, function (error) {
                itself.setState({
                    isSending: false,
                    alertMessage: formatMessage({id: "messages.messageWasNotSent"}),
                    alertType: "danger"
                });
            });
    };

    componentWillMount = () => {
        this.setState({message: this.props.body || ""})
    };

    componentDidMount = () => {
        this.formControlRef.current.focus();
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        const {message, isSending, alertType, alertMessage} = this.state;
        let isSendingAvailable = !this.sendingNotAvailable(message, isSending);
        return (
            <React.Fragment>
                <Alert dismissible variant={alertType} show={alertMessage !== null} onClose={this.handleCloseAlert}>
                    {alertMessage}
                </Alert>
                <Form.Group controlId="message">
                    <Form.Control as="textarea"
                                  rows="3"
                                  ref={this.formControlRef}
                                  placeholder={formatMessage({id: "messages.message"})}
                                  value={message}
                                  disabled={isSending}
                                  onChange={e => this.setState({message: e.target.value})}
                    />
                </Form.Group>
                <Button variant="primary" type="button" size="sm"
                        onClick={isSendingAvailable ? this.handleSend : null}
                        disabled={!isSendingAvailable}>
                    {isSending ? formatMessage({id: "common.sending"}) : formatMessage({id: "messages.send"})}
                </Button>
            </React.Fragment>
        )
    }
}

NewMessage.propTypes = {
    to: PropTypes.string.isRequired,
    body: PropTypes.string,
    onMessageSent: PropTypes.func.isRequired,
    from: PropTypes.string
};

export default injectIntl(NewMessage);
