import React, {Component} from 'react';
import ProfilePage from "./ProfilePage";
import {FormattedMessage, injectIntl} from "react-intl"
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'
import RouteParams from "../services/RouteParams"
import Card from "react-bootstrap/Card"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import LinkService from "../services/LinkService"
import Button from 'react-bootstrap/Button'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import Col from 'react-bootstrap/Col'
import ProductService from "../services/ProductService";
import ImageService from "../services/ImageService"
import arrayMove from 'array-move'
import UploadImageDialog from "./UploadImageDialog";
import LocalStorage from "../services/LocalStorage";
import Alert from 'react-bootstrap/Alert'
import {withRouter} from "react-router-dom";

class EditProduct extends Component {
    state = {
        showUploadDialog: false,
        indexUnderUpload: null,
        showError: false,
        showSavingError: false,
        productLoaded: false,

        isNew: true,
        changed: false,

        productId: null,
        title: null,
        description: null,
        properties: null,
        images: null,
        categories: null,
        priceAmount: null,
        priceCurrency: null
    };

    handleTextFieldChange = (event, field) => {
        this.setState({[field]: event.target.value, changed: true});
    };

    handlePropertyFieldChange = (event, index) => {
        let properties = this.state.properties;
        properties[index] = event.target.value;
        this.setState({properties: properties, changed: true});
    };

    renderTitleAndDescription = () => {
        const {title, description, properties} = this.state;
        return (
            <Form className="edit-form-group">
                <h2><FormattedMessage id="mystuff.edit.info"/></h2>
                <p><FormattedMessage id="mystuff.edit.defineTitleAndDescription"/></p>

                <Form.Group controlId="title">
                    <Form.Label><FormattedMessage id="mystuff.edit.title"/></Form.Label>
                    <Form.Control type="text" value={title} onChange={event => this.handleTextFieldChange(event, "title")}/>
                </Form.Group>

                <Form.Group controlId="description">
                    <Form.Label><FormattedMessage id="mystuff.edit.description"/></Form.Label>
                    <Form.Control as="textarea" rows="5" value={description}
                                  onChange={event => this.handleTextFieldChange(event, "description")}
                    />
                </Form.Group>

                <p><FormattedMessage id="mystuff.edit.fillProductProperties"/></p>

                {[0, 1, 2, 3, 4].map(i => {
                    return (
                        <Form.Group controlId={`property${i}`} key={i}>
                            <Form.Label><FormattedMessage id="mystuff.edit.property"/></Form.Label>
                            <Form.Control type="text" value={properties[i] || ""} onChange={event => this.handlePropertyFieldChange(event, i)}/>
                        </Form.Group>
                    );
                })}
            </Form>
        );
    };

    removeImage = (index) => {
        let images = this.state.images;
        images.splice(index, 1);
        this.setState({images: images});
    };

    uploadImage = (index) => {
        this.setState({
            showUploadDialog: true,
            indexUnderUpload: index
        });
    };

    updateUploadedImage = (image) => {
        let images = this.state.images;
        images[this.state.indexUnderUpload] = image;
        this.setState({
            images: images,
            changed: true
        });
    };

    renderImages = () => {
        const SortableItem = SortableElement(({data}) => {
            return (
                <Col md={4} style={{marginBottom: 30}}>
                    <Card className="drop-edit-image">
                        <div className="product-card-card-image-frame">
                            <Card.Img variant="top"
                                      onLoad={this.handleImageLoad}
                                      src={data.image || LinkService.getNoCatalogImage()}
                            />
                        </div>
                        <Card.Body>
                            <Row className="justify-content-center">
                                <Button variant="outline-secondary" style={{marginLeft: 5, marginRight: 5}} onClick={() => this.uploadImage(data.index)}>
                                    <i className="fas fa-file-upload"/>&nbsp;
                                    <FormattedMessage id="mystuff.edit.upload"/>
                                </Button>
                                <Button variant="outline-secondary" style={{marginLeft: 5, marginRight: 5}} onClick={() => this.removeImage(data.index)}>
                                    <i className="fas fa-trash"/>
                                </Button>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            );
        });
        const SortableList = SortableContainer(({images}) => {
            return (
                <Row>
                    {images.map((image, index) => {
                        return <SortableItem key={`image-${index}`}
                                             index={index}
                                             data={{image: this.state.images[index], index: index}}/>;
                    })}
                </Row>
            );
        });
        let images = [0, 1, 2, 3, 4, 5];

        return (
            <Form className="edit-form-group">
                <h2><FormattedMessage id="mystuff.edit.images"/></h2>
                <UploadImageDialog show={this.state.showUploadDialog}
                                   onImageUpload={(image) => this.updateUploadedImage(image)}
                                   onClose={() => this.setState({showUploadDialog: false})}
                                   uploadFn={(file) => ProductService.uploadImage(file)}
                />
                <Container style={{marginTop: 15}}>
                    <SortableList images={images} axis="xy" onSortEnd={this.onSortEnd} pressDelay={200}/>
                </Container>
            </Form>
        );
    };

    onSortEnd = ({oldIndex, newIndex}) => {
        this.setState(({images}) => ({
            images: arrayMove(images, oldIndex, newIndex),
            changed: true
        }));
    };

    handleImageLoad = (event) => {
        ImageService.calculateImageSize(event.target);
    };

    updateCategories = (category, checked) => {
        let categories = this.state.categories;
        if (checked) {
            categories.add(category);

        } else {
            categories.delete(category);
        }
        this.setState({
            categories: categories,
            changed: true
        });
    };

    renderSort = () => {
        const {formatMessage} = this.props.intl;
        let categories = ["ACCESSORIES_FOR_HAIR", "BABY_CLOTHING", "BAGS", "BOLEROS_AND_CARDIGANS", "BRACELETS", "COVERLETS_AND_CAPES", "DECORATIONS",
            "DOG_CLOTHES", "DRESSES_AND_SKIRTS", "EARRINGS", "FLOWERS_AND_BROOCHES", "FOOTWEAR", "HATS", "MATERIALS", "MITTENS", "MOBILE_ACCESSORIES",
            "NECKLACES", "PICTURES", "RINGS", "SCARVES_AND_SHAWLS", "SOCKS", "SWEATERS_AND_JAKETS", "TOYS", "OTHER"];
        return (
            <Form className="edit-form-group">
                <h2><FormattedMessage id="mystuff.edit.sort"/></h2>
                <p><FormattedMessage id="mystuff.edit.selectCategories"/></p>
                <p><FormattedMessage id="mystuff.edit.categories"/>:</p>
                {categories.map(category => {
                    let title = formatMessage({id: "mystuff.edit.categories." + category.toLowerCase()});
                    return (
                        <Form.Check
                            checked={this.state.categories.has(category)}
                            key={category}
                            type={"checkbox"}
                            id={`checkbox-${category}`}
                            label={title}
                            onChange={event => this.updateCategories(category, event.target.checked)}
                            value={category}
                        />
                    );
                })}
            </Form>
        );
    };

    renderSelling = () => {
        const {formatMessage} = this.props.intl;
        const currencies = ['czk', 'eur', 'usd', 'rub', 'uah'];
        const {priceAmount, priceCurrency} = this.state;
        return (
            <Form className="edit-form-group">
                <h2><FormattedMessage id="mystuff.edit.selling"/></h2>
                <p><FormattedMessage id="mystuff.edit.sellingDescription"/></p>
                <Form.Group controlId="price">
                    <Form.Label><FormattedMessage id="mystuff.edit.price"/></Form.Label>
                    <Form.Control type="number" step="0.01" min="0.01"
                                  value={priceAmount}
                                  onChange={event => this.handleTextFieldChange(event, "priceAmount")}
                    />
                </Form.Group>
                <Form.Group controlId="currency">
                    <Form.Label><FormattedMessage id="mystuff.edit.currency"/></Form.Label>
                    <Form.Control as="select"
                                  value={priceCurrency}
                                  onChange={(event) => this.handleTextFieldChange(event, "priceCurrency")}>
                        {currencies.map((currency) => {
                            return (
                                <option key={currency} value={currency.toUpperCase()}>
                                    {formatMessage({id: `currency.${currency}`})}
                                </option>
                            );
                        })}
                    </Form.Control>
                </Form.Group>
            </Form>
        );
    };

    loadProduct = () => {
        const productId = RouteParams.productId();
        if (productId === "new") {
            this.setState({
                productLoaded: true,
                isNew: true,
                productId: null,
                title: "",
                description: "",
                properties: [],
                images: [],
                categories: new Set(),
                priceAmount: 0,
                priceCurrency: LocalStorage.getOrDefault("currency", "\"eur\"").toUpperCase()
            });
        } else {
            ProductService
                .findMyStuffById(productId)
                .then(product => {
                    this.setState({
                        productLoaded: true,
                        isNew: false,
                        productId: product.id,
                        title: product.title,
                        description: product.description,
                        properties: product.properties,
                        images: product.images.map(img => img.middle),
                        categories: new Set(product.categories),
                        priceAmount: product.price.amount / 100,
                        priceCurrency: product.price.currency
                    });
                })
                .catch(error => {
                    this.setState({
                        showError: true
                    })
                });
        }
    };

    componentWillMount = () => {
        this.loadProduct();
    };

    resetForm = () => {
        this.setState({
            indexUnderUpload: null,
            changed: false,
            showError: false
        });
        this.loadProduct();
    };

    saveProduct = (active) => {
        let product = {
            id: this.state.productId,
            title: this.state.title,
            description: this.state.description,
            properties: this.state.properties,
            images: this.state.images.map(image => {
                return {middle: image}
            }),
            categories: [...this.state.categories],
            price: {
                amount: this.state.priceAmount * 100,
                currency: this.state.priceCurrency
            },
            active: active
        };

        this.setState({
            inProgress: true
        });

        ProductService
            .updateProduct(product)
            .then(() => {
                this.props.history.push(LinkService.getMyStuff());

            })
            .catch(error => {
                this.setState({
                    inProgress: false,
                    showSavingError: true
                });
            });
    };


    formInvalid = (title, images, categories, priceAmount, priceCurrency) => {
        let isValid = (title.trim().length > 0 && images.length > 0 && categories.size > 0 && priceAmount > 0)
        return !isValid;
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        const {title, images, categories, priceAmount, priceCurrency, changed, inProgress, productLoaded} = this.state;
        return (
            <ProfilePage user={this.props.user}>
                <h1>{this.state.isNew ? formatMessage({id: "mystuff.newProduct"}) : formatMessage({id: "mystuff.editProduct"})}</h1>
                <Alert dismissible variant="danger" show={this.state.showError} onClose={() => this.setState({showError: false})}>
                    <FormattedMessage id="common.pageCannotBeFound"/>
                </Alert>
                {productLoaded && this.renderTitleAndDescription()}
                {productLoaded && this.renderImages()}
                {productLoaded && this.renderSort()}
                {productLoaded && this.renderSelling()}


                <Alert dismissible variant="danger" show={this.state.showSavingError} onClose={() => this.setState({showSavingError: false})}>
                    <FormattedMessage id="profile.changesNotSaved"/>
                </Alert>
                {productLoaded &&
                <div style={{marginBottom: 45}}>
                    <div style={{marginBottom: 5}}>
                        <Button variant="primary"
                                onClick={() => this.saveProduct(true)}
                                disabled={!changed || inProgress || this.formInvalid(title, images, categories, priceAmount, priceCurrency)}>
                            {!this.state.inProgress ? formatMessage({id: "mystuff.edit.saveAndPublish"}) : formatMessage({id: "common.waitPlease"})}
                        </Button>
                        <span> </span>
                        <Button variant="outline-primary"
                                onClick={() => this.saveProduct(false)}
                                disabled={!changed || inProgress || this.formInvalid(title, images, categories, priceAmount, priceCurrency)}>
                            {!inProgress ? formatMessage({id: "mystuff.edit.saveAsDraft"}) : formatMessage({id: "common.waitPlease"})}
                        </Button>
                        <span> </span>
                        <Button variant="outline-secondary" onClick={this.resetForm}>
                            <FormattedMessage id="common.cancel"/>
                        </Button>
                    </div>
                    {this.formInvalid(title, images, categories, priceAmount, priceCurrency) &&
                    <p className="text-muted"><FormattedMessage id="mystuff.edit.saveConditions"/></p>
                    }
                </div>
                }

            </ProfilePage>
        )
    }
}

EditProduct.propTypes = {
    user: PropTypes.object,
    onLogin: PropTypes.func.isRequired,
};

export default withRouter(injectIntl(EditProduct));
