import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl"
import Alert from "react-bootstrap/Alert";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";
import LocalStorage from "../services/LocalStorage";

class MessageAlert extends Component {
    state = {
        show: false
    };

    getLocalStorageVariableName = () => {
        return `MessageAlert-${this.props.messageId}`;
    };

    componentWillMount() {
        let show = LocalStorage.get(this.getLocalStorageVariableName());
        show = (show || show === undefined || show === null);
        this.setState({show: show});
    }

    handleDismiss = () => {
        LocalStorage.set(this.getLocalStorageVariableName(), false);
        this.setState({show: false});
    };

    render = () => {
        return (
            <Alert variant="info" onClose={this.handleDismiss} dismissible show={this.state.show}>
                <Alert.Heading><FormattedMessage id="common.importantInformation"/></Alert.Heading>
                <p>
                    {this.props.message}
                    {' '}
                    <LinkContainer to={this.props.link}>
                        <Alert.Link><FormattedMessage id="common.moreInfo"/></Alert.Link>
                    </LinkContainer>
                    .
                </p>
            </Alert>
        )
    }
}

MessageAlert.propTypes = {
    messageId: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
};

export default MessageAlert;
