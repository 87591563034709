import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from "react-intl";
import LinkService from "../services/LinkService";
import Badge from "react-bootstrap/Badge";
import Nav from "react-bootstrap/Nav";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";

class MessagesNavLink extends Component {
    renderTitle = () => {
        return (
            <React.Fragment>
                <i className="fa fa-envelope"></i>&nbsp;
                <FormattedMessage id="topNavi.messages"/>
            </React.Fragment>
        );
    };

    render = () => {
        const {user} = this.props;
        return (
            <React.Fragment>
                {user &&
                <LinkContainer to={LinkService.getMessages()}>
                    <Nav.Link active={window.location.pathname.startsWith(LinkService.getMessages())}>
                        {this.renderTitle()}
                        {user.unreadMessagesCount > 0 &&
                        <React.Fragment>
                            <span>&nbsp;</span>
                            <Badge pill variant="danger">
                                {user.unreadMessagesCount}
                            </Badge>
                        </React.Fragment>
                        }
                    </Nav.Link>
                </LinkContainer>
                }
                {!user &&
                <Nav.Link active={window.location.pathname.startsWith(LinkService.getMessages())} onClick={this.props.onLogin}>
                    {this.renderTitle()}
                </Nav.Link>
                }
            </React.Fragment>
        )
    }
}

MessagesNavLink.propTypes = {
    user: PropTypes.object,
    onLogin: PropTypes.func.isRequired
};

export default injectIntl(MessagesNavLink);
