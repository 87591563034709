import React, {Component} from 'react';
import LocalStorage from "../services/LocalStorage";
import HelpGDPREn from "./HelpGDPREn";
import HelpGDPRCz from "./HelpGDPRCz";
import HelpGDPRRu from "./HelpGDPRRu";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

class HelpGDPR extends Component {
    render = () => {
        const lang = LocalStorage.getOrDefault("language", null);
        return (
            <Row>
                <Col md={3}></Col>
                <Col md={9}>
                    {lang === "en" && <HelpGDPREn/>}
                    {lang === "cz" && <HelpGDPRCz/>}
                    {lang === "ru" && <HelpGDPRRu/>}
                </Col>
            </Row>
        )
    }
}

export default HelpGDPR;
