import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Image from "react-bootstrap/Image";
import ContentLoader from "react-content-loader";

class ProfileCard extends Component {
    render = () => {
        const {user} = this.props;
        return (
            <React.Fragment>
                {user &&
                <div style={{padding: "15px 45px"}}>
                    <p style={{margin: 0}} className="text-center">
                        <Image src={user.avatar} rounded style={{width: 100, height: 100}}/>
                    </p>
                    <h4 style={{margin: 0}} className="text-center">{user.name}</h4>
                    <p style={{marginTop: 15, margin: 0}} className="text-center">
                        <small>{user.email}</small>
                    </p>
                </div>
                }
                {!user &&
                <ContentLoader width={255} height={182}
                               preserveAspectRatio={"none"}
                               style={{width: '100%', height: 182}}>
                    <rect x="72.50" y="11.61" rx="0" ry="0" width="110" height="100"/>
                    <rect x="72.50" y="120" rx="0" ry="0" width="110" height="15"/>
                    <rect x="37.50" y="145" rx="0" ry="0" width="180" height="10"/>
                </ContentLoader>
                }
            </React.Fragment>
        )
    }
}

ProfileCard.propTypes = {
    user: PropTypes.object,
};

export default ProfileCard;
