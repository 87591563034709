import axios from "axios/index";
import ReturnPromise from "./ReturnPromise";

const DIALOGS_API = "/api/v1/dialogs";
const MESSAGES_API = "/api/v1/messages";

const MessageService = {
    listDialogs: function (offset, limit) {
        return ReturnPromise.get(
            axios
                .get(DIALOGS_API, {
                    params: {
                        offset: offset,
                        limit: limit
                    }
                }));
    },

    get: function (url) {
        return ReturnPromise.get(axios.get(url));
    },

    loadMessageThread: function (userId) {
        return ReturnPromise.get(axios.get(`${DIALOGS_API}/${userId}`));
    },

    create: function (userId, message, fromId) {
        return ReturnPromise.get(
            axios
                .post(MESSAGES_API, {
                    userId: userId,
                    body: message,
                    fromId: fromId
                }));
    },

    markMessageThreadAsRead: function (userId) {
        return ReturnPromise.get(axios.put(`${DIALOGS_API}/${userId}`, {unread: false}));
    }
};

export default MessageService;
