import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl"
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import ProductService from "../services/ProductService";
import ReviewItem from "./ReviewItem";

class ReviewReply extends Component {
    state = {
        textareaShown: false,
        message: "",
        isSending: false,
        alertMessage: null,
        reviews: []
    };

    sendingNotAvailable = (message, isSending) => {
        return message === null || message.length === 0 || isSending;
    };

    handleSend = () => {
        const {formatMessage} = this.props.intl;
        let itself = this;
        this.setState({isSending: true});

        ProductService
            .createReviewReply(this.props.product.id, this.props.reviewId, this.state.message)
            .then(function (review) {
                let reviews = itself.state.reviews;
                reviews.push(review);
                itself.setState({
                    textareaShown: false,
                    isSending: false,
                    message: "",
                    reviews: reviews
                });


            }, function (error) {
                itself.setState({
                    isSending: false,
                    alertMessage: formatMessage({id: "messages.messageWasNotSent"}),
                });
            });
    };

    render = () => {
        const {formatMessage} = this.props.intl;
        const {message, isSending, alertMessage} = this.state;
        let isSendingAvailable = !this.sendingNotAvailable(message, isSending);
        return (
            <React.Fragment>
                {!this.state.textareaShown &&
                <Button variant="link" onClick={() => this.setState({textareaShown: true})}>
                    <FormattedMessage id="product.reply"/>
                </Button>
                }
                {this.state.textareaShown &&
                <Form className="reply-form">
                    <Alert dismissible variant="danger"
                           show={alertMessage !== null}
                           onClose={() => this.setState({alertMessage: null})}>
                        {alertMessage}
                    </Alert>
                    <Form.Group>
                        <Form.Control as="textarea" rows="3"
                                      value={message}
                                      placeholder={formatMessage({id: "messages.message"})}
                                      disabled={isSending}
                                      onChange={e => this.setState({message: e.target.value})}
                        />
                    </Form.Group>
                    <Button variant="outline-primary" size="sm"
                            onClick={isSendingAvailable ? this.handleSend : null}
                            disabled={!isSendingAvailable}
                    >
                        {isSending ? formatMessage({id: "common.sending"}) : formatMessage({id: "messages.send"})}
                    </Button>
                </Form>
                }
                {this.state.reviews.map((review) => {
                    return (<ReviewItem key={review.id} review={review} product={this.props.product}/>);
                })}

            </React.Fragment>
        )
    }
}

ReviewReply.propTypes = {
    product: PropTypes.object.isRequired,
    reviewId: PropTypes.string.isRequired
};

export default injectIntl(ReviewReply);
