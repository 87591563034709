import React, {Component} from 'react'
import SinglePhotoTemplate from "./SinglePhotoTemplate";
import PropTypes from 'prop-types'
import Col from "react-bootstrap/Col";

class Template1 extends Component {

    render = () => {
        return (
            <React.Fragment>
                <Col md={3} className="no-padding">
                    <SinglePhotoTemplate product={this.props.products[0]} classes="tall"/>
                </Col>
                <Col md={6} className="no-padding">
                    <SinglePhotoTemplate product={this.props.products[1]} classes="tall"/>
                </Col>
                <Col md={3} className="no-padding">
                    <SinglePhotoTemplate product={this.props.products[2]} classes="small"/>
                    <SinglePhotoTemplate product={this.props.products[3]} classes="small"/>
                </Col>
                <div className="clearfix"></div>
            </React.Fragment>
        )
    }
}

Template1.propTypes = {
    products: PropTypes.array.isRequired
};

export default Template1;
