import React, {Component} from 'react';
import LocalStorage from "../services/LocalStorage";
import HelpTermsEn from "./HelpTermsEn";
import HelpTermsCz from "./HelpTermsCz";
import HelpTermsRu from "./HelpTermsRu";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

class HelpTerms extends Component {
    render = () => {
        const lang = LocalStorage.getOrDefault("language", null);
        return (
            <Row>
                <Col md={3}></Col>
                <Col md={9}>
                    {lang === "en" && <HelpTermsEn/>}
                    {lang === "cz" && <HelpTermsCz/>}
                    {lang === "ru" && <HelpTermsRu/>}
                </Col>
            </Row>
        )
    }
}

export default HelpTerms;
