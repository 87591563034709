import React, {Component} from 'react'
import Table from 'react-bootstrap/Table'
import MessageService from "../services/MessageService";
import ContentLoader from "react-content-loader";
import {FormattedDate, FormattedMessage} from "react-intl";
import ProductService from "../services/ProductService";
import LoadingIndicator from "../catalog/LoadingIndicator";
import InfiniteScroll from "../shell/InfiniteScroll";
import LinkService from "../services/LinkService";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";

class MessageList extends Component {
    state = {
        nextUrl: null,
        messages: null,
        loading: false,
        scrollEnable: false,
    };

    doLoadMessages = () => {
        if (this.state.nextUrl === null || this.state.messages === null) {
            return MessageService.listDialogs(0, 24);
        } else {
            return ProductService.findByUrl(this.state.nextUrl);
        }
    };

    loadMessages = () => {
        let itself = this;
        this.setState({loading: true});

        this
            .doLoadMessages()
            .then(response => {
                let messages = itself.state.messages || [];
                response.items.forEach(message => {
                    messages.push(message);
                });

                itself.setState({
                    messages: messages,
                    scrollEnable: true,
                    nextUrl: response.next || null,
                    loading: false
                });
            });
    };

    loadNext = () => {
        this.setState({scrollEnable: false});
        if (this.state.nextUrl !== null) {
            this.loadMessages();
        }
    };

    renderLoader = (i) => {
        const height = 75;
        return (
            <ContentLoader width={825}
                           height={height} key={i}
                           preserveAspectRatio={"none"}
                           style={{width: '100%', height: height, marginBottom: 5}}>
            </ContentLoader>
        )
    };

    renderNoMessages = () => {
        return (
            <p className="lead"><FormattedMessage id="messages.notFound"/></p>
        );
    };

    renderMessage = (message) => {
        return (
            <LinkContainer to={LinkService.getMessageThread(message.userId)} key={message.userId}>
                <tr>
                    <td>
                        <img className="rounded messages-avatar" alt={message.name} src={message.avatar}/>
                    </td>
                    <td>
                        <strong>{message.name}</strong><br/>
                        <small className="text-muted">
                            <FormattedDate value={message.date} year="numeric" month="numeric" day="numeric"/>
                        </small>
                    </td>
                    <td>{message.body.substring(0, 100)}...</td>
                    <td className="messages-unread">
                        {message.unread && <i className="fas fa-envelope fa-2x float-right text-danger"></i>
                        }
                    </td>
                </tr>
            </LinkContainer>
        )
    };

    componentWillMount = () => {
        this.loadMessages();
    };

    render = () => {
        const {messages} = this.state;
        return (
            <React.Fragment>
                <h1><FormattedMessage id="topNavi.messages"/></h1>
                {messages === null && [1, 2, 3, 4, 5].map(i => {
                    return this.renderLoader(i)
                })}
                {messages !== null && messages.length === 0 && this.renderNoMessages()}
                {messages !== null && messages.length > 0 &&
                <Table hover>
                    <tbody className="clickable">
                    {messages.map(message => {
                        return (this.renderMessage(message))
                    })}
                    </tbody>
                </Table>
                }
                {this.state.loading && <LoadingIndicator/>}
                <InfiniteScroll distance={1} disabled={!this.state.scrollEnable} onBottom={this.loadNext}/>
            </React.Fragment>
        )
    }
}

export default MessageList;
