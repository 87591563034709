import React, {Component} from 'react';

class HelpTermsEn extends Component {
    render() {
        return (
            <React.Fragment>
                <h1>Business conditions of Valika</h1>
                <p>By registering at, and utilizing, the Internet server Valika (hereinafter the Server), the user
                    assents to the
                    following contractual terms. The following text represents a stipulation by and between the operator
                    of the portal
                    and the registered visitor.</p>
                <h2>Short summary of rules</h2>
                <ul>
                    <li>Valika is a trading spot which intermediates the sale and purchase of hand-made goods of wool
                        between the Seller
                        and the Buyer. Valika is not the seller of the Goods.
                    </li>
                    <li>Registration at Valika is free.</li>
                    <li>The Buyer does not pay any charges or commission to Valika.</li>
                    <li>The Seller undertakes to pay (see below) the provider (i.e. Valika) the commission for the
                        intermediation of the
                        sale which comes into force and effect by the confirmation of the order by the Buyer. If the
                        Buyer does not pay
                        the price of the Goods (and, therefore, does not receive the Goods), the Seller may apply for a
                        deduction from
                        the commission in any of the subsequent months.
                    </li>
                    <li>Valika, as the operator of the server, does not sell Goods and therefore shall not be
                        responsible for the Goods
                        being sold (for the correctness of the image of the Goods, their quality, availability, etc.)
                        and shall not be
                        responsible for the process of purchase and sale, for any complaints and/or any eventual
                        disputes between the
                        Buyer and Seller. Valika shall also not be responsible for cases where it is revealed that the
                        identity of the
                        registered user is not correct. Valika does not verify the identity of Sellers and/or Buyers.
                    </li>
                    <li>Valika does not participate in the content of the offers, description of the Goods, or
                        information on the
                        registered users.
                    </li>
                    <li>The users of Valika are obliged to adhere to the legislation of the EU and to respect the
                        fundamentals of good
                        manners. Treat others as you would like to be treated.
                    </li>
                </ul>

                <h2>I. CONDITIONS OF USE</h2>
                <p>Trading activities at Valika are public activities and they are available to registered physical
                    persons 18 years of
                    age or over and registered legal entities that have full capacity to enter legal acts, except for
                    Users whose
                    registration was cancelled by the Provider.</p>
                <p>Neither Users nor applicants for concluding a contract have any legal title to use the Valika server
                    or to conclude
                    any contracts. The Provider reserves the right to refuse registration to any applicant for
                    registration and/or to
                    withdraw from a contract with any existing User or to restrict their access to some services, even
                    without
                    specifying the reasons.</p>
                <p>The Provider does not guarantee, in any way, the maintenance and/or preservation of any and all User
                    data, and the
                    continuity of operation of the service.</p>

                <h2>II. REGISTRATION</h2>
                <p>The condition for the sale and/or purchase of Goods through Valika is the approval of this Contract
                    and free
                    registration of the User.</p>
                <p>A User who is a physical person shall register, specifying their first name, surname, full address
                    (permanent or
                    temporary), e-mail address, telephone number, username and password. The User shall inform Valika of
                    any and all
                    changes to the above data and to update such data regularly.</p>
                <p>The Provider is permitted to provide to a Seller or to a Buyer any necessary data about the
                    counterparty (by reason
                    of sending the Goods, filing a complaint, etc.), including full name, telephone number, place of
                    permanent or
                    temporary abode, and vice versa.</p>
                <p>Users have access to their data and may change them. Users are also authorized to apply for their
                    removal from the
                    database after finishing their activities at Valika.</p>
                <p>Users are entitled to own more than one user account; however, it is forbidden to use one or more of
                    their accounts
                    for breaking this Contract (e.g. manipulation of the accounts for the purpose of hiding one’s
                    identity, or for
                    manipulative promotion).</p>
                <p>The registration is confirmed using an automatically sent e-mail confirmation. Registrations not
                    verified in this way
                    are non-functional and invalid.</p>

                <h2>III. COMMISSION FOR THE SALE OF GOODS</h2>
                <p>The placing of goods is free for the seller..</p>
                <p>Server Valika charges the commission for the intermediation of the sale only.</p>
                <p>The seller is obligated to pay the commission for the arranging of the sale based on the monthly
                    bill. If the buyer
                    does not pay (and therefore does not assume), the seller is obligated to mark the listing commission
                    and the
                    commission compensate of discounts in the next month.</p>
                <p>The seller is informed by e-mail about a summary of the sales commission for the calendar month.
                    Server Valika issues
                    to the seller an invoice automatically through electronic invoices for printing.</p>
                <p>Maturity commission is always indicated on the invoice (the maturity date is the day when the amount
                    is credited to
                    the bank account operator), the payment due date may be penalized. Payments operator only accepts
                    payments via
                    internetbanking.</p>
                <p>In case that the commission will not be paid on time, the operator is entitled to cancel the account
                    of the user to
                    remove his goods from the menu, do not allow re-registering with Valika and recover the amount due
                    in court.</p>
                <p>Valika server is not obliged to resolve any disputes between the seller and the buyer and claim the
                    goods, the seller
                    is not entitled to recover the commission in a dispute with the buyer.</p>
                <p>The commission amount is 10% of the selling price of goods. The server Valika reserves the right to
                    change the amount
                    of commission.</p>
                <p>Valika is not obliged to settle any disputes that may arise between the Seller and the Buyer, and the
                    Seller shall
                    not be entitled to request the return of the commission in the event of any disputes with the
                    Buyer.</p>

                <h2>IV. FORBIDDEN GOODS</h2>
                <p>It is forbidden to offer Goods, the offer, sale, purchase or use of which is in conflict with good
                    manners or valid
                    legal regulations. It is forbidden, in particular, to offer Goods which do not conform to the focus
                    of activity of
                    the server, Goods to which the Seller has no right of sale, or Goods which are mortgaged or
                    otherwise have their
                    dispositions restricted. Furthermore, it is forbidden to sell guns, alcohol, intoxicating or
                    psychotropic
                    substances, pornographic materials or otherwise disturbing pornographic or erotic materials, Goods
                    which violate
                    intellectual property rights (i.e. those which break rights to registered trademarks and/or marks of
                    origin, illegal
                    duplications of author crafts, etc.), or Goods intended for the promotion and dissemination of the
                    beliefs and
                    opinions of extreme leftwing or extreme rightwing movements.</p>

                <p>Kupující bere na vědomí, že zboží, které vkládá do košíku, může pocházet od více prodávajících a
                    zboží, které
                    objednává v jedné objednávce, nemusí být akceptováno celé a také nemusí být dodáno současně.</p>
                <p>The Provider reserves the right to remove such forbidden Goods from the system.</p>
                <p>The Provider also reserves the right to remove, in exceptional cases, such Goods from the system
                    without even stating
                    any reasons.</p>

                <h2>V. PURCHASE OF THE GOODS</h2>
                <p>The Buyer selects the Goods from the Sellers, being aware that Valika is not the seller and that the
                    separate Goods
                    come from the Sellers, who are Registered Users of Valika. The Provider is not liable, in any way,
                    for the Goods
                    being sold. The Provider thus only mediates the sale and purchase of hand-made goods.</p>
                <p>The Buyer acknowledges that the Goods they place in their shopping cart may come from several Sellers
                    and the Goods
                    the Buyer orders within one order are not necessarily to be accepted as a whole and are not
                    necessarily to be
                    supplied all at once.</p>
                <p>The Buyer acknowledges that by sending the order they only inform the Seller of their intention to
                    buy such Goods,
                    while the Goods shall only be sent after the confirmation of the order by the Seller(s) and the
                    payment of the
                    amount due for the Goods by the Buyer.</p>
                <p>The Buyer acknowledges that the Seller is entitled to reject an order as a whole or in part. In such
                    cases, the Buyer
                    shall pay only for the confirmed part of the order.</p>
                <p>The Buyer does not pay any charges or commission to the Provider for the mediation of the sale. The
                    Goods are paid
                    for directly in advance to the Seller’s bank account (or otherwise, as per the payment terms of the
                    Seller).</p>

                <h2>VI. SALE OF THE GOODS</h2>
                <p>The Sellers are allowed to offer their own new hand-made goods of wool through Valika, as per the
                    following
                    definitions:</p>
                <ul>
                    <li>Hand-made goods of wool are goods created personally with creative invention. Hand-made goods
                        must be unused.
                    </li>
                    <li>Other goods allowed for sale are manuals, on-line courses and documents closely related to
                        hand-made and
                        creative production.
                    </li>
                </ul>
                <p>Each item published in the selling part of the server must be specific Goods intended for sale. The
                    Goods must be
                    categorized according to the nature of the Goods.</p>
                <p>Neither the type of Goods nor their image or description may contain contact information about the
                    Seller (name,
                    surname, telephone number, e-mail, web page, etc.). Such contact information (or encouraging them to
                    search for it
                    elsewhere via the Internet) must not be present in any other written or visual sale-related
                    elements, such as
                    comments, discussions, blogs, etc.</p>
                <p>Neither the type of Goods nor their description may contain any key words used for the purpose of
                    manipulating search
                    results.</p>
                <p>Neither the caption of the Goods nor their description may contain elements of HTML, JavaScript, Java
                    and other
                    programming languages, especially with the intention of affecting the content or marketability of
                    the Goods or
                    otherwise impairing or jeopardizing the functionality of the Valika server.</p>
                <p>The Goods that are offered and their image and description must fully and truly represent the reality
                    (in the case of
                    photographs, we also recommend using optional photographs). The depiction of the Goods and their
                    description must
                    sufficiently inform the Buyer as to minimize any possibility of confusion or erroneous notion of the
                    visitors about
                    the depicted Goods.</p>
                <p>It is forbidden to use photographs which may violate copyright law (e.g. photographs from magazines,
                    photographs for
                    which the Seller has no copyright, etc.).</p>
                <p>It is forbidden to offer for sale Goods which violate copyright law (e.g. reproductions of licensed
                    artworks,
                    etc.).</p>
                <p>It is forbidden to offer services (except for the offers in the Courses category); the subject of
                    trading is always
                    Goods – tangible things. Such tangible things include electronic works captured on tangible media
                    (CD, hard drive,
                    etc.).</p>
                <p>It is forbidden to sell any Goods for the manufacture and/or sale of which the Seller has no
                    corresponding
                    authorizations (e.g. foodstuffs without adherence to sanitary standards, products made of precious
                    metals which are
                    not registered at the assay office, etc.).</p>
                <p>It is forbidden to sell Goods which are not hand-made by the Seller or a selling team, or to resell
                    Goods made by
                    another Seller, except for any Goods modified by the Seller (e.g. processed materials or hand-made
                    products
                    creatively manipulated or processed and sold without the original author’s knowledge).</p>
                <p>If it is necessary to use computer simulation or computer retouching which fundamentally changes the
                    character and
                    substance and/or use of the Goods or which, for some reason, cannot inform the Buyer fully about the
                    nature of the
                    Goods (e.g. 2-D or 3-D visualization, an offer of Goods from previous sales with some elements
                    changed, graphic
                    designs for prints, etc.), the Seller must call proper attention to this fact in the description of
                    the Goods or
                    directly in the picture (e.g.: “the picture is for reference only”, “the picture is a 3-D
                    visualization”, etc.).</p>
                <p>The Seller displays the Goods through an electronic form where all required items must be stated.</p>

                <h3>Payment and Delivery Conditions</h3>
                <p>All prices stated by the Seller are final prices with VAT.</p>
                <p>The Seller is entitled to delete the Goods offered at any time or to hide them for an indefinite
                    period of time. The
                    Seller may also change and edit the description of the Goods.</p>
                <p>The Seller is informed of the intention of the Buyer through the Valika’s mail service and also
                    through a listing in
                    the Order section. Such information is not binding and the Seller may withdraw from it by rejection
                    of the
                    order.</p>
                <p>After receiving the notice of an order, the Seller is allowed to accept the order in part only by
                    marking suitable
                    items only. The Seller is then required to supply to the Buyer only those items which have been
                    acknowledged.</p>
                <p>The Seller undertakes to dispatch the Goods as soon as practicable after the receipt, confirmation
                    and payment of the
                    order, without unnecessary delay.</p>
                <p>The Seller is forbidden to communicate in any way (using internal mail or e-mail) with the customers
                    of Valika about
                    the possibility of sales outside Valika (including, but not limited to, links to the Seller’s own
                    e-shop,
                    instructions on how to find the Seller’s own e-shop, and/or the exchange of contact information) or
                    the possibility
                    of exchanging Goods without their purchase through Valika (reciprocal service by compensation or
                    mutual
                    trade-off).</p>
                <p>The Provider warns that the Goods presented or changes to their specifications may be displayed with
                    a certain time
                    displacement (caused by storing the temporary files on the part of the Users or caused by temporary
                    files on the
                    part of the server).</p>
                <p><strong><u>Delivery</u></strong> is defined out of good’s price. Author packs and sends thing to the
                    customer.
                    Delivery time is based on author’s and customer’s location.</p>
                <p>At present we do not accept online payments. Therefore, the deposit can only be paid by means of a
                    bank transfer into
                    the account which we will provide in the email reply to your reservation request. In Future we are
                    planning to
                    accept online payments via PayPal and credit cards.</p>

                <h3>How to sell</h3>
                <p>For selling at web-site “Valika - world of tame wool” is necessary:</p>
                <ol>
                    <li>Sign up</li>
                    <li>Fill in information about author.</li>
                    <li>Place photos of items.</li>
                    <li>Fill in information about item: material, size, price and description.</li>
                </ol>
                <p><strong>To pay attention please!</strong></p>
                <ul>
                    <li>Delivery fee is included in item’s price.</li>
                    <li>Web-site doesn’t take part in payments between seller and buyer. Buyer pays directly to
                        author.
                    </li>
                </ul>
                <p><strong>Placing of the items at the Valika.org is free of fees.</strong></p>
                <p>Additional possibilities</p>
                <p>Registered authors have the next additional possibilities:</p>
                <ul>
                    <li>Personal author’s shop. Every author has own shop’s adress of third level like as
                        author's-name.valika.org.
                    </li>
                    <li>Author can place the adress/link to his own shop in his/her blogs or in livejournal.com
                        (Function is presented
                        in Author’s profile at page of "Shop's settings").
                    </li>
                </ul>

                <h2>VII. OTHER RULES FOR NON-SALE SECTIONS</h2>
                <p>It is forbidden to publish direct personal data in the comments, discussion and other community
                    elements of the
                    server.</p>
                <p>It is forbidden to insert advertising notices or hyperlinks of an advertising nature except for the
                    support of the
                    User’s own business at Valika (however, not by links that lead outside the Valika server).</p>
                <p>It is forbidden to perform any activities which may be classified as spam. These include, for
                    example, sending
                    repeated or bulk advertising or otherwise annoying information using internal mail, or placing
                    repeated or bulk
                    advertising or otherwise annoying information in the discussions or comments.</p>
                <p>It is forbidden to perform any activities which may be classified as unfair competition (e.g. to
                    register any
                    usernames which may, through eventual confusion, aggrieve the original Seller, to communicate or
                    solicit Goods with
                    a view to confusing the identity of the Seller or of the Goods, etc.).</p>
                <p>It is forbidden to use community elements of the server (discussions, comments, etc.) to publicly
                    undermine the
                    authority of the Provider, or otherwise to express disloyalty towards the Provider and/or employees
                    of the Provider
                    by one’s behaviour.</p>
                <p>It is forbidden to publicly impair or attack the Sellers and the Buyers. A negative evaluation (User
                    Evaluation
                    section) is not classified as impairment. In the event of disputes we recommend solving them through
                    internal mail
                    or by contacting the Provider. The Provider is not accountable, in any way, for any disputes that
                    may arise.</p>

                <h2>VIII. PROTECTION OF PERSONAL DATA</h2>
                <p>The User is required to provide true information. The Provider protects any and all information sent
                    upon
                    registration by Act No. 101/2000 Coll., on the protection of personal data, as amended, as per the
                    legislation of
                    the Czech Republic and the EU, except for handing over such information to the counterparty at the
                    moment of the
                    completion of a purchase agreement under valid legislation.</p>
                <p>The Registered User accepts that upon the completion of a sale, any and all personal data are handed
                    over to the
                    counterparty (solely), i.e. to the Seller or to the Buyer. These include, in particular, their name,
                    surname,
                    address and telephone number.</p>
                <p>The Provider shall also be entitled to hand over a reasonable portion of personal data in the event
                    of any complaints
                    about the quality of Goods or failure to deliver Goods.</p>

                <h2>IX. CONSENT TO THE USE OF PHOTOGRAPHS</h2>
                <p>By uploading any and all photographs, the Seller gives permission for the photographs of the Goods to
                    be used for the
                    promotional purposes of Valika in order to popularize the server Valika. The Seller also agrees that
                    Valika may
                    submit the photographs to third parties (e.g. women’s magazines, fashion servers, etc.) but solely
                    for the purpose
                    of popularizing Valika..</p>
                <p>Each photograph or set of photographs used in such a way shall be marked with the Valika's logo or
                    with the text
                    ‘www.valika.org’ in a way that makes it evident that such a product is being sold throughValika.</p>
                <p>The Provider undertakes not to use photographs uploaded by Users for purposes other than to
                    popularize the server,
                    and that the Provider shall deal with third parties in a similar way.</p>
                <p>The Provider declares that it shall make all efforts to inform the Seller in advance of any and all
                    particular use of
                    any and all photographs uploaded by the User.</p>

                <h2>X. FINAL DISPOSITIONS</h2>
                <p>The Provider reserves the right to terminate this Contract (i.e. to exclude from participation in
                    Valika) those
                    Sellers and Buyers who violate the rules of Valika and/or who are not willing to cooperate with the
                    administration
                    of Valika in resolving standard or exceptional situations.</p>
                <p>The Contract concluded under these business conditions shall be governed by the legislation of the
                    Czech Republic and
                    the EU. The Provider reserves the right to change the provision of any services, as well as to amend
                    these business
                    conditions, even without prior notice.</p>
                <p>The Contract may be terminated by a notice in the form of signing off by the User or by the Provider.
                    Such
                    termination comes into effect at the moment of the declaration of the wish and the delivery of the
                    wish by
                    e-mail.</p>
            </React.Fragment>
        )
    }
}

export default HelpTermsEn;
