import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ProfilePage from "./ProfilePage";
import {FormattedMessage, injectIntl} from "react-intl";
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import UserService from "../services/UserService";

class ChangePassword extends Component {
    state = {
        alertType: null,

        inProgress: false,

        oldPassword: "",
        password: "",
        repeatPassword: ""
    };

    handleTextFieldChange = (event, field) => {
        this.setState({[field]: event.target.value});
    };

    isFormValid = (oldPassword, password, repeatPassword) => {
        return oldPassword.trim().length > 0 && password.trim().length > 0 && repeatPassword.trim().length > 0 &&
            password === repeatPassword && password !== oldPassword;
    };

    changePassword = () => {
        this.setState({
            inProgress: true,
            alertType: null
        });
        UserService
            .changePassword(this.state.oldPassword, this.state.password)
            .then(data => {
                this.setState({
                    inProgress: false,
                    alertType: "success",
                    oldPassword: "",
                    password: "",
                    repeatPassword: ""
                });
            })
            .catch(error => {
                this.setState({
                    inProgress: false,
                    alertType: "danger"
                });
            });
    };

    render = () => {
        const {user} = this.props;
        const {formatMessage} = this.props.intl;
        const {oldPassword, password, repeatPassword, inProgress} = this.state;
        return (
            <React.Fragment>
                {user &&
                <ProfilePage user={user}>
                    <h1><FormattedMessage id="topNavi.changePassword"/></h1>
                    <Col md={8} style={{padding: 0}}>
                        <Form>
                            <Alert dismissible variant={this.state.alertType}
                                   show={this.state.alertType !== null}
                                   onClose={() => this.setState({alertType: null})}
                            >
                                {this.state.alertType === "danger" ? formatMessage({id: "profile.passwordChangeUnsuccessful"}) : formatMessage({id: "profile.passwordChangedSuccessfuly"})}
                            </Alert>
                            <Form.Group>
                                <Form.Label><FormattedMessage id="profile.oldPassword"/></Form.Label>
                                <Form.Control type="password"
                                              disabled={inProgress}
                                              placeholder={formatMessage({id: "profile.oldPassword"})}
                                              onChange={e => this.handleTextFieldChange(e, "oldPassword")}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label><FormattedMessage id="login.password"/></Form.Label>
                                <Form.Control type="password"
                                              disabled={inProgress}
                                              placeholder={formatMessage({id: "login.password"})}
                                              onChange={e => this.handleTextFieldChange(e, "password")}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label><FormattedMessage id="profile.repeatPassword"/></Form.Label>
                                <Form.Control type="password"
                                              disabled={inProgress}
                                              placeholder={formatMessage({id: "profile.repeatPassword"})}
                                              onChange={e => this.handleTextFieldChange(e, "repeatPassword")}
                                />
                            </Form.Group>

                            <Button variant="primary"
                                    onClick={this.changePassword}
                                    disabled={!this.isFormValid(oldPassword, password, repeatPassword) || inProgress}
                            >
                                {!this.state.inProgress ? formatMessage({id: "profile.saveChanges"}) : formatMessage({id: "common.waitPlease"})}
                            </Button>
                            <span> </span>
                        </Form>
                    </Col>
                </ProfilePage>
                }
            </React.Fragment>
        )
    }
}

ChangePassword.propTypes = {
    user: PropTypes.object
};

export default injectIntl(ChangePassword);
