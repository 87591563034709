import React, {Component} from 'react';
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";
import LinkService from "../services/LinkService";
import Button from "react-bootstrap/Button";
import {FormattedMessage} from "react-intl";

class FooterLinks extends Component {
    render = () => {
        return (
            <ul className="footer-links">
                <li>
                    <LinkContainer to={LinkService.getHome()}>
                        <Button variant="link" className="text-light">
                            <FormattedMessage id="footer.home"/>
                        </Button>
                    </LinkContainer>
                </li>
                <li className="text-light">·</li>
                <LinkContainer to={LinkService.getHelpTerms()}>
                    <Button variant="link" className="text-light">
                        <FormattedMessage id="footer.terms"/>
                    </Button>
                </LinkContainer>
                <li className="text-light">·</li>
                <LinkContainer to={LinkService.getHelpGDPR()}>
                    <Button variant="link" className="text-light">
                        <FormattedMessage id="footer.gdprTitle"/>
                    </Button>
                </LinkContainer>
                <li className="text-light">·</li>
                <LinkContainer to={LinkService.getHelpCookies()}>
                    <Button variant="link" className="text-light">
                        <FormattedMessage id="footer.cookies"/>
                    </Button>
                </LinkContainer>
            </ul>
        )
    }
}

export default FooterLinks;
