import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ContentLoader from "react-content-loader";
import ReviewItem from "./ReviewItem";

class ProductReviews extends Component {
    commentLoader = (i) => {
        return (
            <ContentLoader width={445} height={90}
                           key={i}
                           preserveAspectRatio={"none"}
                           style={{width: '100%', height: 90}}>
                <rect x="8" y="5" rx="0" ry="0" width="100" height="20"/>

                <rect x="115" y="7" rx="0" ry="0" width="18" height="18"/>
                <rect x="135" y="7" rx="0" ry="0" width="18" height="18"/>
                <rect x="155" y="7" rx="0" ry="0" width="18" height="18"/>
                <rect x="175" y="7" rx="0" ry="0" width="18" height="18"/>
                <rect x="195" y="7" rx="0" ry="0" width="18" height="18"/>

                <rect x="220" y="10" rx="0" ry="0" width="50" height="15"/>
                <rect x="395" y="10" rx="0" ry="0" width="50" height="15"/>

                <rect x="8" y="35" rx="0" ry="0" width="430" height="16"/>
                <rect x="8" y="55" rx="0" ry="0" width="400" height="16"/>

            </ContentLoader>
        )
    };

    render = () => {
        const {product, user} = this.props;
        return (
            <React.Fragment>
                {!product && [1, 2, 3, 4, 5].map((i) => {
                    return this.commentLoader(i)
                })}
                {product && product.reviews.filter(review => review.status !== "HIDDEN").map(review => {
                    return (<ReviewItem user={user} product={product} review={review} key={review.id}/>)
                })}
            </React.Fragment>
        )
    }
}

ProductReviews.propTypes = {
    product: PropTypes.object,
    user: PropTypes.object
};

export default ProductReviews;
