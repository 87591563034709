const ImageService = {
    calculateImageSize: function (image) {
        let parent = image.parentNode;

        let plannedWidth = parent.offsetWidth;
        let plannedHeight = plannedWidth;

        let naturalHeight = image.naturalHeight;
        let naturalWidth = image.naturalWidth;

        if ((naturalWidth / plannedWidth) < (naturalHeight / plannedHeight)) {
            let newHeight = Math.round(naturalHeight * (plannedWidth / naturalWidth));
            image.style.width = plannedWidth + "px";
            image.style.height = newHeight + "px";
            parent.style.height = plannedHeight + "px";
            image.style.marginTop = Math.round((newHeight - plannedHeight) / -2) + "px";

        } else {
            let newWidth = Math.round(naturalWidth * (plannedHeight / naturalHeight));
            image.style.width = newWidth + "px";
            image.style.height = plannedHeight + "px;";
            image.style.marginLeft = Math.round((newWidth - plannedWidth) / -2) + "px";
        }
    },

    calculateTileSize: function (image) {
        let parent = image.parentNode;

        let plannedWidth = parent.offsetWidth;
        let plannedHeight = parent.offsetHeight - 1;

        let naturalHeight = image.naturalHeight;
        let naturalWidth = image.naturalWidth;

        if ((naturalWidth / plannedWidth) < (naturalHeight / plannedHeight)) {
            let newHeight = Math.round(naturalHeight * (plannedWidth / naturalWidth));
            let margin = Math.round((newHeight - plannedHeight) / -2) + "px";

            image.style.width = plannedWidth + "px";
            image.style.height = newHeight + "px";
            parent.style.height = plannedHeight + "px";
            image.style.marginTop = margin;
            image.style.marginBottom = margin;

        } else {
            let newWidth = Math.round(naturalWidth * (plannedHeight / naturalHeight));
            image.style.width = newWidth + "px";
            image.style.height = plannedHeight + "px;";
            image.style.marginLeft = Math.round((newWidth - plannedWidth) / -2) + "px";
        }
    }
};

export default ImageService;
