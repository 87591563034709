import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import LinkService from "../services/LinkService"
import {FormattedMessage} from "react-intl";
import MessagesNavLink from "./MessagesNavLink"
import WishlistNavLink from "./WishlistNavLink"
import AccountLink from "./AccountLink"
import logo from "./valika.png"
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer'
import CurrencySelect from "./CurrencySelect";
import LanguageSelect from "./LanguageSelect";

class TopNavigation extends Component {
    logoStyles = {
        padding: "2px 4px",
        MozBorderRadius: 5,
        WebkitBorderRadius: 5,
        borderRadius: 5,
        background: "black",
        MozBoxShadow: "0 0 3px #444",
        WebkitBoxShadow: "0 0 3px #444",
        // boxShadow: "0 0 3px #444",
        boxShadow: "rgb(68, 68, 68) 0px 0px 3px"
    };

    render = () => {
        const {user} = this.props;
        return (
            <Navbar bg="primary" variant="dark" fixed="top" style={{zIndex: 998}}>
                <Container fluid={false}>
                    <LinkContainer to={LinkService.getHome()}>
                        <Navbar.Brand>
                            <img
                                alt=""
                                src={logo}
                                width="30"
                                height="30"
                                style={this.logoStyles}
                                className="d-inline-block align-top rotate-n-15"
                            />
                            <span>&nbsp;&nbsp;Valika</span>
                        </Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <LinkContainer to={LinkService.getSmartCatalog()}>
                                <Nav.Link active={window.location.pathname.startsWith(LinkService.getCatalog())}>
                                    <FormattedMessage id="topNavi.catalogue"/>
                                </Nav.Link>
                            </LinkContainer>
                        </Nav>

                        <Nav className="justify-content-end" style={{marginRight: 45}}>
                            <CurrencySelect small={true}/>
                            <LanguageSelect user={this.props.user} small={true}/>
                        </Nav>

                        <Nav className="justify-content-end">
                            <MessagesNavLink user={user}
                                             onLogin={this.props.onLogin}
                            />
                            <WishlistNavLink user={user}
                                             onLogin={this.props.onLogin}
                            />
                            <AccountLink user={user}
                                         onLogin={this.props.onLogin}
                                         onLogout={this.props.onLogout}
                            />
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}

TopNavigation.propTypes = {
    user: PropTypes.object,
    onLogin: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired
};

export default TopNavigation;
