import React, {Component} from 'react';

class HelpCookiesEn extends Component {
    render() {
        return (
            <React.Fragment>
                <h1>General information</h1>
                <p>Cookies are small text files that a website stores on a user's hard disk or mobile device. Often,
                    this is a setting
                    that makes it easier for the user to use the site. Some features are not available without
                    cookies.</p>
                <p><strong>Valika.org does not use cookies to identify personally of its users.</strong></p>
                <p>We store the following types of cookies:</p>
                <ul>
                    <li>Technical cookies that are necessary to ensure basic site functionality (session identifier).
                    </li>
                    <li>Third-party cookies designed to measure anonymous user behavior and traffic tracking data using
                        Google Analytics
                        to better customize pages for users.
                    </li>
                </ul>

                <h1>Cookies settings</h1>
                <ul>
                    <li>Rules for using or blocking cookies can be set by each user in their web browser.</li>
                    <li>The user can set permission or reject all or only some cookies. Blocking cookies will negatively
                        affect the
                        usability of the website and valika.org.
                    </li>
                    <li>The user can change his or her choice of cookies at any time. The user may delete already saved
                        cookies in their
                        internet browser.
                    </li>
                    <li>Detailed information on how cookies can be stored in the browser can be found on the web
                        browser's website
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}

export default HelpCookiesEn;
