import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProfileMenu from "./ProfileMenu";

class ProfilePage extends Component {
    render = () => {
        return (
            <Row>
                <Col md={3}>
                    <ProfileMenu user={this.props.user}/>
                </Col>
                <Col md={9}>
                    {this.props.children}
                </Col>
            </Row>
        )
    }
}

ProfilePage.propTypes = {
    user: PropTypes.object,
};

export default ProfilePage;
