import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import PriceService from "../services/PriceService";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button'
import ProductRating from "../catalog/ProductRating";
import PropTypes from 'prop-types'
import Jumbotron from 'react-bootstrap/Jumbotron'
import ContentLoader from "react-content-loader";
import WishlistService from "../services/WishlistService";
import SendMessage from "./SendMessage";
import FastRegistrationDialog from "../shell/FastRegistrationDialog";

class ProductDescription extends Component {
    state = {
        showSendMessage: false,
        showFastRegistrationDialog: false,
        fromId: undefined
    };

    limitTo = (data, limit) => {
        return data.substring(0, limit);
    };

    addToWishlist = () => {
        const {product, user} = this.props;
        if (user === null || user === undefined) {
            this.props.onLogin();

        } else {
            const itself = this;
            WishlistService
                .addToWishlist(product.id)
                .then(function () {
                    product.inWishList = true;
                    itself.props.onAddedToWishlist(product);
                });
        }
    };

    removeFromWishlist = () => {
        const {product, user} = this.props;
        if (user === null || user === undefined) {
            this.props.onLogin();

        } else {
            const itself = this;
            WishlistService
                .deleteFromWishlist(product.id)
                .then(function () {
                    product.inWishList = false;
                    itself.props.onRemovedFromWishlist(product);
                });
        }
    };

    sendMessage = () => {
        const {user} = this.props;
        if (user === null || user === undefined) {
            // this.props.onLogin();
            this.setState({showFastRegistrationDialog: true});
        } else {
            this.setState({showSendMessage: true});
        }
    };

    render = () => {
        const {product, user} = this.props;
        const {formatMessage} = this.props.intl;
        return (
            <React.Fragment>
                {!product &&
                <ContentLoader width={445} height={750}
                               preserveAspectRatio={"none"}
                               style={{width: '100%', height: 750}}>
                    <rect x="32" y="64" rx="0" ry="0" width="150" height="18"/>
                    <rect x="32" y="85" rx="0" ry="0" width="381" height="180"/>

                    <rect x="32" y="285" rx="0" ry="0" width="381" height="20"/>
                    <rect x="32" y="315" rx="0" ry="0" width="381" height="20"/>
                    <rect x="32" y="345" rx="0" ry="0" width="381" height="20"/>
                    <rect x="32" y="375" rx="0" ry="0" width="381" height="20"/>

                    <rect x="32" y="415" rx="0" ry="0" width="80" height="15"/>

                    <rect x="32" y="450" rx="0" ry="0" width="100" height="30"/>
                    <rect x="140" y="470" rx="0" ry="0" width="100" height="10"/>

                    <rect x="32" y="530" rx="0" ry="0" width="381" height="1"/>

                    <rect x="32" y="570" rx="0" ry="0" width="174" height="30"/>
                    <rect x="238" y="570" rx="0" ry="0" width="174" height="30"/>

                    <rect x="32" y="650" rx="0" ry="0" width="381" height="50"/>
                </ContentLoader>
                }
                {product &&
                <Jumbotron style={{backgroundColor: "inherit"}}>
                    <span className="h6">{product.brand.name}</span>
                    <h1 className="display-4">{product.title}</h1>
                    <p className="lead">{this.limitTo(product.description, 200)}&hellip;</p>
                    <p className="text-muted"><FormattedMessage id={"country." + product.brand.country.toLowerCase()}/></p>
                    {product.price.amount > 0 &&
                    <p>
                        <span className="h4">{PriceService.convertAndShow(product.price.amount + '-' + product.price.currency)}</span>
                        &nbsp;&nbsp;
                        <small className="text-muted"><FormattedMessage id="product.approx"/></small>
                    </p>
                    }

                    <hr className="my-4"/>

                    <Row>
                        {(!product.inWishList || user === null) &&
                        <Col md={6}>
                            <Button variant="success" size="sm" block onClick={this.addToWishlist}>
                                <i className="far fa-heart"/>&nbsp;
                                <FormattedMessage id="product.addToWishlist"/>
                            </Button>
                        </Col>
                        }
                        {product.inWishList && user !== null &&
                        <Col md={6}>
                            <Button variant="danger" size="sm" block onClick={this.removeFromWishlist}>
                                <i className="fas fa-times"/>&nbsp;
                                <FormattedMessage id="product.removeFromWishlist"/>
                            </Button>
                        </Col>
                        }

                        <Col md={6}>
                            <Button variant="light" size="sm" block onClick={this.props.onShowReviews}>
                                <ProductRating value={product.rating}></ProductRating>
                                {product.reviewsCount > 0 &&
                                <span>&nbsp;({product.reviewsCount})</span>
                                }
                            </Button>
                        </Col>
                    </Row>

                    <Row style={{marginTop: 45}}>
                        <Col md={12}>
                            <Button variant="warning" size="lg" block onClick={this.sendMessage}>
                                <FormattedMessage id="product.askQuestion"/>
                            </Button>
                            <SendMessage show={this.state.showSendMessage}
                                         onClose={() => this.setState({showSendMessage: false})}
                                         userId={product.brand.id}
                                         body={"\n\n" + product.title + " (" + window.location.href + ")"}
                                         from={this.state.fromId}
                            />
                            <FastRegistrationDialog
                                title={formatMessage({id: "product.askQuestion"})}
                                show={this.state.showFastRegistrationDialog}
                                onClose={() => this.setState({showFastRegistrationDialog: false})}
                                onRegister={(fromId) => this.setState({fromId: fromId, showFastRegistrationDialog: false, showSendMessage: true})}
                            />
                        </Col>
                    </Row>
                </Jumbotron>
                }
            </React.Fragment>
        )
    }
}

ProductDescription.propTypes = {
    user: PropTypes.object,
    product: PropTypes.object,
    onAddedToWishlist: PropTypes.func.isRequired,
    onRemovedFromWishlist: PropTypes.func.isRequired,
    onLogin: PropTypes.func.isRequired,
    onShowReviews: PropTypes.func.isRequired
};

export default injectIntl(ProductDescription);
