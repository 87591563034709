import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ProductList from "../catalog/ProductList";
import ProfilePage from "./ProfilePage";
import {FormattedMessage} from "react-intl";
import ProductService from "../services/ProductService";
import Button from "react-bootstrap/Button";
import LinkService from "../services/LinkService";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";

class Mystuff extends Component {
    loadProducts = () => {
        return ProductService.myStuff(0, 24);
    };

    render = () => {
        return (
            <ProfilePage user={this.props.user}>
                <h1><FormattedMessage id="topNavi.mystuff"/></h1>
                <LinkContainer to={LinkService.getMyStuffNew()} style={{marginBottom: 15}}>
                    <Button variant="outline-success" size="lg" block>
                        <i className="fas fa-lg fa-plus-square"></i>
                        &nbsp;&nbsp;
                        <FormattedMessage id="mystuff.newProduct"/>
                    </Button>
                </LinkContainer>
                <ProductList location={window.location}
                             user={this.props.user}
                             onLogin={this.props.onLogin}
                             onAddedToWishlist={this.props.onAddedToWishlist}
                             onRemovedFromWishlist={this.props.onRemovedFromWishlist}
                             loadProducts={this.loadProducts}
                />
            </ProfilePage>
        )
    }
}

Mystuff.propTypes = {
    user: PropTypes.object,
    onLogin: PropTypes.func.isRequired,
    onAddedToWishlist: PropTypes.func.isRequired,
    onRemovedFromWishlist: PropTypes.func.isRequired,
};

export default Mystuff;
