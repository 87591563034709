import React, {Component} from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import {FormattedMessage} from "react-intl"
import Button from "react-bootstrap/Button"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class Popup extends Component {
    onConfirm = () => {
        this.props.onClose();
        this.props.onConfirm()
    };
    render = () => {
        let type = this.props.type === "CONFIRM" ? "CONFIRM" : "ALERT";
        let iconClass = type === "CONFIRM" ? "fa-question-circle text-warning" : "fa-info-circle text-info";

        return (
            <Modal show={this.props.show} onHide={this.props.onClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xs={2}><i className={classNames("fas", "fa-3x", iconClass)}/></Col>
                            <Col xs={10}>{this.props.message}</Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.onConfirm}>
                        Ok
                    </Button>
                    {type === "CONFIRM" &&
                    <Button variant="outline-secondary" onClick={this.props.onClose}>
                        <FormattedMessage id="common.cancel"/>
                    </Button>
                    }
                </Modal.Footer>
            </Modal>
        )
    }
}

Popup.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,

    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired, // ALERT (ok), CONFIRM (Ok, cancel)
    message: PropTypes.string.isRequired,
};

export default Popup;
