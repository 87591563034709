import React, {Component} from 'react';
import PropTypes from 'prop-types';

class CardIndicators extends Component {
    render = () => {
        const {product, user} = this.props;
        return (
            <div className="card-indicators">
                {user && !product.active && <div><i className="fas fa-2x fa-eye-slash"></i></div>}
                {user && product.inWishList && <div><i className="fas fa-2x fa-heart text-danger"></i></div>}
            </div>
        )
    }
}

CardIndicators.propTypes = {
    product: PropTypes.object.isRequired,
    user: PropTypes.object
};

export default CardIndicators;
