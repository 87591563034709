const RouteParams = {
    search: function () {
        if (window.location.search === undefined || window.location.search.trim().length === 0) {
            return {};
        }
        const result = {};
        window.location.search
            .substring(1)
            .split("&")
            .map(item => {
                return item.split("=");
            })
            .forEach(value => {
                result[value[0].toLowerCase()] = value[1];
            });

        return result;
    },

    catalogCategory: function () {
        let re = new RegExp('\\/catalog\\/?(.*)');
        let result = re.exec(window.location.pathname.toLowerCase());
        if (result === null) {
            return undefined;
        } else {
            let r = result[1];
            if (r.trim().length === 0) {
                return undefined;
            } else {
                return r;
            }
        }
    },

    productFurl: function () {
        let re = new RegExp('\\/product\\/?(.*)');
        let result = re.exec(window.location.pathname.toLowerCase());
        if (result === null) {
            return undefined;
        } else {
            let r = result[1];
            if (r.trim().length === 0) {
                return undefined;
            } else {
                return r;
            }
        }
    },

    productId: function () {
        let re = new RegExp('\\/mystuff\\/?(.*)');
        let result = re.exec(window.location.pathname.toLowerCase());
        if (result === null) {
            return undefined;
        } else {
            let r = result[1];
            if (r.trim().length === 0) {
                return undefined;
            } else {
                return r;
            }
        }
    }
};

export default RouteParams;
