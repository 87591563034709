function encodeUriQuery(val, pctEncodeSpaces) {
    return encodeURIComponent(val)
        .replace(/%40/gi, '@')
        .replace(/%3A/gi, ':')
        .replace(/%24/g, '$')
        .replace(/%2C/gi, ',')
        .replace(/%20/g, (pctEncodeSpaces ? '%20' : '+'));
}

const UrlBuilder = {
    build: function (url, params) {
        if (!params) return url;

        var key;
        var parts = [];
        for (key in params) {
            parts.push(encodeUriQuery(key) + '=' + encodeUriQuery(params[key]));
        }
        return (parts.length > 0)
            ? url + ((url.indexOf('?') === -1) ? '?' : '&') + parts.join('&')
            : url;
    }
};

export default UrlBuilder;
